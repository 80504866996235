import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from '../app.service';
import { RoutineV2Service } from '../routine-v2/routine-v2.service';
import { User } from '../user/user.model';

@Component({
  selector: 'app-alert-edit-levels',
  templateUrl: './alert-edit-levels.component.html',
  styleUrls: ['./alert-edit-levels.component.scss']
})
export class AlertEditLevelsComponent implements OnInit {

  currentUser: User;
  editResponse: any

  constructor(public dialogRef: MatDialogRef<AlertEditLevelsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private appState: AppState,
    private routineService: RoutineV2Service,
    private cdRef: ChangeDetectorRef,
    private route: Router,
  ) {
    this.currentUser = this.appState.get("currentUser");
  }

  ngOnInit() {
  }

  checkBoxChange(completed: boolean, routine: any, level: any) {
    this.findLevelId(routine, level) 
  }

  findLevelId(routine: any, checkedLevel: any) {
    let someArray = []
    let level_ids =[];
    routine.routine.forEach(element => {
      if (element.id != checkedLevel.id) {
        someArray.push(element)
        level_ids.push(element.id)
      }
    });
    this.appState.set('LevelNumber', someArray[0].level)
    this.appState.set('indexOfLevel', undefined)    
    this.appState.set('currentLevel', someArray[0])
    this.appState.set('levelUpdated', undefined)
    const repsRequestData= {  
      user_id:routine.user_id,
      is_reset:true,
      level_id:JSON.stringify(level_ids),
      routine_info_id:routine.routine_info_id
    }
   
    if(repsRequestData.user_id==0){
      return true;
    }else{
      this.routineService.appendRepsCount(repsRequestData).subscribe((res)=>{
        console.log("response for reps", res);
      })

      const editrepsRequestData= {  
      user_id:routine.user_id,
      is_reset:true,
      level_id:JSON.stringify(level_ids),
      routine_info_id: routine.routine_info_id,
      path_id : routine.path_id
     }
      this.routineService.editRoutine(editrepsRequestData).subscribe(
        (response) => {
          this.editResponse = response.data
          this.appState.set('levelUpdated', undefined)
          this.cdRef.detectChanges();
        },
        (error) => {
          console.log('Error for edit routines', error)
        })
    }

  }

  onSubmit(routine) {
    let level_id = this.appState.get('LevelNumber')
    let currentLevel = this.appState.get('selectedLevelIds')
    this.appState.set('showUpdatedScreens', true)
      this.appState.set('LevelNumber', 1) 
      this.findLevelId(routine,1)
      this.appState.set('hidePrevScreen', true);
      this.appState.set("allowScreenCount", undefined)
      this.route.navigate(['/content', routine.schedule_day], { queryParams: { startFromScreen: 'daily-content-screen-routine-levels' } })
  }

  editCurrentRoutine(routine: any, currentLevel: any){
    let level_ids =[]
      currentLevel.forEach((level)=>{
         level_ids.push(level.id);
      })
    const repsRequestData= {
      user_id:routine.user_id,
      is_reset:true,
      level_id:JSON.stringify(level_ids),
      routine_info_id:routine.routine_info_id
    }
    const requestData = {
      'user_id': routine.user_id,
      'path_id': routine.path_id,
      'level_id': currentLevel.id,
      'is_reset': true,
      'routine_info_id': routine.routine_info_id
    }
   
    if(requestData.user_id==0){
      return true;
    }else{
      this.routineService.appendRepsCount(repsRequestData).subscribe((res)=>{
        console.log("response for reps", res);
      })
      
      this.routineService.editRoutine(requestData).subscribe(
        (response) => {
          console.log('status for edit routines', response);
          this.editResponse = response.data
          this.appState.set('levelUpdated', undefined)
          this.cdRef.detectChanges();
        },
        (error) => {
          console.log('Error for edit routines', error)
        })
    }  
  }

}
