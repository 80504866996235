import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { result } from "underscore";
import { AlertUserFeedbackComponent } from "../alert-user-feedback/alert-user-feedback.component";
import { AppState } from "../app.service";
import { CordovaService } from "../cordova.service";
import { HomeV5Service } from "../pages/home-v5/home-v5.service";
import { I18n } from "../i18n.service";
import { Constants } from "../constants/constants";

@Component({
  selector: "app-alert-support",
  templateUrl: "./alert-support.component.html",
  styleUrls: ["./alert-support.component.scss"],
})
export class AlertSupportComponent implements OnInit {
  feedbackValue: string = "";
  isFeedback: boolean = false;
  private dialog: MatDialog;
  path: any;
  client: any;
  currentUser: any;
  os: string;
  translatedAlertSupport;
  constructor(
    private homeService: HomeV5Service,
    private appState: AppState,
    private cordovaService: CordovaService,
    private toastrService: ToastrService,
    private i18n: I18n,
    private dialogRef: MatDialogRef<AlertSupportComponent>
  ) {
    this.path = this.appState.get("currentPath");
    this.client = this.appState.get("currentClient");
    this.currentUser = this.appState.get("currentUser");
    this.translatedAlertSupport = `Share feedback with the ${Constants.appName} team here`;
  }

  ngOnInit() {}

  openFeedback() {
    this.isFeedback = true;
  }

  closeFeedback() {
    this.isFeedback = false;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  submitFeedback() {
    console.log("request");

    var jsonDeviceData = {
      os_platform: this.cordovaService.returnPlatform(),
    };

    var data = {
      user_id: this.currentUser.id,
      path_id: this.path.id,
      device_data: JSON.stringify(jsonDeviceData),
      description: this.feedbackValue
    };

    console.log("request", data);

    this.homeService.supportFeedback(data).subscribe(
      (response) => {
        if(response.status){
          this.toastrService.success(this.i18n.t("Thank you for your feedback!"))
          this.dialogRef.close();
        }
      },
      (error) => {
        this.toastrService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
      }
    );
  }

  share(event) {
    event.stopPropagation();
    this.openMailTo("1st90");
  }

  openMailTo(subject, body: any = null) {
    //location.href = "mailto:comet@1st90.com" + "?subject= Support: ";
    console.log("Mail data");
    this.cordovaService.openMail('mailto:comet@1st90.com')

    window.open("mailto:comet@1st90.com" + "?subject= Support: ", "_self");
    return true;
  }
}
