import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Constants } from '../constants/constants';


@Component({
  selector: 'app-alert-iam-in',
  templateUrl: './alert-iam-in.component.html',
  styleUrls: ['./alert-iam-in.component.scss']
})
export class AlertIamInComponent implements OnInit {

  private dialog: MatDialog
  translatedTextIamIn;
  constructor() {
    this.translatedTextIamIn = `It takes repetition to build new habits. Make this action a habit by committing to repeat it
      multiple times throughout the next 90 days. We’ll help you block off the time in your calendar and track your
      progress in the ' + constants.appName + ' app (you’ll see us use “reps” for repetitions – it’s a small screen!). Do you want to make
      this action a routine?" "It takes repetition to build new habits. Make this action a habit by committing to repeat it
      multiple times throughout the next 90 days. We’ll help you block off the time in your calendar and track your
      progress in the ${Constants.appName} app (you’ll see us use “reps” for repetitions – it’s a small screen!). Do you want to make
      this action a routine?`;
   }

  ngOnInit() {
  }

}
