import { Component, OnInit } from '@angular/core';
import { CordovaService } from '../cordova.service';
import { Constants } from '../constants/constants';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  isStartAnimation=false
  loading=false
  constants = Constants;
  constructor(public cordovaService: CordovaService) { }

  ngOnInit() {
  }

  switch(){

    if(this.isStartAnimation){
      this.isStartAnimation=false
    }else{
      this.isStartAnimation=true
    }

  }


}
