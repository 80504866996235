import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { I18n } from 'src/app/i18n.service';
import { RoutineInfo } from '../routine-info.model';
import { RoutineLevel } from '../routine-level.model';
import { HomeUiService } from '../../new-design/home-ui.service';

@Component({
  selector: 'activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {

  @Input() routine: RoutineInfo
  @Input() level: RoutineLevel
  @Input() inverted = false

  @Output() activityData = new EventEmitter<any>();
  @Output() activityCueData = new EventEmitter<any>();
  activity: string
  activityLabel: string

  constructor(private i18n: I18n,
    public homeUIService: HomeUiService,
    private cdRef: ChangeDetectorRef,) {
    this.activityLabel = this.i18n.t('enter activity')
   }

  ngOnInit() {
    
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  getValue(activity){
    this.activityData.emit(activity)
  }

  getSelectValue(event: any){
    this.activityCueData.emit(event.target.value)
  }

}