import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppState } from '../app.service';
import { HomeUiService } from '../new-design/home-ui.service';

@Component({
  selector: 'app-alert-commit-routine',
  templateUrl: './alert-commit-routine.component.html',
  styleUrls: ['./alert-commit-routine.component.scss']
})
export class AlertCommitRoutineComponent implements OnInit {
  isConfirmed: boolean;
  isMobile: boolean
  constructor(public dialogRef: MatDialogRef<AlertCommitRoutineComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private appState: AppState,
    public homeUIService: HomeUiService,
    ) { 
      this.isMobile = this.appState.get('isMobile')
    }

  ngOnInit() {
    this.isConfirmed = this.appState.get('isConfirmed')

  }

}
