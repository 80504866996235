import { ChangeDetectorRef, Component, DoCheck, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstantsService } from '../app-constants.service';
import { AppState } from '../app.service'
import { UtilityService } from '../common-services/utility.service';
import { DailyStepsService } from '../daily-steps/daily-steps.service';
import { Habit } from '../habit/habit.model';
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { Path } from '../path/path.model';
import { AnalyticsServiceService } from '../services/analytics-service.service';
import { SpinnerService } from '../spinner.service';
import { ToasterService } from '../toaster.service';
import { User } from '../user/user.model';
import { zoomInAnimation, expandOnEnterAnimation, slideOutDownAnimation, slideInLeftAnimation } from 'angular-animations';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { I18n } from '../i18n.service';
import { HomeUiService } from '../new-design/home-ui.service';
import { DailyStepsContentModalComponent } from '../new-design/daily-steps-content-modal/daily-steps-content-modal.component';
import { ModalController, IonTextarea,Platform } from '@ionic/angular';
import * as _ from 'underscore';
import { SessionService } from '../session/session.service';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';

@Component({
  selector: 'app-weldonescreen',
  templateUrl: './weldonescreen.component.html',
  styleUrls: ['./weldonescreen.component.scss'],
  animations: [
    zoomInAnimation(),
    trigger('shrinkAnimation', [
      state('normal', style({
        transform: 'scale(1)',
      })),
      state('shrunken', style({
        transform: 'scale(0.7)', // Adjust the scale factor as needed
      })),
      transition('normal => shrunken', [
        animate('0.3s ease-out')
      ]),
      transition('shrunken => normal', [
        animate('0.3s ease-in')
      ]),
    ]),
    expandOnEnterAnimation({ duration: 400 }),
    slideOutDownAnimation({ duration: 500 }),
    slideInLeftAnimation({duration:400})
  ]
})
export class WeldonescreenComponent implements OnInit, DoCheck,OnDestroy {
  currentUser: User;
  currentPath: Path;
  currentDay: number;
  habit: Habit;
  currentHabit: number
  actionPoints: number
  showStepTitle = true;
  postText: any = ''
  formRating: any = 0
  maxAllowedScheduleDay: any;
  scheduleDay: number;
  scheduledStep: any;
  nextDay: number;
  inoutScreen: any;
  titleOfTask: any;
  allowScreenCount: boolean
  dayHasContent;
  previousDayCompleted;
  public pathRegistration: any;
  options: [
    {
      label: 'Zoom Entrances',
      animations: ['zoomIn']
    },
  ]
  @ViewChild('gifImage') gifImage: ElementRef;
  // @ViewChild('textarea', { static: false }) textarea: IonTextarea;
  @ViewChild("textarea") txtarea: ElementRef;
  animateZoomIn = false
  showRatingBox = false
  @ViewChild('ratingArea', {static: false}) ratingArea: ElementRef;
  isShrunken = false;
  expandTextArea = false
  showTyLabel = false
  dailyStepsArray;
  isIconDisliked: boolean = false;
  isIconLiked: boolean = false;
  mobileWeldoneScreenIds = [];
  isBadgesExpanded;
  unlockStepInoutScreenData;
  durationOfTask;
  unAttemptedActionPoints = {};
  totalUnAttemptedPoints = 0;
  currentStepActionPoints;
  stepScreensIds
  showBottomTextarea = false
  showInstruction = false;
  currentScreenIndex = 0;
  animationBoard = false;
  reviewState = false;
  currentNumber = 0;
  isAndroidTablet=false;
  @ViewChild("stepTitle") stepTitle: ElementRef;
  constructor(
    private appState: AppState,
    public route: ActivatedRoute,
    private router: Router,
    private i18n: I18n,
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
    public homeUIService: HomeUiService,
    private homev5Service: HomeV5Service,
    private cdRef: ChangeDetectorRef,
    private toasterService: ToasterService,
    private dailyStepsService: DailyStepsService,
    private analyticsService: AnalyticsServiceService,
    private modalController: ModalController,
    private utilService: UtilityService,
    private appConstantService: AppConstantsService,
    private renderer: Renderer2,
    public sessionService: SessionService,
    private elementRef: ElementRef,
    private keyboard: Keyboard,
    private platform: Platform
  ) {
    this.pathRegistration = this.appState.get('pathRegistration')
    this.currentUser = this.appState.get('currentUser');
    this.currentPath = this.appState.get('currentPath');
    this.currentDay = this.appState.get('currentScheduleDay')
    this.scheduledStep = this.appState.get('scheduleDayForCurrentStep');
    this.habit = this.findForCurrentDay(this.currentPath.habits)
    this.maxAllowedScheduleDay = this.appState.get('daysOfContent')
    this.scheduleDay = +this.appState.get('currentScheduleDay')
    this.dailyStepsArray = this.appState.get('DailyStepsData')
    this.inoutScreen = this.findForCurrentDay(this.currentPath.inoutScreens)
    this.isAndroidTablet = this.platform.is('mobileweb') && ( this.platform.is('tablet'))
    if (this.appState.get('routine_schedule_day')) {
      this.currentDay = this.appState.get('routine_schedule_day')
      this.appState.set('routine_schedule_day', null)
    }
    for(let i=this.currentDay + 1; i<= this.maxAllowedScheduleDay; i++) {
      this.dayHasContent = this.currentPath.checkContentForDay(i);
      if(this.dayHasContent) {
        this.nextDay = i;
        this.scheduleDay =i;
        break;
      }
    }
    this.stepScreensIds = this.appState.get("stepScreenIds");
    this.currentStepActionInfo();
  }

  currentStepActionInfo() {
    let data = {
      'user_id': this.currentUser.id,
      'path_id': this.currentPath.id,
      'currentDay': this.currentDay
    }
    this.homeUIService.getStepwiseActionPoint(data).subscribe((res) => {
      this.currentStepActionPoints = res.data;
      if (this.currentStepActionPoints?.total_action_points > 0) {
        this.startCounterAnimation(this.currentStepActionPoints?.total_action_points)
      }
      let poll, documents, notification, profile, accountability, managerEmail, habit, action, survey, reflection, question;
      habit = this.stepScreensIds?.find((item) => item?.type === "routine");
      poll = this.stepScreensIds?.find((item) => item?.type === "poll");
      documents = this.stepScreensIds?.find((item) => item?.type === "document");
      notification = this.stepScreensIds?.find((item) => item?.type === "notification");
      profile = this.stepScreensIds?.find((item) => item?.type === "profile");
      accountability = this.stepScreensIds?.find((item) => item?.type === "accountability");
      managerEmail = this.stepScreensIds?.find((item) => item?.type === "managerEmail");
      action = this.stepScreensIds?.find((item) => item?.type === "action");
      reflection = this.stepScreensIds?.find((item) => item?.type === "reflection");
      survey = this.stepScreensIds?.find((item) => item?.type === "survey");
      question = this.stepScreensIds?.find((item) => item?.type === "question");
      if (this.currentStepActionPoints.document == 0 && documents) {
        this.totalUnAttemptedPoints += documents.actionPoints;
        this.unAttemptedActionPoints['document'] = documents.actionPoints;
      }
      if (habit) {
        this.totalUnAttemptedPoints += habit.actionPoints;      
        this.unAttemptedActionPoints['habit'] = habit.actionPoints;
      }
      if (poll) {
        if (this.currentStepActionPoints.poll == 0) {
           this.totalUnAttemptedPoints += poll.actionPoints;
           this.unAttemptedActionPoints['poll'] = poll.actionPoints;
        }
        else if (this.currentStepActionPoints.poll > 0) {
          // let pointsDiff = poll.actionPoints - this.currentStepActionPoints.poll;
          // if (pointsDiff!=0) {
             this.totalUnAttemptedPoints += poll.actionPoints;
             this.unAttemptedActionPoints['poll'] = poll.actionPoints;
          // }
          // else {
            //  this.totalUnAttemptedPoints += pointsDiff;
            //  this.unAttemptedActionPoints['poll'] = pointsDiff;
         //   }
         
        }
       
      }
      if (this.currentStepActionPoints.notification == 0 && notification) {
        this.totalUnAttemptedPoints += notification.actionPoints; 
        this.unAttemptedActionPoints['notifications'] = notification.actionPoints;
      }
      if (this.currentStepActionPoints.profile == 0 && profile) {
        this.totalUnAttemptedPoints += profile.actionPoints; 
        this.unAttemptedActionPoints["profile"] = profile.actionPoints;
      }
      if (this.currentStepActionPoints.accountability == 0 && accountability) {
        this.totalUnAttemptedPoints += accountability.actionPoints; 
        this.unAttemptedActionPoints['accountability'] = accountability.actionPoints;
      }
      if (this.currentStepActionPoints.manager == 0 && managerEmail) {
        this.totalUnAttemptedPoints += managerEmail.actionPoints; 
        this.unAttemptedActionPoints['managerEmail'] = managerEmail.actionPoints;
      }
      if (this.currentStepActionPoints.action == 0 && action) {
        this.totalUnAttemptedPoints += action.actionPoints; 
        this.unAttemptedActionPoints['action'] = action.actionPoints;
      }
      if (this.currentStepActionPoints.content_survey == 0 && survey) {
      this.totalUnAttemptedPoints += survey.actionPoints; 
      this.unAttemptedActionPoints['content_survey'] = survey.actionPoints;
    }
      if (this.currentStepActionPoints.reflection == 0 && reflection) {
       this.totalUnAttemptedPoints += reflection.actionPoints; 
       this.unAttemptedActionPoints['reflection'] = reflection.actionPoints;
     }
      if (this.currentStepActionPoints.question == 0 && question) {
       this.totalUnAttemptedPoints += question.actionPoints; 
       this.unAttemptedActionPoints['question'] = question.actionPoints;
     }      
      
    })
  }
  startCounterAnimation(targetNumber) {
    const element = document.querySelector('.number') as HTMLElement;
    if (element) {
      // element.style.setProperty('--num', targetNumber.toString());
      setTimeout(() => {
        this.animateCounter(0, targetNumber, 1000);
      }, 2150); 
    }
  }

  animateCounter(start: number, end: number, duration: number) {
    const stepTime = Math.abs(Math.floor(duration / (end - start)));
    let current = start;
    const step = () => {
      current += 1;
      this.currentNumber = current;
      if (current < end) {
        setTimeout(step, stepTime);
      }
    };
    step();
  }
  ngOnInit() {
    this.dailyStepsArray = this.appState.get('DailyStepsData')
    let isStepRestartedFromWeldone = this.appState.get("isCurrentStepRestarted")
    let isInitialWeldoneScreen =  this.appState.get('showInitialWeldone')
    // if ((this.scheduledStep == this.currentDay &&this.dailyStepsArray[this.currentDay]?.stepStatus==undefined )|| (this.scheduledStep == this.currentDay+1 && this.dailyStepsArray[this.currentDay]?.stepStatus==undefined )|| isStepRestartedFromWeldone ) {
    if (isStepRestartedFromWeldone || isInitialWeldoneScreen) {
      this.mobileWeldoneScreenIds = [
        "step-action-points-screen",
       // "step-badges-screen",
        "step-final-weldone-screen",
        //"step-review-weldone-screen",
      ];
      this.appState.set("showInitialWeldone", false)
      this.appState.set("isCurrentStepRestarted", false);
    console.log("this.mobileWeldoneScreenIds", this.mobileWeldoneScreenIds, this.mobileWeldoneScreenIds['action-points-screen']);
   }
    else {
      this.mobileWeldoneScreenIds = [
        'step-review-weldone-screen'
      ]
    }
    this.actionPoints = this.habit.actionPoints
    this.dailyStepsService.init()
    this.analyticsService.setScreen(AnalyticsServiceService.WELDONE)
    this.appState.set('allowNext', false)
    this.getActions(this.currentUser.id, this.currentPath.id)
  }
  private capitalizeSentences(text: string): string {
    return text.replace(/(?:^|\.\s)([a-z])/g, (match) => match.toUpperCase());
  }
  capitalLine(event): void {
    // const textareaElement = this.txtarea?.getInputElement();
    let ele = document.getElementsByClassName("weldone-textMessage");
    // const lineHeight = parseFloat(getComputedStyle(this.textarea['el']).lineHeight);
    if (event && event.target.value) {
      this.postText = this.capitalizeSentences(event.target.value);
    }
  }
  // onTextModification(ev) {
  //   console.log("called on change", ev.target.value);
  //   this.capitalLine(ev)
  // }
  ngDoCheck() {
    this.allowScreenCount = this.appState.get('allowScreenCount')
    // this.currentDay = this.appState.get('currentScheduleDay')
    
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // STOP confetti animation
      // this.renderer.setStyle(this.gifImage.nativeElement, 'background-image', 'none'); // Change color property
      // this.renderer.setStyle(this.gifImage.nativeElement, 'background-color', '#fff'); // Change color property
      // this.renderer.setStyle(this.gifImage.nativeElement, 'display', 'none'); // Change color property
      this.renderer.removeClass(this.gifImage?.nativeElement, 'confitee-animation');

      //Enable rating area with zoomIn animation
      this.showRatingBox = true
      this.animateZoomIn = false
      setTimeout(() => {
        this.animateZoomIn = true
      }, 10);

    }, 2000);
    this.checkBottomHeight()
  }

  findForCurrentDay(collection: any): any {
    return collection.find(
      item => item.startDay <= this.currentDay + 1 && this.currentDay + 1 <= item.endDay
    )
  }

  goToHome(formRating: any) {
    this.appState.set('isNewStep', undefined)
    this.appState.set('isHomeScreen',true)
    if (formRating) {
      this.onSumbit()
    }else{
      this.modalController.dismiss();
      //this.router.navigate(['/main'])
    }
    this.appConstantService.removeAppStateData()
    if (this.allowScreenCount && this.currentDay ==this.scheduledStep && this.dailyStepsArray[this.scheduledStep-1]?.stepStatus!='Review'){
      this.utilService.setScreenCount()
    }
  }

  onSumbit() {
    localStorage.setItem('step_comment', this.postText);
    localStorage.setItem('step_score', JSON.stringify(this.formRating));
    this.giveFeedback()
  }

  findLastHabit(collection: any, lastDay: any): any {
    return collection.find(
      item => item.startDay <= lastDay && lastDay <= item.endDay
    )
  }

  isDayLocked(nextDay) {
    // if (this.allowScreenCount) {
    //   this.utilService.setScreenCount()
    // }
    this.appState.set('isHomeScreen',true)
    var currentDay = nextDay - 1
    let levelSaveData = this.appState.get('levelSaveData')
    console.log('levelSaveData :', levelSaveData);
    if (levelSaveData) {
      localStorage.setItem(
        `Toggle:Day:${levelSaveData.currentDay}:LevelNumber:${levelSaveData.levelId}:true`,
        'true'
      )
      this.appState.set('showUpdatedScreens', undefined)
      this.appState.set('levelSaveData', undefined)
    }
    if (nextDay >= this.scheduledStep && this.scheduledStep <= this.maxAllowedScheduleDay && (this.dailyStepsArray[nextDay-1]?.stepStatus==undefined || this.dailyStepsArray[nextDay-1]?.stepStatus==null)) {
      localStorage.setItem('isBeginClicked','true')
      this.unlockReplay(currentDay)
    } else {
      this.appState.set("currentScheduleDay",nextDay)
      this.replay(nextDay)
    }
  }

  returnToHome() {
    this.modalController.dismiss();
    let dailySteps = this.appState.get("DailyStepsData");
    dailySteps.forEach((step)=>{
      if(step?.stepStatus =='Review' )
      {
          this.appState.set('currentScheduleDay', step?.step+1);
          this.appState.set('scheduleDayForCurrentStep',  step?.step+1);
      }
    })
  }


  replay(i) {
    let updateScheduleDay=i;
    this.appState.set('maxAllowedScheduleDay', updateScheduleDay);
    this.appState.set('currentScheduleDay', updateScheduleDay);
    // this.appState.set('scheduleDayForCurrentStep', updateScheduleDay);
    this.scheduledStep = this.appState.get('scheduleDayForCurrentStep');
    localStorage.removeItem(
      `Toggle:User:${this.currentUser.id}:DayProgress:${this.currentPath.id}:${!(i == undefined) ? i : this.scheduleDay
      }`
    );
    // this.router.navigate([
    //   '/content',
    //   !(i == undefined) ? i : this.scheduledStep,
    // ])
    this.modalController.dismiss({isOpenNextStep:true});
    this.homeUIService.openDailyStepsComponent(DailyStepsContentModalComponent,{currentDay:i},'web-daily-steps-custom-modal')
    // this.router.navigate([
    //   '/content',
    //   !(i == undefined) ? i : this.scheduleDay,
    // ])
    return false
  }

  unlockReplay(i) {

    if (i == 0) {
      return
    }

    let updateScheduleDay = i + 1
    let data = {
      'schedule_day': updateScheduleDay,
      'user_id': this.currentUser.id
    }

    // for(let s=i; s>=0; s--) {
    //   this.dayHasContent = this.currentPath.checkContentForDay(s);
    //   if (this.dayHasContent) {
    //     this.previousDayCompleted = !!this.homev5Service.checkPrevDayCompleted(
    //       this.pathRegistration,
    //       s,
    //       this.currentUser,
    //       this.currentPath
    //     )
    //     break;
    //   }
    // }

    // let stepsArray = this.homev5Service.removeDuplicates(this.pathRegistration.screensViewed, "step");
    // stepsArray.forEach(element => {
    //   if(element.step === this.scheduleDay && element.totalScreens === element.visitedScreens){
    //     this.previousDayCompleted = true
    //   }
    // });
    // if (this.previousDayCompleted) {

      this.homev5Service.updateScheduleDay(data).subscribe(
        (response) => {
          if (response.success == true) {
            this.appState.set('allowScreenCount', true)
            this.appState.set('isNewStepUnlocked', true)
            this.appState.set('maxAllowedScheduleDay', updateScheduleDay)
            this.appState.set('currentScheduleDay', updateScheduleDay)
            this.appState.set('scheduleDayForCurrentStep', updateScheduleDay)
            this.appState.set('indexOfLevel', 0)
            this.appState.set('levelUpdated', undefined)
            // this.maxAllowedScheduleDay = this.appState.get(
            //   'maxAllowedScheduleDay'
            // ) // LUMBA-1379
            this.scheduleDay = this.appState.get('currentScheduleDay') // LUMBA-1379
            this.scheduledStep = this.appState.get('scheduleDayForCurrentStep')

            this.appState.set('LevelNumber', 1)

            localStorage.removeItem(
              `Toggle:User:${this.currentUser.id}:DayProgress:${this.currentPath.id}:${!(i == undefined) ? i + 1 : this.scheduledStep
              }`
            )
            // this.spinnerService.on()
            // this.router.navigate([
            //   '/content',
            //   !(i == undefined) ? i + 1 : this.scheduledStep,
            // ])
            this.modalController.dismiss({isOpenNextStep:true});
            this.homeUIService.openDailyStepsComponent(DailyStepsContentModalComponent,{currentDay: !(i == undefined) ? i + 1 : this.scheduledStep},'web-daily-steps-custom-modal')
            return false

          } else {
            this.toasterService.warning(this.i18n.t('Server or connection issue. Please check your internet connection.'))
          }

        },
        (error) => {
          this.toasterService.warning(this.i18n.t('Server or connection issue. Please check your internet connection.'))
          console.log('Response of update  error', error)
        }
      )
    // } else {
    //   this.toasterService.warning(this.i18n.t('Please complete previous step'))
    // }
  }


  giveFeedback() {
    var step_rating = localStorage.getItem("step_score")
    var step_comment = localStorage.getItem("step_comment")
    try {
      const requestData = {
        'user_id': this.currentUser.id,
        'path_id': this.currentPath.id,
        'step_score': step_rating,
        'step_comment': step_comment,
      }
      console.log('data', requestData)
      // this.spinnerService.on()
      if (requestData.step_comment == '' && requestData.step_score == '') {
        // this.spinnerService.off();
      } else {
        this.homev5Service.postStepFeedback(requestData).subscribe(
          (response) => {
            this.toasterService.success(this.i18n.t('Thank you for your feedback!'))
            this.cdRef.detectChanges()
            // this.spinnerService.off();
            this.onBackgroundTap();
            this.isIconDisliked = false;
            this.isIconLiked = false;
            this.postText = ''
            this.appState.set('isHomeScreen', true)
            this.showRatingBox = false
            this.showTyLabel = true
          },
          (error) => {
            this.toasterService.error(this.i18n.t('Server or connection issue. Please check your internet connection.'))
          }
        )
      }

    } catch (o_O) {
      console.log(o_O);

    }

  }

  getTodaysTask() {
    if (this.inoutScreen) {
      this.titleOfTask = this.inoutScreen.homepageSubtext.text;
      return this.titleOfTask;
    }
  }

  getActions(userId: number, pathId: number) {
    const requestData = {
      'user_id': userId,
      'path_id': pathId
    }
    this.dailyStepsService.getActionsList(requestData).subscribe((response) => {
      this.appState.set('todoRoutines',this.homev5Service.removeDuplicates(response.data[0].routine, "routine_info_id"))
      this.appState.set('todoActions',response.data[0].action)
    }, (error) => {
      console.log('error :', error);
    })
  }

  animateReviewBox(formRating) {
    if (formRating >= 1) {
      this.isShrunken = false
      setTimeout(() => {
        this.isShrunken = true
      }, 10);

      this.expandTextArea = false
      setTimeout(() => {
        this.expandTextArea = true
      }, 10);
    }
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }

  dislike(val) {
    if (!val) {
      this.isIconLiked = null;
        setTimeout(() => {
          this.isIconDisliked = true;
          
        }, 200);
    }
    this.showTextArea();    
  }

  like(val) {
    if (val) {
       this.isIconDisliked = null;    
       setTimeout(() => {
         this.isIconLiked = true;
       }, 220);
    }
    this.showTextArea();
  }

  nextStepEndScreen() {
    this.currentScreenIndex++;
  }
  
  onBackgroundTap() {
    if (this.showBottomTextarea && !this.showInstruction) {
      // this.keyboard.hide();
      setTimeout(() => {
        this.showBottomTextarea = false;
      }, 190);
      console.log("called on background", this.showBottomTextarea)
    }
  }
  showTextArea() {
   setTimeout(() => {
     this.showBottomTextarea = true;
   }, 190);   
    console.log("show textarea called", this.showBottomTextarea)
    this.animationBoard = false;
    setTimeout(() => {
      this.animationBoard = true;
     
      this.txtarea?.nativeElement?.focus();
    }, 200);  
  }

  onTextareaFocus() {
    let id = document.getElementsByClassName("rate-wrap");
    if (id && this.isAndroidTablet) {
       id[0]?.setAttribute(
            "style",
            "bottom:20% !important;margin: 9% 0 9%;"
          );
    }
  }
  checkBottomHeight() {

    let id = document.getElementsByClassName("rate-wrap");
      this.platform.ready().then(() => {
        this.keyboard.onKeyboardWillShow().subscribe((info) => { 
          if (id && this.isAndroidTablet) {

            id[0]?.setAttribute(
              "style",
              "bottom:20% !important;margin: 9% 0 9%;"
            );
          }
        });

        this.keyboard.onKeyboardWillHide().subscribe((info) => {
          if (id && this.isAndroidTablet) {

            id[0]?.setAttribute(
              "style",
              "bottom:11% !important; margin: 9% 0 9%;"
            );
          }
        });
      })
    
  }
  @HostListener('touchend', ['$event'])
    onTouchEnd(event: TouchEvent) {
      this.addInputEventListeners();
  }
  addInputEventListeners() {
    const inputs = document.querySelector("textarea");
    let id = document.getElementsByClassName("rate-wrap");
    if (inputs) {
          this.renderer.listen(inputs, "focus", () => {
       if (id && this.isAndroidTablet) {
         id[0]?.setAttribute(
           "style",
           "bottom:20% !important; margin: 9% 0 9%;"
         );
       }
    });

    this.renderer.listen(inputs, "blur", () => {
      if (id && this.isAndroidTablet) {
        id[0]?.setAttribute("style", "bottom:11% !important; margin: 9% 0 9%;");
      }
    });
    this.renderer.listen(inputs, "focusout", () => {
     if (id && this.isAndroidTablet) {
       id[0]?.setAttribute("style", "bottom:11% !important; margin: 9% 0 9%;");
     }
    });
    }

  }
   removeInputEventListeners() {
    const inputs = document.querySelector("textarea");
    if (inputs) {
       this.renderer.listen(inputs, "focus", null);
       this.renderer.listen(inputs, "blur", null);
       this.renderer.listen(inputs, "focusout", null);
    }
   
  }
  expandBadges(val) {
    this.isBadgesExpanded = val;
  }
  changeFont() {
  
    const element = this.stepTitle?.nativeElement;
    const specificElement = element?.querySelector('p') || element?.querySelector('h1') || element?.querySelector('h2');
    if(specificElement) {
      this.renderer.setStyle(specificElement, 'font-size', '16px');
      this.renderer.setStyle(specificElement, 'font-weight', '400');
      this.renderer.setStyle(specificElement, 'font-style', 'normal');
      this.renderer.setStyle(specificElement, 'line-height', 'normal');
      this.renderer.setStyle(specificElement, "font-family", "Roboto");
      this.renderer.setStyle(specificElement, "margin-bottom", "0px");
     this.renderer.setStyle(specificElement, "word-break", "break-word");
     this.renderer.setStyle(specificElement, "overflow", "hidden");
     this.renderer.setStyle(specificElement, " text-overflow", "ellipsis");
     this.renderer.setStyle(specificElement, "display", "-webkit-box");
     this.renderer.setStyle(specificElement, "-webkit-line-clamp", "2");
     this.renderer.setStyle(specificElement, "-webkit-box-orient", "vertical");

    }
  return true;
  }
  getTaskDurations() {
    if (this.inoutScreen && !_.isUndefined(this.inoutScreen)) {
      if (this.inoutScreen.stime && this.inoutScreen.etime) {
        this.durationOfTask =
          this.inoutScreen.stime + " - " + this.inoutScreen.etime;
        return this.durationOfTask;
      } else if (!this.inoutScreen.stime && this.inoutScreen.etime) {
        this.durationOfTask = this.inoutScreen.etime;
        return this.durationOfTask;
      } else {
        return "5 - 10";
      }
    } else {
      return "5 - 10";
    }
    }

  restartStep(val,day) {
    this.modalController.dismiss();
    this.appState.set("isCurrentStepRestarted", val)
    this.homeUIService.openDailyStepsComponent(DailyStepsContentModalComponent,{currentDay: day},'web-daily-steps-custom-modal')
  }
  moveToLastScreen() {
    let screenIds = this.appState.get('currentStepScreenIds')
    this.modalController.dismiss();
    console.log("screenIDS", screenIds);
    setTimeout(() => {
      this.homeUIService.openDailyStepsComponent(DailyStepsContentModalComponent, { currentDay: this.currentDay, startFromScreen: screenIds[screenIds?.length - 1] }, 'web-daily-steps-custom-modal')
    }, 550);
  }

  close(){
    this.modalController.dismiss();
  }
  ngOnDestroy(): void {
    // this.appState.set("isCurrentStepRestarted", false);
    this.appState.set("stepScreenIds", null);
    this.appState.set('currentStepScreenIds', null)
    this.removeInputEventListeners();
  }
  
}
