import { Component, Input, OnInit,ViewChild } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { AppState } from '../app.service';
import { SessionService } from '../session/session.service';
import { ModalController,IonTextarea, } from '@ionic/angular';
import { I18n } from '../i18n.service';

@Component({
  selector: 'app-alert-give-feedback-commitment',
  templateUrl: './alert-give-feedback-commitment.component.html',
  styleUrls: ['./alert-give-feedback-commitment.component.scss']
})
export class AlertGiveFeedbackCommitmentComponent implements OnInit {
  @Input() levelTiltle;
  postText:any = ''
  formRating1:any = 3
  formRating2:any = 0
  postMsg: any = "";
  complexItems = this.appState.get("userlist")
  namesData : any[] = new Array()  
  tempValue = 0
  index = 0
  enableFinal: boolean =false;
  scaleValue: any;
  bubbles: number[] = [0, 1, 2, 3, 4];
  @ViewChild('textarea', { static: false }) textarea: IonTextarea;
  
  constructor(private appState: AppState, public i18n: I18n,private modalController: ModalController, private sessionService: SessionService) { }

  ngOnInit() {
  }
  private capitalizeSentences(text: string): string {
    return text.replace(/(?:^|\.\s)([a-z])/g, (match) => match.toUpperCase());
  }
  capitalLine(event): void {
    const textareaElement = this.textarea?.getInputElement();
    let ele = document.getElementsByClassName("web-textarea");
    // const lineHeight = parseFloat(getComputedStyle(this.textarea['el']).lineHeight);
    if (event && event.target.value) {
      this.postText = this.capitalizeSentences(event.target.value);
    }
  }
  onTextModification(ev) {
    console.log("called on change", ev.target.value);
    this.capitalLine(ev)
  }
  onSumbit(){
    this.modalController.dismiss();
    mixpanel.track('Commitments-Habit Complete Post', {value: this.postText})
    localStorage.setItem('commitmentFeedback',this.postText);
    localStorage.setItem('commitmentRating1',this.formRating1);
    localStorage.setItem('commitmentRating2',this.formRating2);
  }

  format(item:any) {
    return item['username'].toUpperCase()
  }
  formatLabel(value: number) {
    this.formRating1 = value;
    return `${value}`;
  }
  onInputChange($event) {
    this.formRating1 = $event.target.value;
   // this.setRating(this.formRating1)
  }
  setRating(): void {
    //this.formRating1 = value;
    setTimeout(() => {
      this.onSumbit();
    },200)
    
  }
}
