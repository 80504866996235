import { Component, ElementRef, OnInit, Optional, SimpleChanges, ViewChild } from '@angular/core';
import { AppState } from '../../../app.service';
import { CordovaService } from '../../../cordova.service';
import { CalendarParams } from '../../../daily-content-v2/daily-content-v2.component';
import * as moment from 'moment'
import { RoutineV2Service } from '../../routine-v2.service';
import { HomeV5Service } from '../../../pages/home-v5/home-v5.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { AlertWebCalendarComponent } from '../../../alert-web-calendar/alert-web-calendar.component';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common';
import { AlertRepeatEventComponent } from '../../../alert-repeat-event/alert-repeat-event.component';
import { ToasterService } from 'src/app/toaster.service';
import { CalendarService } from 'src/app/calendar.service';
import { HomeUiService } from 'src/app/new-design/home-ui.service';
import { I18n } from 'src/app/i18n.service';
import { AlertController, IonDatetime, ModalController, PickerController } from '@ionic/angular';
import { SessionService } from 'src/app/session/session.service';
import { Constants } from 'src/app/constants/constants';


@Component({
  selector: 'app-routine-calendar',
  templateUrl: './routine-calendar.component.html',
  styleUrls: ['./routine-calendar.component.scss']
})
export class RoutineCalendarComponent implements OnInit {
  @ViewChild('datePicker') datePicker
  calendarParams: CalendarParams
  recurrenceInterval: string;
  hasNativeCalendar: any;
  currentPath: any;
  selectedDay:any
  currentRoutine:any
  isSingleEvent:boolean = false
  isMobileWebIos = false;
  isMobileWeb=false;
  minDate: any;
  maxDate: any;
  timings: string[];
  routineTime: any;
  selectedDate;
  backTo: string;
  @ViewChild('matTime') matTime: MatSelect;
  @ViewChild('matDate', {
    read: MatInput
  }) matDate: MatInput;
  dayNameArray=[];
  todayTimeArray=[];
  isEventCreated: boolean
  routineDay: any
  singleRoutineTime: any
  cueTime: string
  newDayTimeArray: any
  isTimeEdited: boolean
  priorTimeData: any
  combinedArray = []
  cueName: any
  isMobile: boolean
  oldDayArray: any
  oldTimeArray: any
  isRoutineCalender: any
  selectedTimings: any
  selectedDays: any 
  optionType= 'option1' 
  routinAnswer: any
  routineTitle: any
  selectedDatesArray = []
  formatedselectedDate;
  commitType: any
  activityCueTime: any
  makeCommitment: any
  dayTimeArray: any
  typeValue: string
  subRoutineAnswer: any
  localRoutineData: any
  prevRoutineTime: any
  showCalender = false;
  defaultTime: string = "07:00";
  constructor( private appState: AppState,private cordovaService: CordovaService,
    private router: Router,
    private dialog: MatDialog,
    private routinev2Service: RoutineV2Service,
      private calendarService: CalendarService,
      private homeService: HomeV5Service,
      public modalController: ModalController,
      private _location: Location,
      public route: ActivatedRoute,
      public homeUIService: HomeUiService,
      private toastrService: ToasterService,
      private i18n: I18n,
      private sessionService: SessionService,
      private alertController: AlertController,
      private pickerController: PickerController,
      @Optional() public dialogRef: MatDialogRef<RoutineCalendarComponent>
      ) {
    const w: any = window
    this.hasNativeCalendar = this.cordovaService.onCordova && w && w.plugins && w.plugins.calendar
    this.currentPath = this.appState.get('currentPath')
    this.currentRoutine = this.appState.get("routineCalendarData")
    this.isSingleEvent = this.currentRoutine.isSingleEvent
    this.isTimeEdited = this.appState.get('isTimeEdited')
    this.isMobile = this.appState.get('isMobile')
    this.isRoutineCalender = this.appState.get('isRoutineCalender')
    this.selectedTimings = this.currentRoutine.routineTime
    this.selectedDays = this.currentRoutine.selectedDays
    this.routinAnswer = this.currentRoutine.routineAnswer
    this.routineTitle = this.currentRoutine.title
    this.timings = this.routinev2Service.TIMINGS_30_MINUTE
    const currentTime = new Date();
    currentTime.setHours(currentTime.getHours() + 1);
    const hours = currentTime.getHours(); // 0-23
    const minutes = currentTime.getMinutes(); // 0-59
     this.defaultTime  = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    
    this.oldDayArray = this.appState.get('differentDays')
    this.oldTimeArray = this.appState.get('differentTimes')

    if (this.oldDayArray && this.oldTimeArray) {
      this.dayNameArray = this.oldDayArray
      this.todayTimeArray = this.oldTimeArray
    }

    if (this.isRoutineCalender) {
      if (this.currentRoutine.level.routine_type === "Activity"){
        if (this.currentRoutine.level.activity_time) {
          this.cueName = this.currentRoutine.level.activity_time + " I " + this.currentRoutine.level.type_value
        }else{
          this.cueName = " before I " + this.currentRoutine.level.type_value
        }
      }
      if (this.currentRoutine.level.routine_type === "Person") {
        this.cueName = " when I encounter " + this.currentRoutine.level.type_value 
      }
      if (this.currentRoutine.level.routine_type === "Location") {
        this.cueName = " when I am " + this.currentRoutine.level.type_value 
      }
      if (this.currentRoutine.level.routine_type === "Other") {
        this.cueName = " when " + this.currentRoutine.level.type_value 
      }
      
    }else{
      if (this.currentRoutine.selectedCueOption === "Activity") {
        if (this.currentRoutine.activityCueTime) {
          this.cueName = this.currentRoutine.activityCueTime + " I " + this.currentRoutine.typeValue
        }else{
          this.cueName = " before I " + this.currentRoutine.typeValue
        }
      }
      if (this.currentRoutine.selectedCueOption === "Person") {
        this.cueName = " when I encounter " + this.currentRoutine.typeValue 
      }
      if (this.currentRoutine.selectedCueOption === "Location") {
        this.cueName = " when I am " + this.currentRoutine.typeValue 
      }
      if (this.currentRoutine.selectedCueOption === "Other") {
        this.cueName = " when " + this.currentRoutine.typeValue 
      }
    }
    
    this.newDayTimeArray = this.appState.get('dayTimeArray')    
    this.priorTimeData = this.appState.get('differentTimes')
    this.prevRoutineTime = this.appState.get('prevRoutineTime')
    if(this.appState.get('prevdays') && this.appState.get('prevTimings')){
      let diffDays = this.appState.get('prevdays')
      diffDays.forEach(element => {
        this.dayNameArray.push(element)
      });
      this.todayTimeArray = this.appState.get('prevTimings')
    }
   }

  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
    this.setMaxMinDates()
    this.backTo = this.route.snapshot.queryParamMap.get('backTo')
    this.currentRoutine = this.appState.get("routineCalendarData")
  }

  createSubRoutineEvent() {
    const success = function(message) {
      
      console.log('Success routine calendar: ' + JSON.stringify(message))
      
    }
    const error = function(message) { console.error('Error: ' + message) }
    this.recurrenceInterval='never'

    const w: any = window
    const calOptions = w.plugins.calendar.getCalendarOptions()
    const calendarList = w.plugins.calendar.listCalendars(success,error);
    
    const daysCount = 7  // LUMBA-1399
    calOptions.recurrence = this.recurrenceInterval.toLowerCase(); // supported are: daily, weekly, monthly, yearly
    calOptions.firstReminderMinutes = 0
    calOptions.recurrenceEndDate = moment(new Date(), 'HH:mm').add(daysCount, 'day').toDate()
    this.calendarService.scheduleEvents(
      this.calendarParams.title,
      this.calendarParams.eventLocation,
      this.calendarParams.notes,
      this.calendarParams.startDate,
       this.calendarParams.endDate,
       calOptions,
       success,
       error
      );
  }
  
  differentTimesDays() {
    this.isSingleEvent = true;
    this.appState.set('dayTimeArray', undefined);
    this.newDayTimeArray = [];
    this.todayTimeArray = [];
    this.currentRoutine.isSingleEvent = this.isSingleEvent
    this.appState.set("routineCalendarData", this.currentRoutine)
  }
  createDifferentSubRoutineEvent() {
    const success = function(message) {
      
      console.log('Success routine calendar: ' + JSON.stringify(message))
      
    }
    const error = function(message) { console.error('Error: ' + message) }
    //this.recurrenceInterval='never'

    const w: any = window
    const calOptions = w.plugins.calendar.getCalendarOptions()
    const calendarList = w.plugins.calendar.listCalendars(success,error);
    
    const daysCount = 7  // LUMBA-1399
    //calOptions.recurrence = this.recurrenceInterval.toLowerCase(); // supported are: daily, weekly, monthly, yearly
    calOptions.firstReminderMinutes = 0
    //calOptions.recurrenceEndDate = moment(new Date(), 'HH:mm').add(daysCount, 'day').toDate()
    // w.plugins.calendar.createEventInteractivelyWithOptions(
    //   this.calendarParams.title,
    //   this.calendarParams.eventLocation,
    //   this.calendarParams.notes,
    //   this.calendarParams.startDate,
    //   this.calendarParams.endDate,
    //   calOptions,
    //   success,
    //   error
    // )

    this.calendarService.scheduleEvents(
      this.calendarParams.title,
      this.calendarParams.eventLocation,
      this.calendarParams.notes,
      this.calendarParams.startDate,
       this.calendarParams.endDate,
       calOptions,
       success,
       error
      );
  }


  prepareRoutineCalendarParams(title:any,msg: String,routinelen:any = null,eventlen:string =null, routineTime: any=null,selectedDate:any=null) {
    var date = moment().format('LL')

    if (!routineTime) {
      this.toastrService.error(this.i18n.t("Please select routine time!"))
    }

    if(selectedDate){
     var date = moment(selectedDate).format('LL')
    }    

      var eventDate = date + " " + routineTime
      const start = moment(eventDate)
      const end = moment(eventDate).add(10, 'minute')
      var  titleOfEvent = "Daily Learning Reminder"

      if(this.currentPath.names && this.currentPath.names.titleOfEvent){
        titleOfEvent = this.currentPath.names.titleOfEvent;
      }else{
        titleOfEvent = "Daily Learning Reminder"
      }  

      this.calendarParams = {
        startDate: start.toDate(),
        endDate: end.toDate(),
        title: title, //updated title
        eventLocation: '',
        notes: msg,
        endEventTime: routinelen,
        frequency: eventlen
      }
  }
  setCalendarEvent(currentRoutine:any,routineTime:any,selectedDate: any, isTimeEvent: boolean){
    this.appState.set('isTimeEdited', false)
    var routineLen = 1

    if(!routineTime){
      this.toastrService.warning(this.i18n.t("Please select the time!"))
      return;
    }
    if(!this.isSingleEvent){
      routineLen=7
    }

    if(!this.subRoutineAnswer){
      this.subRoutineAnswer=""
    }

    if(this.hasNativeCalendar){
      this.getTimings() 
      this.getRoutineStatement(currentRoutine)
      this.prepareRoutineCalendarParams(`${Constants.appName} Habit: `+currentRoutine.title,this.subRoutineAnswer  +" "+ this.routinev2Service.innerHtmlToRoutinesData(currentRoutine.text),routineLen,'Daily',routineTime,selectedDate)
      if(this.isSingleEvent){
        this.createDifferentSubRoutineEvent()
      }else{
        this.createSubRoutineEvent()
      }

      this.appState.set('isEventCreated', true)
      // this.getTimings()       
      this.showRepeatAlert(isTimeEvent)  
    }else {
      this.getTimings() 
      this.getRoutineStatement(currentRoutine)
      this.appState.set('isEventCreated', true)
      this.prepareRoutineCalendarParams(`${Constants.appName} Habit: `+currentRoutine.title,this.subRoutineAnswer +" "+ this.routinev2Service.innerHtmlToRoutinesData(currentRoutine.text),routineLen,'Daily',routineTime,selectedDate)
      this.calendarParams.notes = this.subRoutineAnswer + this.routinev2Service.innerHtmlToRoutinesData(currentRoutine.text)
      this.homeService.setCalendarData(this.calendarParams)
                     
      const dialogRef = this.dialog.open(AlertWebCalendarComponent) 
      dialogRef.afterClosed().subscribe((result) => {

        this.showRepeatAlert(isTimeEvent)
        
      })
    }
  }
  async setCalendarEventInWeb(currentRoutine:any,routineTime:any,selectedDate: any, isTimeEvent: boolean){
    this.appState.set('isTimeEdited', false)
    var routineLen = 1

    if(!routineTime){
      this.toastrService.warning(this.i18n.t("Please select the time!"))
      return;
    }
    if(!this.isSingleEvent){
      routineLen=7
    }

    if(!this.subRoutineAnswer){
      this.subRoutineAnswer=""
    }

    if(this.hasNativeCalendar){
      this.getTimings() 
      this.getRoutineStatement(currentRoutine)
      this.prepareRoutineCalendarParams(`${Constants.appName} Habit: `+currentRoutine.title,this.subRoutineAnswer  +" "+ this.routinev2Service.innerHtmlToRoutinesData(currentRoutine.text),routineLen,'Daily',routineTime,selectedDate)
      if(this.isSingleEvent){
        this.createDifferentSubRoutineEvent()
      }else{
        this.createSubRoutineEvent()
      }

      this.appState.set('isEventCreated', true)
      // this.getTimings()       
      this.showRepeatAlert(isTimeEvent)  
    }else {
      this.getTimings() 
      this.getRoutineStatement(currentRoutine)
      this.appState.set('isEventCreated', true)
      this.prepareRoutineCalendarParams(`${Constants.appName} Habit: `+currentRoutine.title,this.subRoutineAnswer +" "+ this.routinev2Service.innerHtmlToRoutinesData(currentRoutine.text),routineLen,'Daily',routineTime,selectedDate)
      this.calendarParams.notes = this.subRoutineAnswer + this.routinev2Service.innerHtmlToRoutinesData(currentRoutine.text)
      this.homeService.setCalendarData(this.calendarParams)
                     
      //  const dialogRef = this.dialog.open(AlertWebCalendarComponent) 
      // dialogRef.afterClosed().subscribe((result) => {

      //   this.showRepeatAlert(isTimeEvent)
        
      // })
      const modal = await this.modalController.create({
        component: AlertWebCalendarComponent,
        animated: this.sessionService.isTabletDevice()? true:false,
        cssClass: this.isMobile?'custom-modal-size':'web-action-calender-modal',
       
      });
       await modal.present() 
       await modal.onDidDismiss().then(async()=>{
        await this.showRepeatAlert(isTimeEvent)
       });
    }
  }

  setMaxMinDates(){
    this.minDate = moment().format(); 
    this.maxDate = moment().add(6,'days').format();

  }

  changeTime(time) {
    this.routineTime = time;
    this.appState.set('routineTime',this.routineTime) 
  }
  changeTimeInWeb(ev) {
    console.log("changed data on changeTime",ev)
    this.routineTime = ev?.detail?.value;
    this.appState.set('routineTime',this.routineTime) 
  }

  dateChange(date){

    this.selectedDate = date.value
    this.appState.set('selectedDate',this.selectedDate)
    this.selectedDatesArray.push(this.selectedDate)
    this.appState.set('multipleDates',this.selectedDatesArray)
    console.log('this.selectedDatesArray :', this.selectedDatesArray);
    this.openPicker(this.defaultTime)
  }
  dateChangeOnWeb(ev) {
    this.showCalender =false;
    console.log("changed data",ev, typeof(ev?.detail?.value))
    const selectedDateObject: Date = new Date(ev.detail.value);
    if (!this.selectedDatesArray.some(date => date.getTime() === selectedDateObject.getTime())) {
      this.formatedselectedDate = new Intl.DateTimeFormat('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).format(selectedDateObject);
      this.selectedDate = selectedDateObject;
      this.appState.set('selectedDate',this.selectedDate)
      this.selectedDatesArray.push(this.selectedDate);
      this.appState.set('multipleDates',this.selectedDatesArray)
      console.log('this.selectedDatesArray :', this.selectedDatesArray,typeof(selectedDateObject),this.selectedDate, typeof(this.selectedDate));
      this.openPicker(this.defaultTime)
    }
  }
  openDateTimePicker() {

    this.showCalender =true;
  }
  close(){
    if(this.isMobile){
      this.appState.set('isTimeEdited', false)
      this.dialogRef.close()  
    }
    else {
      this.appState.set('isTimeEdited', false)
      this.modalController.dismiss()
    }
      
  }

  getTimings(){
    this.singleRoutineTime = this.routineTime
    if (!this.isSingleEvent) {
      this.todayTimeArray = [];
    }
    this.todayTimeArray.push(this.routineTime)
    this.appState.set('differentTimes', this.todayTimeArray)
    console.log("this.appState.set('differentTimes'", this.appState.get('differentTimes'));
    var splitElement
    let newArray = []
    //var combinedArray = []
    if (this.newDayTimeArray?.length>0 && this.isSingleEvent) {      
      this.newDayTimeArray.forEach(element => {
        splitElement = element.split(" ",1)
        newArray.push(splitElement[0])
      });
    }

    var today: any
    var dayName: any
    this.routineDay = dayName
    if (this.selectedDate) {
      today = (this.selectedDate).getDay();
      dayName = this.routinev2Service.getDayFromDate(today)
      this.dayNameArray.push(dayName)
      if (newArray.length >= 1) {
        newArray.forEach(element => {
          this.combinedArray.push(element)
        });
        this.dayNameArray.forEach(element => {
          this.combinedArray.push(element)
        });
      }

      this.appState.set('differentDays', this.dayNameArray)
            
    }

  }

  async showRepeatAlert(isTimeEvent: boolean){
     if(this.isMobile) {
        if (isTimeEvent === true) {
          this.dialogRef.close()
        }else{
          const dialogRepeat = this.dialog.open(AlertRepeatEventComponent, {
            panelClass: this.homeUIService.isDark() ? 'dark-theme' : 'light-theme'
          })
          dialogRepeat.afterClosed().subscribe((result) => {
            this.isTimeEdited = this.appState.get('isTimeEdited')
            if (result == 'confirm') {
              this.isEventCreated = true
              if (this.matTime) { this.matTime.options.forEach((data: MatOption) => data.deselect()); }
              if (this.matDate) { this.matDate.value = '' }
            }else{
              this.dialogRef.close()
              this.isEventCreated = true
              if (this.matTime) { this.matTime.options.forEach((data: MatOption) => data.deselect()); }
              if (this.matDate) { this.matDate.value = '' }
            }
          })
        }
       }
       else {
        console.log("enter repeat alert", isTimeEvent);
        if (isTimeEvent === true) {
          setTimeout(()=>{
            this.modalController.dismiss();
           },100)
        }else{
          // const dialogRepeat = this.dialog.open(AlertRepeatEventComponent, {
          //   panelClass: this.homeUIService.isDark() ? 'dark-theme' : 'light-theme'
          // })
          const alert = await this.alertController.create({
            subHeader: this.i18n.t('Do you want to schedule other days & times?'),
            cssClass:this.homeUIService.isDark() ? 'dark-theme' : 'light-theme',
            buttons: [
              {
                text: this.i18n.t("No"),
                handler: () =>{
                  this.isTimeEdited = this.appState.get('isTimeEdited')
                  this.modalController.dismiss();
                  this.isEventCreated = true
                  if (this.matTime) { this.matTime.options.forEach((data: MatOption) => data.deselect()); }
                  if (this.matDate) { this.matDate.value = '' }
                },
                role: 'cancel',
              },
              {
                text: this.i18n.t("Yes"),
                handler: () => {
                  this.isTimeEdited = this.appState.get('isTimeEdited')
                  this.isEventCreated = true
                  if (this.matTime) { this.matTime.options.forEach((data: MatOption) => data.deselect()); }
                  if (this.matDate) { this.matDate.value = '' }

    
                },
              },
            ],
          })
          await alert.present();
        }
       }
  }

  deleteDate(day){
    const index: number = this.dayNameArray.indexOf(day);
    this.dayNameArray.splice(index, 1);
    this.appState.set('differentDays', this.dayNameArray)

    this.todayTimeArray.splice(index, 1);
    this.appState.set('differentTimes',this.todayTimeArray)

    const combinedIndex: number = this.combinedArray.indexOf(day);
    this.combinedArray.splice(combinedIndex, 1);
  }

  getRoutineStatement(routine: any){
    let differentTimes1 = this.todayTimeArray
     differentTimes1 = this.routinev2Service.formatTimeForFrontEnd(differentTimes1);

    let differentDays1 = this.dayNameArray

    if (differentDays1.length>0 && differentDays1?.length == differentTimes1?.length) {
      this.dayTimeArray = differentDays1.map(function (x, i) {
        return x + ' at ' + differentTimes1[i]
      })

      this.subRoutineAnswer = this.routinev2Service.createCommitEvent(routine, routine.level, routine.typeValue,
        routine.activityCueTime,
        differentTimes1, differentDays1, routine.commitType, routine.makeCommitment, "on " + this.dayTimeArray.join(', '))
    } else if (differentDays1.length<1 || (differentDays1.length ==7 && differentTimes1?.length==1)) {
      // differentDays = 'every day'
      this.dayTimeArray = "every day at " + differentTimes1
      this.subRoutineAnswer = this.routinev2Service.createCommitEvent(routine, routine.level, routine.typeValue, routine.activityCueTime, differentTimes1, 'every day', routine.commitType, routine.makeCommitment, this.dayTimeArray)
    }
    
  }

   async openPicker(
    time
   ) {
     
     console.log("everyday at",new Date().getHours(),  this.defaultTime);
    let defaultHour = parseInt(this.defaultTime.split(":")[0]);
    if (time) {
      defaultHour = parseInt(time.split(":")[0]);
    }
    const defaultMinute = parseInt(
      this.defaultTime.split(":")[1].split(" ")[0]
    );
    let defaultAmPm = this.defaultTime.split(" ")[1];
    defaultAmPm = defaultHour < 12 ? "AM" : "PM";

    if (defaultHour === 0) {
      defaultHour = 12;
    } else if (defaultHour > 12) {
      defaultHour -= 12;
      defaultAmPm = "PM";
    }
    const picker = await this.pickerController.create({
      columns: [
        {
          name: "hour",
          options: this.generateHourOptions(), // For hours in 12-hour format
          selectedIndex: defaultHour - 1,
        },
        {
          name: "minute",
          options: this.generateColumnOptions(), // For minutes
          selectedIndex: defaultMinute === 0 ? 0 : 1,
        },
        {
          name: "ampm",
          options: [
            { text: "AM", value: "AM" },
            { text: "PM", value: "PM" },
          ],
          selectedIndex: defaultAmPm === "AM" ? 0 : 1,
        },
      ],
      buttons: [
        {
          text: this.i18n.t("Cancel"),
          role: "cancel",
        },
        {
          text: this.i18n.t("Confirm"),
          handler: (value) => {
            console.log("value", value);
            this.routineTime = value.hour.value + ':' + value.minute.value + ' ' + value.ampm.value;
             this.appState.set('routineTime',this.routineTime) 
            if (this.isMobile) {
              this.setCalendarEvent(this.currentRoutine, this.routineTime, this.selectedDate, this.isSingleEvent?false:true)
            }
            else {
              this.setCalendarEventInWeb(this.currentRoutine, this.routineTime, this.selectedDate, this.isSingleEvent?false:true)
            }
          },
        },
      ],
      cssClass: `${this.homeUIService.isDark() ? "dark-theme" : "light-theme"}`,
    });

    await picker.present();
   }
  
  generateHourOptions() {
    const options = [];
    for (let i = 1; i <= 12; i++) {
      options.push({
        text: i.toString(),
        value: i.toString().padStart(2, "0"),
      });
    }
    return options;
  }
  generateColumnOptions() {
    const options = [
      { text: "00", value: "00" },
      { text: "30", value: "30" },
    ];
    return options;
  }

  notsureTimesDays(makeRoutine,value,type) {

    this.appState.set('commitData',`${makeRoutine},${type},${value}`)
    localStorage.setItem('commitData', `${makeRoutine},${type},${value}`)
    
    this.appState.set('makeCommitment',makeRoutine)
    localStorage.setItem('makeCommitment', makeRoutine)

    if(this.isMobile){
      this.appState.set('isTimeEdited', false)
      this.dialogRef.close(`${makeRoutine},${type},${value}`);
    }
    else {
      this.appState.set('isTimeEdited', false)
      this.modalController.dismiss()
    }
  }
}


