declare var require: any;
import { EventEmitter, Injectable, NgZone } from "@angular/core";
import { Apollo } from "apollo-angular";
import * as query from "./session.query";
import { get } from "object-path";
import { map } from "rxjs/operators";
import { Observable, Subscription, interval, of } from "rxjs";
import { AppState } from "../app.service";
import { User } from "../user/user.model";
import { Client } from "../client/client.model";
import { Path } from "../path/path.model";
import { Question } from "../question/question.model";
import { WalletService } from "../wallet/wallet.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ThemeService } from "../theme/theme.service";
// import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { AlertController, Platform } from "@ionic/angular";
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { I18n } from "../i18n.service";
import { JournalService } from "../pages/journal/journal.service";
import { ToasterService } from "../toaster.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { HomeV5Service } from "../pages/home-v5/home-v5.service";
import { RoutineService } from "../daily-content-v2/routine.service";
import { Badge } from "@awesome-cordova-plugins/badge/ngx";
import { SpinnerService } from "../spinner.service";
import { RoutineV2Service } from "../routine-v2/routine-v2.service";
import { PathRegistration } from "../user/path-registration.model";
import { UserService } from "../user/user.service";
import mixpanel from 'mixpanel-browser';
import { MyLibraryComponent } from "../my-library/my-library.component";
import { HomeUiService } from "../new-design/home-ui.service";
import { DailyStepsService } from "../daily-steps/daily-steps.service";
import * as moment from "moment";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { MyCommitmentsComponent } from "../my-commitments/my-commitments.component";
import { MobileChatBoardComponent } from "../pages/mobile-chat-board/mobile-chat-board.component";
import { Deeplinks } from "@awesome-cordova-plugins/deeplinks/ngx";
import { Constants } from "../constants/constants";
import { AppVersion } from "@awesome-cordova-plugins/app-version/ngx";
import { Title } from "@angular/platform-browser";
import { CordovaService } from "../cordova.service";

// var moment = require("moment");

declare var FCMPlugin: any;

export interface MultipleSignInByForm {
  [multipleSignInByForm: string]: any;
}

export interface MultipleSignInByToken {
  [multipleSignInByToken: string]: any;
}

export interface MultipleSignInBySSO {
  [multipleSignInBySso: string]: any;
}

export interface UpdatePathRegistration {
  [updatePathRegistration: string]: any;
}

function _window(): any {
  // For Cordova `navigator.app` access
  return window;
}
export interface UpdateUser {
  [updateUser: string]: any
}
const API_URL: string = environment.apiBaseUrl
const APP_NOTIFICATION: string = '/app_notification'

@Injectable()
export class SessionService {
  public afterRefreshEvent: EventEmitter<null> = new EventEmitter<null>();

  photoUrl: any;
  public openQuestionFeed: any;
  isNewPhoto: boolean;
  todaysDate: any;
  givenDate: any;
  static data: any[];
  urlRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?#&])[A-Za-z\d@$!%*?#&]{7,}$/;
  emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  static index = 0;
  static dataArray: any[];
  escapeRegExpMatch: any;
  isExactMatch: any;
  isMobile: boolean;
  public signIn: MultipleSignInByForm;
  public signInByToken: MultipleSignInByToken;
  public signInSSO: MultipleSignInBySSO;
  udiD: string;
  timerValue: number = 0;
  private timerSubscription: Subscription;
  public updatePathRegistration: UpdatePathRegistration;
  userUpdate: UpdateUser

  constructor(
    private apollo: Apollo,
    private appState: AppState,
    private walletService: WalletService,
    private themeService: ThemeService,
    private router: Router,
   private firebaseX:FirebaseX,
    private platform: Platform,
    public alertController: AlertController,
    private journalService: JournalService,
    private i18n: I18n,
    private http: HttpClient,
    private homeUIService: HomeUiService,
    private homev5Service: HomeV5Service,
    private routineService: RoutineService,
    private routineV2Service: RoutineV2Service,
    private badge: Badge,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
    private deeplinks: Deeplinks,
    private spinnerService: SpinnerService,
    private userService: UserService,
    private dailystepService: DailyStepsService,
    private screenOrientation: ScreenOrientation,
    private appVersion: AppVersion,
    private titleService: Title,
    private cordovaService: CordovaService
  ) {
    this.todaysDate = moment();
    this.escapeRegExpMatch = function (s) {
      return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
    };
    this.isExactMatch = (str, match) => {
      return new RegExp(`\\b${this.escapeRegExpMatch(match)}\\b`).test(str);
    };
    this.checkMobile();
  }

  newSessionByToken(token: string): Observable<boolean> {
    return this.apollo
      .mutate({
        mutation: query.multipleSignInByToken,
        variables: { token: token },
      })
      .pipe(
        map((response) => {
          this.appState.set('showLoader', undefined)
          this.signInByToken = response.data;
          const data = this.signInByToken.multipleSignInByToken;
          
          if (!data.result) {
            this.removeCurrentData();
            window.location.reload();
            this.afterRefreshEvent.emit(); // LUMBA-1318
            return false;
          }
          console.log('multipleSignInByToken data :', data);
          mixpanel.track('Login-session start', {'email': data.user.email})
          mixpanel.identify(data.user.id)
          mixpanel.people.set({ "$name":data.user.firstName + ' ' + data.user.lastName, "$email": data.user.email,"$pathId": data.path.id, '$user_id':data.user.id})
          this.setCurrentData(data);
          this.updateUserTimeZone(data.user);
          this.getLibraryPosts()
          this.platform.ready().then(() => {
            if(this.platform.is('android') || this.platform.is('ios')){
              this.getTokenAfterLogin(data)
              let afterAppInitData = this.appState.get('AfterAppInit')
              this.routeAfterNotification(afterAppInitData)
            }
          })
          this.deepLinkRoute();
          const showCloseout = this.checkForCloseout(data);
          if (showCloseout) {
            this.router.navigate(["/closeout"]);
          }
          this.afterRefreshEvent.emit(); // LUMBA-1318
          return true;
        })
      );
  }

  routeAfterNotification(afterAppInitData) {
    let savedCommunityId: number
    savedCommunityId = parseInt(localStorage.getItem('CommunityNotificationId'))
    let savedCommitmentsId: number
    savedCommitmentsId = parseInt(localStorage.getItem('CommitmentsNotificationId'))
    if (afterAppInitData) {
      const requestData = {
        mode: afterAppInitData.mode,
        postId: afterAppInitData.postId,
        id: afterAppInitData.id,
        route: afterAppInitData.route
      }
      if (afterAppInitData.route === '/main/questions' && (afterAppInitData.id > savedCommunityId || !savedCommunityId)) {
        this.appState.set('communityNotification', true)
        this.appState.set('notificationData', requestData)
        // this.router.navigate(['/main/questions']);
        // this.router.navigate(['/main']);
        setTimeout(() => {
          this.homeUIService.openNextComponent(MobileChatBoardComponent);
        },1300)
      }else if (afterAppInitData.route === '/main/commitments' && (afterAppInitData.id > savedCommitmentsId || !savedCommitmentsId)) {
        localStorage.setItem('CommitmentsNotificationId', afterAppInitData.id.toString())
        // this.router.navigate(['/main/commitments']);
        setTimeout(() => {
          this.homeUIService.openNextComponent(MyCommitmentsComponent);
        },1300);
      }else{
        console.log('requestData inside else (Sessions)', requestData);
      }
      
    }
  }

  async getTokenAfterLogin(data) {
    await this.platform.ready().then(() => {
      this.firebaseX
        .getToken()
        .then((token) => {
          localStorage.setItem("deviceToken", token);
          console.log(`The token is ${token}`);
        })
        .catch((error) => console.error("Error getting token", error));

      this.firebaseX.onTokenRefresh().subscribe((token: string) => {
        localStorage.setItem("deviceToken", token);
        this.saveToken(data);
        console.log("New device token : ", token);
      });
    }).catch(error => {
      console.log("platform  error : ", error);
    });
  }
  updateUserTimeZone(user) {
    // console.log("user in timezone",user, moment.tz.names())
    user = this.appState.get('currentUser');
    delete user.profileUploadedDay;
    // console.log("before updating timezone", user);
    let localTimeZone= moment.tz.guess();
    // console.log("after updating timezone", user)
    if (user.timezone != localTimeZone) {
      user.timezone = localTimeZone;
      delete user.userBgColor;
      this.userService.save(user).subscribe(response => {
        this.userUpdate = response.data
        const updatedUser = new User(this.userUpdate.updateUser);
        user = updatedUser
        this.appState.set('currentUser', updatedUser)
      });
    }
   
  }
  deepLinkRoute() {
    console.log("window.location.pathname", window.location.pathname);
    if (this.platform.is('cordova')) {
       this.deeplinks
         .route({
           [Constants.deepLinkRouter]: MyCommitmentsComponent,
         })
         .subscribe(
           (match) => {
             this.ngZone.run(() => {
               // this.router.navigate(["/main"]); // path would be like /auth/password/reset/123xyz
               setTimeout(() => {
                 this.homeUIService.openNextComponent(MyCommitmentsComponent);
               }, 650);
             });
           },
           (nomatch) => {
             console.log("link not matched");
             console.log("window.location.pathname", window.location.pathname);
             this.activatedRoute.paramMap.subscribe((params) => {
               let param = params.get("param");
               if (param) {
                 // Handle your link based on the parameter
                 console.log("Link parameter:", param);
               }
             });
           }
         );
    }
    else if(window.location.pathname =='/main/commitments') {
      this.ngZone.run(() => {
       this.router.navigate(["/main"]); // path would be like /auth/password/reset/123xyz
        setTimeout(() => {
          this.homeUIService.openNextComponent(MyCommitmentsComponent,'',this.isMobile?'':'desktop-todo')
        }, 350);
      });
    }
   
  }
  saveToken(data) {
    let deviceId;
    if (this.isMobile && (localStorage.getItem('deviceToken') !== null || localStorage.getItem('deviceToken') != undefined)) {
      deviceId =localStorage.getItem('deviceToken')
    }
    else {
      deviceId = data.pathRegistration.pathData.deviceRegistrationId;
    }
    const pathRegistration = new PathRegistration({
      userId: data.user.id,
      pathId: data.path.id,
      pathData: {
        loginReminderEnabled: data.pathRegistration.pathData.loginReminderEnabled,
        loginReminderTime: data.pathRegistration.pathData.loginReminderTime,
        deviceRegistrationId: deviceId,
        emailReminderEnabled: data.pathRegistration.pathData.emailReminderEnabled,
        pushReminderEnabled: data.pathRegistration.pathData.pushReminderEnabled,
        socialReminderEnabled: data.pathRegistration.pathData.socialReminderEnabled,
        socialEmailEnabled: data.pathRegistration.pathData.socialEmailEnabled,
      }
    });
    this.userService.savePathRegistration(pathRegistration).subscribe(
      (response) => {
        this.updatePathRegistration = response.data
        this.appState.set("pathRegistration", this.updatePathRegistration.updatePathRegistration);
        // console.log('response from session:', response);
      },
      (error) => {
        console.log("Server error", error);
      });
  }

  refreshSession() {
    const token = localStorage.getItem("token");
    return token ? this.newSessionByToken(token) : of(false);
  }

  // Backwards compatible with boolean
  newSession(signInInput: any): Observable<any> {
    return this.apollo
    .mutate({
      mutation: query.multipleSignInByForm,
      variables: signInInput,
    })
    .pipe(
      map((response) => {
        this.signIn = response.data;
        mixpanel.track('Login-session start', {'email': this.signIn.multipleSignInByForm.user.email})
        mixpanel.identify(this.signIn.multipleSignInByForm.user.id)
          mixpanel.people.set({ "$name":this.signIn.multipleSignInByForm.user.firstName + ' ' + this.signIn.multipleSignInByForm.user.lastName, "$email": this.signIn.multipleSignInByForm.user.email,"$pathId": this.signIn.multipleSignInByForm.path.id, '$user_id':this.signIn.multipleSignInByForm.user.id})
          const data = this.signIn.multipleSignInByForm;
          if (data.token) {
            localStorage.setItem("token", data.token);
            mixpanel.identify(this.signIn.multipleSignInByForm.user.id)
            this.setCurrentData(data);
            this.updateUserTimeZone(data.user);
            this.getLibraryPosts()
            this.platform.ready().then(() => {
              if(this.platform.is('android') || this.platform.is('ios')){
                this.getTokenAfterLogin(data)
                mixpanel.identify(this.signIn.multipleSignInByForm.user.id)
              }
            })
            this.deepLinkRoute();
            const showCloseout = this.checkForCloseout(data);
            return {
              showOnboardingSurvey: data.showOnboardingSurvey,
              showCloseout: showCloseout,
              data: data,
            };
          }
          if (!data.expiredOrRemoved) {
            return false;
          }
          return { expiredOrRemoved: true, client: data.client };
        })
      );
  }

  ngOnDestroy() {
    this.stopTimer();
    mixpanel.track('Login-session end', {
      navigator_sendBeacon: true
    });
  }

  newSessionSSO(signInInput: any): Observable<any> {
    console.log("query.multipleSignInBySso ", query.multipleSignInBySso);
    console.log("signInInput session.service ", signInInput);

    return this.apollo
      .mutate({
        mutation: query.multipleSignInBySso,
        variables: signInInput,
      })
      .pipe(
        map((response) => {
          this.signIn = response.data;
          const data = this.signIn.multipleSignInBySso;
          if (data.token) {
            mixpanel.track('Login-session start', {'email': data.user.email})
            mixpanel.identify(data.user.id)
              mixpanel.people.set({ "$name":data.user.firstName + ' ' + data.user.lastName, "$email": data.user.email,"$pathId": data.path.id, '$user_id':data.user.id})
            this.setCurrentData(data);
            this.updateUserTimeZone(data.user);
            this.getLibraryPosts()
            this.platform.ready().then(() => {
              if(this.platform.is('android') || this.platform.is('ios')){
                this.getTokenAfterLogin(data)
                mixpanel.identify(data.user.id);
              }
            })
             this.deepLinkRoute();
            console.log("Login Data ", data);
            localStorage.setItem("token", data.token);
            const showCloseout = this.checkForCloseout(data);
            return {
              showOnboardingSurvey: data.showOnboardingSurvey,
              showCloseout: showCloseout,
              data: data,
            };
          }
          if (!data.expiredOrRemoved) {
            return false;
          }
          return { expiredOrRemoved: true, client: data.client };
        })
      );
  }

  validateSession(signInInput: any): Observable<any> {
    console.log("signInInput session.service ", signInInput);

    return this.apollo
      .mutate({
        mutation: query.validateDeletion,
        variables: signInInput,
      })
      .pipe(
        map((response) => {
          this.signIn = response.data;
          const data = this.signIn.multipleSignInByForm;
          if (data.token) {
            localStorage.setItem("token", data.token);
            const showCloseout = this.checkForCloseout(data);
            return {
              showOnboardingSurvey: data.showOnboardingSurvey,
              showCloseout: showCloseout,
              data: data,
            };
          }
          if (!data.expiredOrRemoved) {
            return false;
          }
          return { expiredOrRemoved: true, client: data.client };
        })
      );
  }


  checkForCloseout(data) {
    const pathGroupId = localStorage.getItem("pathGroupId");
    const seen = localStorage.getItem(`Closeout:${pathGroupId}`);
    if (data.showCloseoutSurvey && !seen) {
      localStorage.setItem(`Closeout:${pathGroupId}`, "true");
      return true;
    }
    return false;
  }

  destroy() {
    this.removeCurrentData();
    this.apollo.getClient().clearStore();
  }

  removeLocalStorageData() {
    const exclude = ["token", "deviceToken"];
    const localStorageLength = localStorage.length;
    const keys = [];
    for (let i = 0; i < localStorageLength; i++) {
      const key = localStorage.key(i);
      if (exclude.indexOf(key) === -1) {
        keys.push(key);
      }
    }
    keys.forEach((key) => localStorage.removeItem(key));
  }

  // LUMBA-982
  changePathData(data: any) {
    localStorage.setItem("pathGroupId", get(data, "pathGroup.id"));
    localStorage.setItem("pathGroupName", get(data, "pathGroup.name"));
    localStorage.setItem(
      "pathTheme",
      this.themeService.stringifyTheme(
        get(data, "path.colours"),
        get(data, "client")
      )
      
    ); // LUMBA-1402
    this.themeService._composeTheme(
      get(data, "path.colours"),
      get(data, "client")
    )
    const path = new Path(data.path);
    this.themeService.apply(
      get(data, "path"),
      get(data, "client")
    )    

    localStorage.setItem("activePathId", path.id.toString());
    this.appState.set("currentPath", path);
    this.appState.set("DailyStepsData", path?.dailySteps);
    this.homeUIService.setRelationshipState(path?.dailySteps);
    this.appState.set("language", path.language);
    const chatBoard = new Question({
      text: "",
      replies: path.chatBoardReplies,
    });
    this.appState.set("currentChatBoard", chatBoard);
    this.appState.set("currentScheduleDay", data.scheduleDay);
    this.appState.set("maxAllowedScheduleDay", data.scheduleDay); // LUMBA-1301
    this.appState.set("scheduleDayForCurrentStep", data.scheduleDay);
    this.appState.set("daysOfContent", path.daysOfContent);
    this.appState.set("totalDayOfContent", path.daysOfContent);
    this.appState.set("allContentCompleted", data.allContentCompleted);
    this.appState.set("data", data);
    this.walletService.update(get(data, "pathRegistration.earnedActionPoints"));
    this.appState.set("managerEmailCheck", data.user.managerEmailCheck);
    this.appState.set("managerEmail", data.user.managerEmail);
    this.appState.set("accountabilityCheck", data.user.accountabilityCheck);
    this.appState.set("accountabilityEmails", data.user.accountabilityEmails);

    this.appState.set("video_path", data.path.videopath);

    console.log("Path Data login data : ", data);
  }

  private setCurrentData(data: any) {
    this.removeNotificationBadge()
    localStorage.setItem("pathGroupId", get(data, "pathGroup.id"));
    localStorage.setItem("pathGroupName", get(data, "pathGroup.name"));
    localStorage.setItem("learningTime", data.pathRegistration.pathData.loginReminderTime)
    if(data.scheduleDay>1){
      localStorage.setItem('stepCompleted',data.scheduleDay)
    }
    this.appState.set("pathRegistration", data.pathRegistration);
    this.timerValue = 0;
    this.startTimer();
    // LUMBA-1396
    localStorage.setItem(
      "pathTheme",
      this.themeService.stringifyTheme(
        get(data, "path.colours"),
        get(data, "client")
      )
    );
   

    this.appState.set("isAuthenticated", true);
    const user = new User(data.user);
    this.appState.set("currentUser", user);
    localStorage.setItem('userid', user.id.toString());
    this.appState.set("currentClient", new Client(data.client));
    const path = new Path(data.path);
    this.routineV2Service.setRoutinePathDetails(data.path);
    this.appState.set("currentPath", path);
    this.appState.set("DailyStepsData", path?.dailySteps);
    this.homeUIService.setRelationshipState(path?.dailySteps);
    this.themeService.apply(
      path,
     data.client
     )

    this.themeService._composeTheme( path.colours,
      data.client)
    this.appState.set("video_path", data.path.videopath);

    this.appState.set("routineInfo", data.path.routineInfo);

    // LUMBA-982
    localStorage.setItem("clientPathCount", data.pathCount);
    localStorage.setItem("clientId", data.client.id.toString());
    localStorage.setItem("activePathId", path.id.toString());
    const subDomain = this.themeService.getSubDomain();
    if (subDomain) {
      localStorage.setItem("subDomain", subDomain);
    }

    this.appState.set("language", path.language);
    const chatBoard = new Question({
      text: "",
      replies: path.chatBoardReplies,
    });
    this.appState.set("isLoggedIn", true);
    this.appState.set("currentChatBoard", chatBoard);
    this.appState.set("currentScheduleDay", data.scheduleDay);
    this.appState.set("maxAllowedScheduleDay", data.scheduleDay); // LUMBA-1301
    this.appState.set("daysOfContent", path.daysOfContent);
    this.appState.set("scheduleDayForCurrentStep", data.scheduleDay);
    this.appState.set("allContentCompleted", data.allContentCompleted);
    this.walletService.update(get(data, "pathRegistration.earnedActionPoints"));
    this.appState.set("managerEmail", data.user.managerEmail);

    this.appState.set("managerEmailCheck", data.user.managerEmailCheck);
    this.appState.set("accountabilityCheck", data.user.accountabilityCheck);
    this.appState.set("accountabilityEmails", data.user.accountabilityEmails);
    this.appState.set("routineVersion", path.pathGroup.enabledAttributes);

    this.routineService.getTodoList(path.id)
    this.dailystepService.getActions(user.id, path.id)
    this.getAppInfo(user.id)
  }

  private removeCurrentData() {
    localStorage.removeItem("pathGroupId");
    localStorage.removeItem("pathGroupName");
    localStorage.removeItem("token");
    localStorage.removeItem('stepCompleted')
    localStorage.clear();
    this.appState.set("isAuthenticated", false);
    this.appState.set("DailyStepsData", undefined);
    this.homeUIService.setRelationshipState(undefined);
    this.cordovaService.setVideoViewedStatus(undefined);
    this.appState.set("currentUser", undefined);
    this.appState.set('isHamburgerOpen', undefined);
    this.appState.set("to-do-comp", undefined);
    this.appState.set('IsOptionSelected', undefined);
    this.appState.set('expandNotCommittedActions', undefined);
    this.appState.set("currentClient", undefined);
    this.appState.set("currentPath", undefined);
    this.appState.set("currentChatBoard", undefined);
    this.appState.set("currentScheduleDay", undefined);
    this.appState.set('IsRepsAppendAfterReoptCommit', undefined);
    this.appState.set('isLevelClosedBBeforeCommit',undefined)
    this.appState.set("maxAllowedScheduleDay", undefined); // LUMBA-1301
    this.appState.set("daysOfContent", undefined);
    this.appState.set("verifyEmail", undefined);
    this.appState.set('MobileWeb', undefined);
    this.appState.set("video_path", undefined);
    this.appState.set("showCloseoutSurvey", undefined);
    this.appState.set("showOnboardingSurvey", undefined);
    this.appState.set("managerEmail", undefined);
    this.appState.set("routineInfo", undefined);
    this.appState.set("levelData", undefined);
    this.appState.set("subRoutine", undefined);
    this.appState.set('MobileWebIos', undefined)
    this.appState.set('allPathCount', undefined);
    this.appState.set("allPathsData", undefined);
    this.appState.set("levelId", undefined);
    this.appState.set("levelNumber", undefined);
    this.appState.set("isImInCompleted", undefined);
    this.appState.set("isScreenViewed", undefined);
    this.appState.set("changeCue", undefined);
    this.appState.set("LevelNumber", undefined);
    this.appState.set("isRoutineEdited", undefined);
    this.appState.set("routineVersion", undefined);
    this.appState.set("isConfirmed", undefined);
    this.appState.set("isRoutineCalender", undefined);
    this.appState.set("routines", undefined);
    this.appState.set("stepScreenIds", null);
    this.appState.set("allowScreenCount", undefined);
    this.appState.set("screensViewed", undefined);
    this.appState.set('stepsArray', undefined);
    this.appState.set('stepObject', undefined);
    this.appState.set('pathRegistration', undefined);
    localStorage.setItem("dontShowAgain", 'true');
    localStorage.setItem("isAppUpdated", 'true');
    this.appState.set('savedPosts', undefined)
    this.appState.set('isNewStep', undefined)
    this.appState.set('recentSavedStep', undefined)
    this.appState.set('unCheckedLevels', undefined)
    this.appState.set('indexOfLevel', undefined)
    this.appState.set('selectedLevelIds', undefined)
    this.appState.set('levelUpdated', undefined)
    this.appState.set('screenId', undefined)
    this.appState.set('showUpdatedScreens', undefined)
    this.appState.set('lastTodoScreenStep', undefined)
    this.appState.set('isTodoReps', undefined)
    this.appState.set('levelSaveData', undefined)
    this.appState.set("isFromEdit", undefined)
    this.appState.set('allLevelsCompleted', undefined)
    this.appState.set('levelEdit', undefined)
    this.appState.set('toDos', undefined)
    this.appState.set('todoActions', undefined)
    this.appState.set('todoRoutines', undefined)
    this.appState.set('showLoader', undefined)
    localStorage.removeItem('currentScreenIndexAfterScreenView')
    localStorage.removeItem('currentDayAfterScreenView')
    localStorage.removeItem('screensIdsAfterScreenView')
    this.appState.set('screenStatus', undefined)
    localStorage.removeItem('toDosWhenRefreshed')
    this.appState.set('loggedInDates', undefined)
    localStorage.removeItem('PathProgress')
    this.appState.set('weekDays', undefined)
    localStorage.removeItem('stepCompleted')
    this.appState.set('prevPathData', undefined)
    this.appState.set('tabLabel', undefined)
    this.appState.set('libraryTabChanged', undefined)
    this.appState.set('savedPosts', undefined)
    this.appState.set("deletedPostId", undefined);
    this.appState.set('repliesTreeObject', undefined)
    this.appState.set('cohortsData', undefined)
    localStorage.removeItem("clientId");
    this.walletService.update(0);
    this.stopTimer();
    console.log(
      "get removed data",
      this.appState.set("currentUser", undefined)
    );
  }

  public get url() {
    //console.log('get url',this.photoUrl);
    return this.photoUrl;
  }

  public set url(photoUrl) {
    this.photoUrl = photoUrl;
    console.log("set url", this.photoUrl);
  }

  public getQuestion(): Observable<any> {
    return this.openQuestionFeed;
  }

  public setQuestion(openQuestionFeed: any): void {
    this.openQuestionFeed = openQuestionFeed;
  }

  public static getData(): any {
    return this.data;
  }

  public static setData(data: any): void {
    this.data = data;
  }

  createTimeStamp(innerDate) {
    let lang=this.appState.get("currentPath")
    this.todaysDate.locale(lang.language);
    // moment.locale(lang.language)
    this.givenDate = moment.utc(innerDate);
    this.givenDate = this.givenDate.locale(lang.language)
    var isThisWeek = this.givenDate.isSame(this.todaysDate, "week");
    let dayData = this.givenDate.locale(lang.language).fromNow();
    if (
      this.givenDate.format("MMMM Do YYYY") ===
      this.todaysDate.locale(lang.language).format("MMMM Do YYYY")
    ) {
      return  dayData;
    } else if (isThisWeek) {
      return this.i18n.t(this.givenDate.format("dddd"));
    } else {
      return this.i18n.t(this.givenDate.format("dddd MMMM Do YYYY"));
    }
  }

  createDateStamp(innerDate) {
    let lang=this.appState.get("currentPath")
    this.todaysDate.locale(lang.language);
    this.givenDate = moment.utc(innerDate).local();
    this.givenDate = this.givenDate.locale(lang.language)
    var isThisWeek = this.givenDate.isSame(this.todaysDate, "week");
    let dayData = this.givenDate.fromNow();

    if (
      this.givenDate.format("MMMM Do YYYY") ===
      this.todaysDate.locale(lang.language).format("MMMM Do YYYY")
    ) {
      // return dayData;
      return this.i18n.t(this.givenDate.format("MMMM Do YYYY"));
    } else if (isThisWeek) {
      // return this.givenDate.format("dddd");
      return this.i18n.t(this.givenDate.format("MMMM Do YYYY"));
    } else {
      return this.i18n.t(this.givenDate.format("MMMM Do YYYY"));
    }
  }

  convertToHTML(text) {
    return text.replace(this.urlRegex, function (url) {
      if (url.startsWith("www") || url.startsWith("WWW")) {
        return '<a href="https://' + url + '" target="_blank">' + url + "</a>";
      } else {
        return '<a href="' + url + '" target="_blank">' + url + "</a>";
      }
    });
  }

  htmlToText(text) {
    var div = document.createElement("DIV");
    div.innerHTML = text;
    let cleanText = div.innerText;

    return cleanText;
  }

  replaceURLWithHTMLLinks(text) {
    var exp =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(exp, "<a href='$1'>$1</a>");
  }

  getPuralString(item: number, message: string) {
    if (item == 1) {
      return item + " " + message;
    } else {
      return item + " " + message + "s";
    }
  }

  findById(arrayOfData, id) {
    var data = arrayOfData.find((item) => item.id === id);
    return data;
  }

  getUserTAGs(mainString, userList) {
    var i = 0;
    var list_of_tags_ids = [];
    try {
      for (i = 0; i < userList.length; i++) {
        if (this.isExactMatch(mainString, userList[i].username.toUpperCase())) {
          list_of_tags_ids.push(userList[i].id);
        }
      }
    } catch (error) {}
    return list_of_tags_ids;
  }

  findTimeStamp(innerDate) {
     let lang=this.appState.get("currentPath")
     this.todaysDate.locale(lang.language);

    //moment.locale(lang.language)
    if (!innerDate) {
      return "";
    } else {
      this.givenDate = moment.utc(innerDate);
      this.givenDate = this.givenDate.locale(lang.language)
      var isThisWeek = this.givenDate.isSame(this.todaysDate, "week");
      var isYesterday = this.todaysDate.diff(this.givenDate, "days");

      if (
        this.givenDate.format("MMMM Do YYYY") ===
        this.todaysDate.format("MMMM Do YYYY")
      ) {
        return this.i18n.t("today");
      } else if (isYesterday === 1) {
        return this.i18n.t("yesterday");
      } else if (isThisWeek) {
        return this.i18n.t("on " + this.givenDate.format("dddd"));
      } else {
        return this.i18n.t("on " + this.givenDate.format("MMMM Do"));
      }
    }
  }

  validatePassword(inputPassword) {
    return this.passwordRegex.test(inputPassword);
  }

  checkMobile() {
    const isAndroid = /android/i.test(navigator.userAgent.toLowerCase());
    const isMobileAndroid = isAndroid && /mobile/i.test(navigator.userAgent.toLowerCase());
    const isFireFox = /Firefox/i.test(navigator.userAgent);
    console.log("isMobileAndroid value", isMobileAndroid, isFireFox, this.platform.is('tablet'));
    let isTabletAndroid;
    if (isFireFox) {
       isTabletAndroid = isAndroid && this.platform.is('tablet');
    }
    else {
      isTabletAndroid = isAndroid && !isMobileAndroid && this.platform.is('tablet') ;
    }
    if((
      /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) && !isTabletAndroid) || (/iPad/.test( navigator.userAgent) && window.innerWidth <= 768)
    ) {
      // true for mobile device
      this.isMobile = true;
      this.appState.set("isMobile", this.isMobile);
      console.log("isMobile", this.appState.get("isMobile"));
    } else {
      // false for not mobile device
      this.isMobile = false;
      this.appState.set("isMobile", this.isMobile);
      console.log("isMobile", this.appState.get("isMobile"));
    }
  }

  validateEmail(email) {
    return this.emailRegex.test(String(email).toLowerCase());
  }

  async getDeviceTokenLoop(successCallback = null, failureCallback = null) {
    await this.platform.ready().then(() => {
      localStorage.setItem('notificationsEnabled', 'true');
      localStorage.setItem('emailReminderEnabled', 'true');
      localStorage.setItem('socialReminderEnabled', 'true');
      localStorage.setItem('socialEmailReminder', 'true');
      
      this.firebaseX.grantPermission().then((permission) => {
        console.log("ios permission : ", permission);
      });

      this.firebaseX
        .getToken()
        .then((token) => {
          localStorage.setItem("deviceToken", token);
          console.log(`The token is ${token}`);
        })
        .catch((error) => console.error("Error getting token", error));

      this.firebaseX
        .onMessageReceived()
        .subscribe((data) => {
            let postData = JSON.parse(data?.keysandvalues)
            console.log(`User opened a notification ${JSON.stringify(data)}`)
            this.removeNotificationBadge()
          }
        );

      this.firebaseX.onTokenRefresh().subscribe((token: string) => {
        localStorage.setItem("deviceToken", token);
        console.log("New device token : ", token);
      });


    }).catch(error => {
      console.log("platform  error : ", error);
    });
    console.log("after platform  : ");
  }

  removeLocalRoutinesData() {
    var levelId = 1;
    var currentDay;

    currentDay = 1;
    var firstDay = localStorage.getItem(
      `Toggle:Day:${currentDay}:LevelNumber:${levelId}:true`
    );

    currentDay = 2;
    var secondDay = localStorage.getItem(
      `Toggle:Day:${currentDay}:LevelNumber:${levelId}:true`
    );

    currentDay = 3;
    var thirdDay = localStorage.getItem(
      `Toggle:Day:${currentDay}:LevelNumber:${levelId}:true`
    );

    currentDay = 4;
    var forthDay = localStorage.getItem(
      `Toggle:Day:${currentDay}:LevelNumber:${levelId}:true`
    );

    currentDay = 5;
    var fifthDay = localStorage.getItem(
      `Toggle:Day:${currentDay}:LevelNumber:${levelId}:true`
    );

    if (
      firstDay === "true" ||
      secondDay === "true" ||
      thirdDay === "true" ||
      forthDay === "true" ||
      fifthDay === "true"
    ) {
      currentDay = 1;
      localStorage.removeItem(
        `Toggle:Day:${currentDay}:LevelNumber:${levelId}:true`
      );
      console.log("local routine data removed");
    }
  }

  async presentAlert(time, msg?) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-alert',
      header: this.i18n.t('Success!'),
      subHeader: this.i18n.t("You have committed to learning every work day at ")+time,
      message: msg,
      buttons: [this.i18n.t("OK")]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
  }

  async getLibraryPosts() {
    this.journalService.getList().subscribe((journalPosts) => {
      this.appState.set('savedPosts', journalPosts)
      this.appState.set('postSaved', true)
      if(this.appState.get('tabLabel')==null ||  this.appState.get('FirstTimeTabCheck')) {
        this.appState.set('FirstTimeTabCheck', true);
        let count =0
        journalPosts.forEach((doc)=>{
          if((doc?.documents && doc?.documents[0]?.isRecommended ==null) || doc?.documents==null) {
            count++;
          }
        })
        this.appState.set("savedPostsCount", count);
        if(count>0  ) {
          this.appState.set('tabLabel', 'saved')
          this.spinnerService.off();
        }
        else {
          this.appState.set('tabLabel', 'recommended')
          this.spinnerService.off();
        }
      }
      else {
        this.spinnerService.off();
      }
      
      return journalPosts
    },
      (error) => {
        this.spinnerService.off();
        console.log('error :', error);
      })
  }

  public getNotificationData(): Observable<any>{
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
      const params = new HttpParams();
    return this.http.get(API_URL + APP_NOTIFICATION,{headers,params})
  }

  removeAppStateOnContentClose(){
    //Removes appState data on closing the daily content screens
    this.appState.set('screenId', undefined)
    this.appState.set("recentSavedStep", undefined)
    this.appState.set('isNewStep', undefined)
    this.appState.set('selectedLevelIds', undefined)
    this.appState.set('unCheckedLevels', undefined)
    this.appState.set('levelUpdated', undefined)
    this.appState.set('IsOptionSelected',undefined);    
  }

  removeAppStateOnContentPrev(){
    //Removes appState data on clicking back button of daily content screens
    this.appState.set('differentTimes', undefined)
    this.appState.set('differentDays', undefined)
    this.appState.set('routineTime', undefined)
    this.appState.set('selectedDate', undefined)
  }

  getSelectedLevels(){
    //return selected habit levels
    let selectedLevelIds = this.appState.get('selectedLevelIds')
    let levelsArray = []
    if (selectedLevelIds) {
      selectedLevelIds.forEach(element => {
        levelsArray.push(element.id)
      });
    }
    levelsArray.sort(function (a, b) {
      return a - b;
    });
    return levelsArray
  }

  changePathToLibrary(prevPathData: any) {
    const request = {
      'user_id': prevPathData.userId,
      'active_path_id': prevPathData.pathId
    }
    this.homev5Service.subpathchange(request).subscribe(
      (response) => {
        let data = response["data"];
        try {
          let isOnboardContentPresent = data.onboardingSurvey || data.isIntroVideo;
          if (data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted?.length > 0 && isOnboardContentPresent) {
            let value = data['isonboardingcompleted'][0];
            let isonboardingcompleted = value["isonboardingcompleted"];
            if ((isonboardingcompleted === false || isonboardingcompleted === null) && isOnboardContentPresent) {
              this.router.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
            } else if (isonboardingcompleted === true) {
              this.refresh()
              // this.router.navigate(['/main/library']);
                this.router.navigate(['/main']);
                // setTimeout(()=>{
                //   this.homeUIService.openNextComponent(MyLibraryComponent);
                // },700);
              }
            
          } else if(data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted.length===0 && isOnboardContentPresent){
              this.router.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
          } else {
            this.homeUIService.checkSubpathStatus();
            this.router.navigate(['/main']);
          }
        } catch (error) {
          this.router.navigate(['/main']);
        }
        this.refresh()
      }, (error) => {
        console.log("Response of path changed ", error)
      }
    )
  }

  refresh() {
    return new Promise(resolve => {
      this.appState.refreshMainComponent.emit()
      setTimeout(resolve, 0)
    })
  }

  removeNotificationBadge(){
    this.badge.clear();
  }

  getYouTubeVideoIdFromUrl = (url: string) => {
    // Our regex pattern to look for a youTube ID
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    //Match the url with the regex
    const match = url.match(regExp);
    //Return the result
    return match && match[2].length === 11 ? match[2] : undefined;
  };

  confirmYoutubeUrl(url){
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const regExp1=/^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

    const match = url.match(regExp) && url.match(regExp1);
    return match;
   }

  returnStarsAsString(formRating1){
    let starRating: string
    if (formRating1 === 1) {
      starRating = 'one'
    } else if (formRating1 === 2) {
      starRating = 'two'
    } else if (formRating1 === 3) {
      starRating = 'three'
    } else if (formRating1 === 4) {
      starRating = 'four'
    } else if (formRating1 === 5) {
      starRating = 'five'
    }
    return starRating
  }

  startTimer() {
    var pathRegistration = this.appState.get('pathRegistration')
    var datesArray = pathRegistration.loggedInDates
    var date = moment().format("YYYY-MM-DD");
    if (datesArray && datesArray.length > 0) {
      var isPresent = datesArray.some(function (ele) { return ele === date });
      if (!isPresent) {
        this.saveCurrentDate(date)
      }else{
        //TODO: set interval to 1000
        this.timerSubscription = interval(1000).subscribe(() => {
          this.timerValue++;
          if (this.timerValue === 60) {
            this.stopTimer();
            let loggedInDates = this.appState.get('loggedInDates')
            if (loggedInDates) {
              this.generateWeekDays(loggedInDates)
            } else {
              this.generateWeekDays(datesArray)
            }
          }
        });
      }
    } else {
      console.log('1st date inside array:', date);
      //TODO: set interval to 1000 i.e. 1 minute
      this.timerSubscription = interval(1000).subscribe(() => {
        this.timerValue++;
        if (this.timerValue === 60) {
          this.saveCurrentDate(date)
        }
      });
      
    }
  }

  stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();  
    }
  }

  saveCurrentDate(date){
    let pathRegData = this.appState.get('pathRegistration')
    const requestData = {
      user_id: pathRegData.userId,
      path_id: pathRegData.pathId,
      logged_in_dates: date
    };
    this.homeUIService.updateDates(requestData).subscribe(
      (response) => {
        this.timerValue = 0;
        this.stopTimer();
        this.appState.set('loggedInDates', response.data[0])
        this.generateWeekDays(response.data[0])
      },
      (error) => {
        console.log("Server error", error);
      });
  }

  generateWeekDays(loggedInDates) {
    let weekDays = []
     const lang= this.appState.get('currentPath');
    const startOfWeek = moment().startOf('week');
    for (let i = 0; i < 7; i++) {
      weekDays.push({
        initial: startOfWeek.locale(lang.language).format('ddd').charAt(0),
        day: startOfWeek.locale(lang.language).format('ddd').substring(0,3),
        date: startOfWeek.locale(lang.language).format('YYYY-MM-DD') 
      });
      startOfWeek.add(1, 'day');
    }
    const currentDate = moment().locale(lang.language).format('YYYY-MM-DD'); // Get the current date
    weekDays = weekDays.map(day => {
      const dateToCheck = `${day.date}`;
      if (loggedInDates.includes(dateToCheck)) {
        return { ...day, isFilled: true };
      } else {
        return day;
      }
    }).map(day => {
      const dayDate = moment(day.date, 'YYYY-MM-DD');
      if (moment(`${day.date}`).format('YYYY-MM-DD') === currentDate) {
        return { ...day, isCurrentDay: true };
      } else if (dayDate.isBefore(currentDate, 'day')) {
        return { ...day, isPreviousDay: true };
      } else {
        return day;
      }
    });
    this.appState.set('weekDays',weekDays)
    return weekDays;
  }

  calculateLearningMinutes(inout:any[],step) {
    const scheduleArray = inout
    let sumOfEtime = 0
      const relevantEntries = scheduleArray.filter(entry => entry.endDay <= step);
      sumOfEtime = relevantEntries.reduce((total, entry) => {
        if (parseInt(entry.etime)) {
          return total + parseInt(entry.etime);
        }
        return total;
      }, 0);
      return sumOfEtime
  }
  isTabletDevice(): boolean { 
    let isTablet = this.appState.get('isTabletDevice')
    return isTablet && (this.screenOrientation.type == 'landscape-primary' || this.screenOrientation.type == 'landscape' || this.screenOrientation.type == 'landscape-secondary' )
  }
  
  hideTabletDeviceouchpoints(): boolean { 
    return this.platform.is('mobileweb') && (this.platform.is('tablet') || this.platform.is('ipad'))  
  }

  getAppInfo(userId){
    this.platform.ready().then(() => {
      if(this.platform.is('android') || this.platform.is('ios') || this.platform.is('mobileweb')){    
        this.appVersion.getAppName().then((response) => {
          console.log('response :', response);
          let appName = response.toString()
          this.setUserAppName(userId,appName)
        });
      }else{
        let appName = this.titleService.getTitle();
        this.setUserAppName(userId,appName)
      }

    })
  }

  setUserAppName(userId,userAppName){
    let appName: number
    if(userAppName.includes('comet')){
      appName = 0
    }else{
      appName = 1
    }
    const requestData = {
      'user_id': userId,
      'app_name': appName
    }
    console.log('requestData :', requestData);
    this.userService.setAppName(requestData).subscribe(
      (response) => {
        console.log('response of appName', response);
      },
      (error) => {
        console.log('error of appName', error);
      }
    );
  }
}
