import { ChangeDetectorRef, Component, DoCheck, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from 'src/app/app.service';
import { Checklist } from 'src/app/checklist/checklist.model';
import { Client } from 'src/app/client/client.model';
import { DailyStepsComponent } from 'src/app/daily-steps/daily-steps.component';
import { SnoozedHabit } from 'src/app/daily-steps/daily-steps.model';
import { DailyStepsService } from 'src/app/daily-steps/daily-steps.service';
import { Habit } from 'src/app/habit/habit.model';
import { I18n } from 'src/app/i18n.service';
import { InoutScreen } from 'src/app/inout-screen/inout-screen.model';
import { Document } from '../../document/document.model'
import { NotificationService } from 'src/app/notification/notification.service';
import { Path } from 'src/app/path/path.model';
import { Poll } from 'src/app/poll/poll.model';
import { Question } from 'src/app/question/question.model';
import { RoutineV2Service } from 'src/app/routine-v2/routine-v2.service';
import { SessionService } from 'src/app/session/session.service';
import { StatService } from 'src/app/stat/stat.service';
import { User } from 'src/app/user/user.model';
import { UserService } from 'src/app/user/user.service';
import { WalletService } from 'src/app/wallet/wallet.service';
import { HomeV5Service } from '../home-v5/home-v5.service';
import { slideInLeftAnimation, slideInRightAnimation, } from 'angular-animations';
import * as _ from 'underscore';
import * as moment from 'moment';
import { AlertNotificationUpdateComponent } from 'src/app/alert-notification-update/alert-notification-update.component';
import { AlertReviewRoutineComponent } from 'src/app/alert-review-routine/alert-review-routine.component';
import { WeekendCalendarComponent } from 'src/app/routine-v2/weekend-calendar.component';
import { AlertAnimationComponent } from 'src/app/alert-animation/alert-animation.component';
import { AlertConfirmNotificationComponent } from 'src/app/alert-confirm-notification/alert-confirm-notification.component';
import { ChangeSubpathAlertComponent } from 'src/app/change-subpath-alert/change-subpath-alert.component';
import { ToasterService } from 'src/app/toaster.service';
import { SpinnerService } from 'src/app/spinner.service';
import { AlertSupportComponent } from 'src/app/alert-support/alert-support.component';
import { SwiperOptions } from 'swiper';
import { AlertAskRoutineQuestionComponent } from 'src/app/alert-ask-routine-question/alert-ask-routine-question.component';
import { AlertGiveFeedbackCommitmentComponent } from 'src/app/alert-give-feedback-commitment/alert-give-feedback-commitment.component';
import { AlertEditLevelsComponent } from 'src/app/alert-edit-levels/alert-edit-levels.component';
import { AlertRepeatRoutineComponent } from 'src/app/alert-repeat-routine/alert-repeat-routine.component';

import * as confetti from 'canvas-confetti';
import { UtilityService } from 'src/app/common-services/utility.service';
import { browserRefresh } from 'src/app/app.component';
import mixpanel from 'mixpanel-browser';
import { HomeUiService } from 'src/app/new-design/home-ui.service';

class CompletionsCollection {
  ByDay: { [dayNum: string]: { [completionId: string]: boolean } }
  ByType: { [completionId: string]: boolean }
}

export interface CalendarParams {
  title: String,
  startDate: Date
  endDate: Date,
  eventLocation: String,
  notes: String,
  endEventTime: any,
  frequency: string
}
@Component({
  selector: "app-home-v6",
  templateUrl: "./home-v6.component.html",
  styleUrls: ["./home-v6.component.scss"],
  animations: [
    slideInLeftAnimation({ duration: 400 }),
    slideInRightAnimation({ duration: 400 }),
  ],
})
export class HomeV6Component implements OnInit, DoCheck {
  public swiperConfig: SwiperOptions = {
    slidesPerView: 1.25,
    spaceBetween: 0,
    navigation: true,
    centeredSlides: true,
  };

  @ViewChild("dailyStepsComponent") dailyStepsComponent: DailyStepsComponent;
  @ViewChild("modalSnoozed") modalSnoozed: ElementRef;

  loading = true;
  client: Client;
  currentUser: User;
  path: Path;
  poll: Poll;
  content: any; // TODO: Type it: Response from homePageService
  document: Document;
  habit: Habit;
  question: Question;
  checklists: Checklist[];
  completions: CompletionsCollection;
  scheduleDay: number;
  maxAllowedScheduleDay: number; // LUMBA-1301
  scheduleDays: number[];
  isPreview: boolean;
  allContentCompleted: boolean;
  currentDayCompleted: boolean;
  dayHasIncompleteMandatory: boolean; // LUMBA-1317
  // LUMBA-1301
  showContinueButton: boolean;
  showReplayButton: boolean;
  showBeginButton: boolean;
  showCompletedMessage: boolean;
  showUnlockButton: boolean = false;
  showPrev: boolean = false;
  scheduledStep: number;

  currentDayStarted: boolean;
  currentDayTitle: string;
  currentDaySubtext: string;
  showCloseoutWelcomeScreen: boolean;
  activeCloseoutWelcomeScreen: boolean; // LUMBA-1312
  activeDay: number;
  dayHasContent: boolean;
  badgesCount: number;
  percentage: number;
  newSubtitle: string;
  task: string;
  pollSort: any[];
  titleOfTask: string;

  messageDailySteps: number;
  messageDailyStepsTotal: number;
  messageTotalActionPoints: number;
  dailyStepMsg: any[];

  mainTitle: string;
  subTitle: string;
  step1: string;
  step2: string;
  step3: string;
  step4: string;
  step5: string;
  questionData: Question[];
  documentData: Document[];
  pollData: any[];
  surveyData: any[];
  hadbitData: Habit[];
  inoutScreenData: InoutScreen[];

  singleTask: any[];

  isQuestion: string[];
  isFullCardView: boolean[];

  isTodayTask: boolean;
  maxData: number[];
  data: any;

  maxOneNumbers: number;
  maxTwoNumbers: number;

  titleName: string;
  subTitleName: string;

  modalSnoozedHabit: SnoozedHabit;
  dailyStepsService: DailyStepsService;
  currentDay: number;
  lengthOfSnoozed: number;
  dailyTask: any = {};

  unlocked_maxAllowedScheduleDay = 0;
  unlocked_currentScheduleDay = 0;

  isPresent = 0;
  isCompleted = 0;
  indexOfUnlockedTask = 0;
  subPathtask: any;
  selectedPath: any;
  state: boolean = false;
  rightState: boolean = false;
  animation = ["slideInLeft", "slideInRight"];
  isDoneButton: boolean = false;
  dayNumber: number;
  time: any;
  isonboardingcompleted: any;
  rewardPoint: number;
  isMobile: boolean;
  subPathName: string;
  routineList: any;
  hasNativeCalendar: boolean;
  calendarParams: CalendarParams;
  recurrenceInterval: string;
  isCommitmentsViewed: boolean;
  isNotificationViewed: boolean;
  routineVersion: boolean;
  isConfirmed: boolean;
  typeValue: string;
  commitType: any;
  selectedTimings = [];
  enabledAttributes: any;
  levelId: any;
  showDialogCounter: any;
  counterId: number;
  timezone: any;
  routineTime: any;
  isRoutineCalender: boolean;
  pathGroupId: any;
  browserRefresh;
  toDos;
  isSlideLeft = "animate__slideInRight";
  isHomeRoute: boolean;
  stats: any;
  thisPathUserRank: any;
  thisPathUser: any;
  badgeCount = 0;
  allPathLabel: string;
  currentPathLabel: string;
  pathRegistration: any;
  previousDayCompleted: any;
  selectedLevels = [];
  makeHabit: boolean;
  canvas: ElementRef;
  allLevelsCompleted: boolean;
  stepsArray: any;
  inProgressStep: boolean;
  lastStep: 0;
  
  constructor(
    private appState: AppState,
    private router: Router,
    public route: ActivatedRoute,
    private userService: UserService,
    private sessionService: SessionService,
    private statService: StatService,
    public walletService: WalletService,
    public notificationService: NotificationService,
    private dialog: MatDialog,
    private toasterService: ToasterService, //add toast
    private i18n: I18n,
    private cdRef: ChangeDetectorRef,
    private homev5Service: HomeV5Service,
    private spinnerService: SpinnerService,
    private routineService: RoutineV2Service,
    private dailyStepService: DailyStepsService,
    private homeUIService: HomeUiService,
    private utilService: UtilityService
  ) {
    this.allPathLabel = this.i18n.t("ALL PATHS");
    this.currentPathLabel = this.i18n.t("CURRENT PATH");
    this.maxAllowedScheduleDay = this.appState.get("daysOfContent");
    this.data = this.appState.get("data");
    this.scheduledStep = this.appState.get("scheduleDayForCurrentStep");
    this.isMobile = this.appState.get("isMobile");
    this.levelId = this.appState.get("LevelNumber");
    this.counterId = this.appState.get("counterId");
    this.appState.set("page", 1);
    this.path = this.appState.get("currentPath");
    this.pathRegistration = this.appState.get("pathRegistration");
    this.lastStep = this.appState.get("lastStep");
    if (this.levelId) {
      // console.log("appState levelId", this.levelId);
    } else {
      this.levelId = 1;
    }
    if (this.counterId) {
      console.log("counterIdConstructor", this.counterId);
    } else {
      this.appState.set("counterId", 0);
    }
    if (this.router.url == "/main") {
      this.isHomeRoute = true;
    }
    this.init();
    this.browserRefresh = browserRefresh;
    if(this.browserRefresh){
       this.toDos =JSON.parse(localStorage.getItem('toDosWhenRefreshed'));
       this.appState.set('toDos', this.toDos);
      let currentScreenIndex =JSON.parse(localStorage.getItem('currentScreenIndexAfterScreenView'))
       let currentDay =JSON.parse(localStorage.getItem('currentDayAfterScreenView'))
     let screensIds= JSON.parse(localStorage.getItem('screensIdsAfterScreenView'))
    let allowScreenCount =true;
      let screenStatus = JSON.parse(localStorage.getItem('screenStatus'));
      let dailyStepsArray= this.appState.get("DailyStepsData");
    this.appState.set('screenStatus', screenStatus);
 let scheduledDay = this.appState.get("scheduleDayForCurrentStep")
      if (currentScreenIndex &&this.appState.get('allowScreenCount')&& currentDay==scheduledDay && dailyStepsArray[scheduledDay-1]?.stepStatus !='Review') {
        this.utilService.setScreenData(currentScreenIndex, currentDay, screensIds)
        this.utilService.setScreenCount()
      }

      this.appState.set('allowScreenCount',true);

      if (this.appState.get('routine_schedule_day')) {
        this.appState.set('routine_schedule_day', null)
      }
    }

    this.sessionService.afterRefreshEvent.subscribe(() => {
      this.maxAllowedScheduleDay = this.appState.get("daysOfContent"); // LUMBA-1379
      this.scheduleDay = this.appState.get("currentScheduleDay"); // LUMBA-1379
      this.switchScheduleDay(this.scheduleDay);
    });
    this.isCommitmentsViewed = this.appState.get("isCommitmentsViewed");
    this.isNotificationViewed = this.appState.get("isNotificationViewed");
    this.getStats();
    this.initBadges();
  }
  
  getStats() {
    this.statService
      .getList(this.path.id)
      .then((response) => {
        this.stats = response;
        this.getCurrentUser(this.stats);
      })
      .catch((error) => {
        this.stats = error;
      });
  }
  getCurrentUser(stats: any) {
    var current_path_user = [];
    current_path_user = stats.leaderboard.current_path_user;

    try {
      this.thisPathUserRank = current_path_user.findIndex(
        (element) => element.isMe
      );
      let thisUser = current_path_user.filter((element) => element.isMe);
      this.thisPathUser = thisUser[0];
    } catch (error) {
      console.log("error catch", error);
    }
  }

  ngAfterViewInit() {
    if (this.dailyStepsComponent) {
      this.messageDailySteps = this.dailyStepsComponent.messageDailySteps;
      this.messageDailyStepsTotal =
        this.dailyStepsComponent.messageDailyStepsTotal;
      this.messageTotalActionPoints =
        this.dailyStepsComponent.messageTotalActionPoints;
      this.lengthOfSnoozed = this.dailyStepsComponent.getSnoozedSteps().length;
      this.dailyStepMsg = this.dailyStepsComponent.dailySteps;
      this.appState.set("snoozedSteps", this.lengthOfSnoozed);
      localStorage.setItem("snoozedSteps", this.lengthOfSnoozed.toString());
    }

    this.actionPointAnimation();
  }

  ngDoCheck() {
    this.allLevelsCompleted = this.appState.get('allLevelsCompleted')
  }

  init() {
    this.path = this.appState.get("currentPath");
    this.client = this.appState.get("currentClient");
    this.currentUser = this.appState.get("currentUser");
    this.scheduleDay = +this.appState.get("currentScheduleDay");
    this.activeDay = this.scheduleDay;
    this.enabledAttributes = this.appState.get("routineVersion");
    this.pathGroupId = localStorage.getItem("pathGroupId");
    if (this.enabledAttributes.routineVersion) {
      this.routineVersion = this.enabledAttributes.routineVersion;
    } else {
      this.routineVersion = false;
    }
    this.appState.set("clientImage", this.client.imageUrl);
    this.currentDay = this.appState.get('currentScheduleDay')

    this.scheduleDays = new Array(this.path.daysOfContent)
      .fill(0)
      .map((item, index) => index + 1);
    this.currentDayStarted = !!localStorage.getItem(
      `Toggle:User:${this.currentUser.id}:DayProgress:${this.path.id}:${this.scheduleDay}`
    );
    this.currentDayCompleted = !!localStorage.getItem(
      `Toggle:User:${this.currentUser.id}:DayCompleted:${this.path.id}:${this.scheduleDay}`
    );
    this.allContentCompleted = this.appState.get("allContentCompleted");
    this.dayHasIncompleteMandatory = this.path.checkMandatoryForDay(
      this.scheduleDay
    ); // LUMBA-1317
    this.showCloseoutWelcomeScreen = this.isLastCurrentDayCompleted;

    this.currentDayTitle = this.path.getHomepageTitleForDay(this.scheduleDay);
    this.currentDaySubtext = this.path.getHomepageSubtextForDay(
      this.scheduleDay
    );
    this.dayHasContent = this.path.checkContentForDay(this.scheduleDay);
    // Instead of maxAllowedScheduled we added scheduledStep cause maxAllowedScheduleDay is nothing but total day of content

    // Check whether localdata is present or not if not then check how many steps are completed
    if (!this.currentDayStarted || !this.currentDayCompleted) {
      let stepsArray = this.homev5Service.removeDuplicates(
        this.pathRegistration?.screensViewed,
        "step"
      );
      stepsArray.forEach((element) => {
        if (
          element.step == this.scheduleDay &&
          element.visitedScreens < element.totalScreens
        ) {
          this.currentDayStarted = true;
        } else if (
          element.step == this.scheduleDay &&
          element.visitedScreens == element.totalScreens
        ) {
          this.currentDayStarted = true;
          this.currentDayCompleted = true;
        }
      });
    }

    this.homev5Service.getDailySteps(this.scheduleDay, this.path);
    // console.log('this.scheduledStep :', this.scheduledStep);

    if (this.scheduleDay === this.scheduledStep) {
      if (this.showCloseoutWelcomeScreen) {
        if (this.scheduleDay < this.path.daysOfContent) {
          // LUMBA-1435
          this.activeDay = this.path.daysOfContent + 1;
        } else {
          this.activeDay = this.scheduleDay + 1;
        }
        this.closeOutOn();
      }
      this.showCompletedMessage =
        !this.dayHasIncompleteMandatory && this.dayHasContent;

      this.showContinueButton = this.homev5Service.checkScreenCount(
        this.pathRegistration,
        this.scheduleDay
      )
      this.showReplayButton = this.homev5Service.checkReplayForNonMand(
        this.pathRegistration,
        this.scheduleDay
      )
      this.showBeginButton = this.homev5Service.checkPrevDayCompletedForNonMand(
        this.pathRegistration,
        this.scheduleDay,
        this.currentUser,
        this.path,
        this.currentDayStarted
      )
      
    } else {
      this.showCompletedMessage = this.dayHasContent;
      this.showContinueButton = false;
      this.showReplayButton = this.dayHasContent;
      this.showBeginButton = false;
    }


    // console.log('======== HOME init =========== :', );
    // console.log('this.showBeginButton :', this.showBeginButton);
    // console.log('this.showReplayButton :', this.showReplayButton);
    // console.log('this.showContinueButton :', this.showContinueButton);
    // console.log('this.checkAllNonMandatory :', this.homev5Service.checkAllNonMandatory());
    // console.log('this.checkPrevDayCompletedForNonMand :', this.homev5Service.checkPrevDayCompletedForNonMand(this.pathRegistration,this.scheduleDay,this.currentUser,this.path,this.currentDayStarted));
    // console.log('this.checkReplayForNonMand :', this.homev5Service.checkReplayForNonMand(this.pathRegistration,this.scheduleDay));
    // console.log('this.checkScreenCount :', this.homev5Service.checkScreenCount(this.pathRegistration,this.scheduleDay));
    // console.log('============================== :', );

    // console.log("path init():", this.path);
    if (
      this.showReplayButton &&
      (this.appState.get("isHomeScreen") ||
        this.activeDay <= this.scheduledStep)
    ) {
      this.isDayLocked(this.scheduledStep);
      this.activeDay = this.scheduledStep;
      this.scheduleDay = this.activeDay;

      this.dayHasIncompleteMandatory = this.path.checkMandatoryForDay(
        this.activeDay
      );
      this.showContinueButton = this.homev5Service.checkAllNonMandatory()
        ? this.homev5Service.checkScreenCount(
            this.pathRegistration,
            this.activeDay
          )
        : (!this.dayHasIncompleteMandatory && this.currentDayStarted)?this.homev5Service.checkScreenCount(
          this.pathRegistration,
          this.activeDay
        ):(this.dayHasIncompleteMandatory && this.currentDayStarted);
      if (this.showContinueButton) {
        this.activeDay = this.scheduledStep - 1;
      }
      this.switchNextScheduleDay();

      this.appState.set("isHomeScreen", false);
    }
    
    if (!this.showUnlockButton) {
      if((this.currentDayStarted && !this.showReplayButton) || this.showBeginButton){
        this.activeDay= this.scheduledStep-1
        this.switchNextScheduleDay();
        this.appState.set('isHomeScreen', false);
      }
    }
    this.isDayLocked(this.activeDay);
    this.todayTask();
    this.setDataToAllTask();
    this.sortArrayObject();

    this.isFullCardView = new Array(this.highestLengthAllTaskObject());
    this.setAllTaskToMinimize(this.highestLengthAllTaskObject());
    this.subPathName = this.path?.name;

    let levelUpdateData = this.appState.get("levelUpdateData");
    this.allLevelsCompleted = this.appState.get("allLevelsCompleted");
    if (levelUpdateData?.levelUpdated === true) {
      this.appState.set("LevelNumber", levelUpdateData?.level.level + 1);
      const last =
        levelUpdateData?.routine.routine[
          levelUpdateData?.routine.routine.length - 1
        ];
      var isLastRepLogged = levelUpdateData?.level.reps_commit.filter(
        (item) => item.is_completed === true
      );

      if (
        levelUpdateData &&
        levelUpdateData.level.reps_commit.length ==
          isLastRepLogged.length + 1 &&
        last.id === levelUpdateData.level.id
      ) {
        if (this.allLevelsCompleted === true) {
          console.log("do nothing :");
        } else {
          this.checkLastRep(levelUpdateData.routine);
        }
      } else {
        this.appState.set("levelUpdated", true);
        this.router.navigate(["/alert-unlock-component"], {
          state: {
            data: levelUpdateData.routine.schedule_day,
          },
        });
        this.appState.set("levelUpdateData", undefined);
      }
    }

    let levelSaveData = this.appState.get("levelSaveData");
    if (levelSaveData && this.showReplayButton) {
      localStorage.setItem(
        `Toggle:Day:${levelSaveData.currentDay}:LevelNumber:${levelSaveData.levelId}:true`,
        "true"
      );
      this.appState.set("showUpdatedScreens", undefined);
      this.appState.set("levelSaveData", undefined);
    }
  }
  initBadges() {
    this.statService.getDailyParticipation().subscribe((response) => {
      this.badgesCount = response.badgesCount;
    });
  }

  showAnimation() {
    const myConfetti = confetti.create(this.canvas, {
      resize: true, // will fit all screen sizes
    });
    myConfetti();
  }

  checkLastRep(routine: any) {
    this.showAnimation();
    this.toasterService.success("Habit Complete!");
    this.appState.set("LevelNumber", undefined);
    this.appState.set("allLevelsCompleted", true);
    setTimeout(() => {
      const dialogRef = this.dialog.open(AlertAskRoutineQuestionComponent, {
        data: {
          dataKey: { routineName: routine.routine_info.title },
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "confirm") {
          this.makeHabit = true;
          const dialogAction = this.dialog.open(
            AlertGiveFeedbackCommitmentComponent
          );
          dialogAction.afterClosed().subscribe((result) => {
            this.giveFeedback(routine);
          });
        } else {
          this.makeHabit = false;
          const dialogAction = this.dialog.open(
            AlertGiveFeedbackCommitmentComponent
          );
          dialogAction.afterClosed().subscribe((result) => {
            this.giveFeedback(routine);
          });
        }
      });
    }, 2500);
  }

  giveFeedback(routine: any) {
    var step_rating = localStorage.getItem("commitmentRating1");
    var step_comment = localStorage.getItem("commitmentFeedback");
    try {
      const requestData = {
        user_id: this.currentUser.id,
        path_id: this.path.id,
        step_score: step_rating,
        step_comment: step_comment,
        routine_info_id: routine.routine_info_id,
                'is_routine_complted':routine?.routine?.length>0?(routine?.routine[routine?.routine?.length-1].is_level_completed?true:false):(routine?.levels?.length>0?routine?.levels[routine?.levels?.length-1]?.is_level_completed?true:false:false)
      };
      this.spinnerService.on();
      if (requestData.step_comment == "" && requestData.step_score == "") {
        this.spinnerService.off();
      } else {
        this.routineService.routineFeedback(requestData).subscribe(
          (response) => {
            console.log("respose", response);
            this.getAllRoutines(this.currentUser.id, this.path.id);
            // this.getAllRoutines(this.currentUser.id)
            this.cdRef.detectChanges();
            this.spinnerService.off();
            if (this.makeHabit === false) {
              const dialogRef = this.dialog.open(AlertRepeatRoutineComponent, {
                data: {
                  dataKey: { routineName: routine.routine_info.title },
                },
              });
              dialogRef.afterClosed().subscribe((result) => {
                if (result == "confirm") {
                  this.onSubmit(routine);
                 }
              });
            }
          },
          (error) => {
            this.spinnerService.off();
            this.toasterService.error(this.i18n.t(
              "Server or connection issue. Please check your internet connection."
            ));
          }
        );
      }
    } catch (o_O) {
      console.log(o_O);
    }
  }
  findLevelId(routine: any, checkedLevel: any) {
    let someArray = []
    let level_ids =[];
    routine.routine.forEach(element => {
      if (element.id != checkedLevel.id) {
        someArray.push(element)
        level_ids.push(element.id)
      }
    });
    this.appState.set('LevelNumber', someArray[0].level)
    this.appState.set('indexOfLevel', undefined)    
    this.appState.set('currentLevel', someArray[0])
    this.appState.set('levelUpdated', undefined)
    let repsRequestData= {  
      user_id:routine.user_id,
      is_reset: true,
      level_id:JSON.stringify(level_ids),
      routine_info_id:routine.routine_info_id
    }
   
    if(repsRequestData.user_id==0){
      return true;
    }else{
      this.routineService.appendRepsCount(repsRequestData).subscribe((res)=>{
        console.log("response for reps", res);
      })
     const editrepsRequestData= {  
      user_id:routine.user_id,
      is_reset:true,
      level_id:JSON.stringify(level_ids),
      routine_info_id: routine.routine_info_id,
      path_id : routine.path_id
     }
      this.routineService.editRoutine(editrepsRequestData).subscribe(
        (response) => {
          console.log('status for edit routines', response);
          this.appState.set('levelUpdated', undefined)
          this.cdRef.detectChanges();
        },
        (error) => {
          console.log('Error for edit routines', error)
        })
    }

  }

  onSubmit(routine) {
    this.appState.set('showUpdatedScreens', true)
      this.appState.set('LevelNumber', 1) 
      this.findLevelId(routine,1)
      this.appState.set('hidePrevScreen', true);
      this.appState.set("allowScreenCount", undefined)
      this.router.navigate(['/content', routine.schedule_day], { queryParams: { startFromScreen: 'daily-content-screen-routine-levels' } })
  }

  ngOnInit(): void {
    this.appState.set("levelUpdated", undefined);
    if (
      this.routineVersion &&
      this.activeCloseoutWelcomeScreen &&
      !this.isCommitmentsViewed
    ) {
      this.appState.set("isCommitmentsViewed", true);
      this.router.navigate(["/main/commitments"]);
    } else {
      this.router.navigate(["/main"]);
    }
    if (this.routineVersion && !this.isNotificationViewed) {
      this.getAllRoutines(this.currentUser.id, this.path.id);
    }
  }

  findForCurrentDay(collection: any): any {
    return collection.find(
      (item) => item.startDay <= this.dayNumber && this.dayNumber <= item.endDay
    );
  }

  getAllRoutines(userId: number, pathId: number) {
    const requestData = {
      user_id: userId,
      path_id: pathId,
    };
    this.dailyStepService.getActionsList(requestData).subscribe(
      (response) => {

        this.appState.set('todoRoutines', this.homev5Service.removeDuplicates(response.data[0].routine, "routine_info_id"))
        this.appState.set('todoActions', response.data[0].action)

        this.routineList = this.homev5Service.removeDuplicates(
          response.data[0].routine,
          "routine_info_id"
        );
        this.cdRef.detectChanges();
        var notificationSentArrayLevel = this.routineList.map((item) => {
          return {
            ...item,
            routine: item.routine.filter(
              (routine) => routine.notification_sent === true
            ),
          };
        });
        var notificationSentArrayLevelFinal = notificationSentArrayLevel.filter(
          (item) => item.routine.length > 0
        );
        this.timezone = this.currentUser.timezone;
        if (
          Array.isArray(notificationSentArrayLevelFinal) &&
          notificationSentArrayLevelFinal.length > 0
        ) {
          this.counterId = this.appState.get("counterId");
          this.showDialog(
            notificationSentArrayLevelFinal,
            this.appState.get("counterId")
          );
        }
      },
      (error) => {
        this.toasterService.error(this.i18n.t(
          "Server or connection issue. Please check your internet connection."
        ));
        console.log("error :", error);
      }
    );
  }

  showDialog(notificationSentArrayLevelFinal: any[], routineLevel) {
    try {
      if (routineLevel >= notificationSentArrayLevelFinal.length) return; //finished processing

      var lastLevel =
        notificationSentArrayLevelFinal[
          notificationSentArrayLevelFinal.length - 1
        ].routine[0];
      var firstLevel = notificationSentArrayLevelFinal[routineLevel].routine[0];

      const isAfterDay = moment().isAfter(firstLevel.end_date);
      if (isAfterDay) {
        const dialogRef = this.dialog.open(AlertNotificationUpdateComponent, {
          data: {
            dataKey: { routine: firstLevel },
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result === "confirm") {
            const dialogRef = this.dialog.open(AlertReviewRoutineComponent, {
              data: {
                dataKey: { routine: firstLevel },
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (lastLevel.id === firstLevel.id) {
                this.appState.set("isNotificationViewed", true);
              }
              //this.appState.set('isNotificationViewed',true)
              var routineAnswer = firstLevel.routine_description;
              if (result === "confirm") {
                //yes
                if (firstLevel) {
                  if (firstLevel.isSingleEvent === null) {
                    return;
                  }
                  if (firstLevel.isSingleEvent) {
                    this.openRoutineCalendar(
                      false,
                      routineAnswer,
                      firstLevel,
                      notificationSentArrayLevelFinal,
                      routineLevel
                    ); //false
                  } else {
                    this.openRoutineCalendar(
                      true,
                      routineAnswer,
                      firstLevel,
                      notificationSentArrayLevelFinal,
                      routineLevel
                    ); //TODO ;  true
                  }
                  this.appState.set("isConfirmed", true);
                }
              } else if (result === "change") {
                //No, change cue
                this.appState.set("changeCue", true);
                this.appState.set("isRoutineEdited", true);
                this.counterId++;
                this.appState.set("counterId", this.counterId);
                this.router.navigate(
                  [
                    "/content",
                    notificationSentArrayLevelFinal[routineLevel].schedule_day,
                  ],
                  {
                    queryParams: {
                      startFromScreen: "daily-content-screen-meet-cue",
                    },
                  }
                );
              } else if (result === "turnOff") {
                //No, turn off reminders
                this.turnOffNotifications(
                  notificationSentArrayLevelFinal[routineLevel],
                  firstLevel,
                  notificationSentArrayLevelFinal,
                  routineLevel
                );
              }
            });
          }
        });
      }
    } catch (error) {
      this.toasterService.error(this.i18n.t(
        "Server or connection issue. Please check your internet connection."
      ));
      console.log("Error for routines ", error);
    }
  }

  openRoutineCalendar(
    isSingleEvent: boolean,
    subRoutineAnswer: any,
    level: any,
    notificationSentArrayLevelFinal: any,
    routineLevel: any
  ) {
    var data = {
      text: level.text,
      title: level.title,
      routineAnswer: subRoutineAnswer,
      level: level,
      isSingleEvent: isSingleEvent,
      routineTime: level.routine_time,
      selectedDays: level.day,
    };

    this.appState.set("routineCalendarData", data);
    this.appState.set("isRoutineCalender", true);

    var differentTimes = level.routine_time;
    var differentDays = level.day;

    const dialogRef = this.dialog.open(WeekendCalendarComponent, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      width: "100%",
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.appState.set("isRoutineCalender", false);

      const dialogAnim = this.dialog.open(AlertAnimationComponent);
      dialogAnim.afterClosed().subscribe((result) => {
        this.appState.set("counterId", this.counterId++);

        differentTimes.forEach((element) => {
          const date = moment().format("YYYY/MM/DD");
          const time = element;
          const timeAndDate = moment(date + " " + time);
          timeAndDate.format("YYYY/MM/DD hh:mm A");
          console.log(timeAndDate.format("YYYY/MM/DD hh:mm A"));
          let newUtcDate = moment(timeAndDate).utc().format("hh:mm A");
          this.selectedTimings.push(newUtcDate);
        });

        let day = "{" + level.day + "}";
        let routineTime = "{" + this.selectedTimings + "}";

        const data = {
          user_id: notificationSentArrayLevelFinal[routineLevel].user_id,
          path_id: notificationSentArrayLevelFinal[routineLevel].path_id,
          routine_info_id: level.routine_info_id,
          type_level: notificationSentArrayLevelFinal[routineLevel].type_level,
          routine_time: routineTime,
          day: day,
        };

        this.homev5Service.routineUpdateData(data).subscribe(
          (response) => {
            console.log("commit response", response);
          },
          (error) => {
            console.log("error", error);
            this.toasterService.error(this.i18n.t(
              "Server or connection issue. Please check your internet connection."
            ));
          }
        );

        this.selectedTimings = [];
        this.showDialog(notificationSentArrayLevelFinal, this.counterId); //Recursively open next dialog
      });
    });
  }

  turnOffNotifications(
    routine: any,
    level: any,
    notificationSentArrayLevelFinal: any,
    routineLevel: any
  ) {
    const requestData = {
      user_id: this.currentUser.id,
      path_id: this.path.id,
      notification_sent: false,
      routine_info_id: routine.routine_info_id,
    };
    this.homev5Service.disableNotification(requestData).subscribe(
      (response) => {
        const dialogRef = this.dialog.open(AlertConfirmNotificationComponent, {
          data: {
            dataKey: { level: level },
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.appState.set("counterId", this.counterId++);
          this.showDialog(notificationSentArrayLevelFinal, this.counterId); //Recursively open next dialog
        });
      },
      (error) => {
        this.toasterService.error(this.i18n.t(
          "Server or connection issue. Please check your internet connection."
        ));
      }
    );
  }

  isSnoozedDotPresent(): boolean {
    if (this.lengthOfSnoozed > 0) {
      return true;
    } else {
      return false;
    }
  }

  setDataToAllTask() {
    this.mainTitle = this.path.names.h_tc;
    this.subTitle = this.path.names.h_ts;

    this.step1 = this.path.names.h_ds_p;
    this.step2 = this.path.names.h_ds_d;
    this.step3 = this.path.names.h_ds_q;
    this.step4 = this.path.names.h_ds_h;
    this.step5 = this.path.names.h_ds_s;

    this.questionData = this.path.gridQuestions;
    this.hadbitData = this.path.habits;
    this.documentData = this.path.gridDocuments;
    this.pollData = this.path.polls;
    this.inoutScreenData = this.path.inoutScreens;
    this.surveyData = this.path.contentSurveys;
  }

  getRouteLinkPath(i: number) {
    i = i + 1;
    return "/content" + i;
  }

  getTaskDurations(index: number) {
    if (!_.isUndefined(this.path.inoutScreens[index])) {
      try {
        if (
          this.path.inoutScreens[index].stime &&
          this.path.inoutScreens[index].etime
        ) {
          this.titleOfTask =
            "Duration " +
            this.path.inoutScreens[index].stime +
            " - " +
            this.path.inoutScreens[index].etime +
            " Min";
          return this.titleOfTask;
        } else if (
          !this.path.inoutScreens[index].stime &&
          this.path.inoutScreens[index].etime
        ) {
          this.titleOfTask =
            "Duration " + this.path.inoutScreens[index].etime + " Min";
          return this.titleOfTask;
        } else {
          return "Duration 5 - 10 Min";
        }
      } catch (o_O) {
        console.log(o_O);
        return "Duration 5 - 10 Min";
      }
    } else {
      return "Duration 5 - 10 Min";
    }
  }

  getAllActionPoints(index: number) {
    var totalActionPoints = 0;

    var startDay = index + 1;

    try {
      this.questionData.forEach((s) => {
        if (s.startDay == startDay) {
          totalActionPoints = s.actionPoints + totalActionPoints;
        }
      });
    } catch (o_O) {
      _.isError(o_O);
    }

    try {
      this.hadbitData.forEach((s) => {
        if (s.startDay == startDay) {
          totalActionPoints = s.actionPoints + totalActionPoints;
        }
      });
    } catch (o_O) {
      _.isError(o_O);
    }

    try {
      this.documentData.forEach((s) => {
        if (s.startDay == startDay) {
          totalActionPoints = s.actionPoints + totalActionPoints;
        }
      });
    } catch (o_O) {
      _.isError(o_O);
    }

    try {
      this.pollData.forEach((s) => {
        if (s.startDay == startDay) {
          totalActionPoints = s.actionPoints + totalActionPoints;
        }
      });
    } catch (o_O) {}

    try {
      this.surveyData.forEach((s) => {
        if (s.startDay == startDay) {
          totalActionPoints = s.actionPoints + totalActionPoints;
        }
      });
    } catch (o_O) {}
    return totalActionPoints;
  }

  gethomepageTitleForList(index: number) {
    try {
      this.titleName = this.inoutScreenData[index].homepageTitle.text;
      return this.titleName;
    } catch (o_O) {
      _.isError(o_O);
    }
  }

  gethomepageSubtextForList(index: number) {
    try {
      this.subTitleName = this.inoutScreenData[index].homepageSubtext.text;
      return this.subTitleName;
    } catch (o_O) {
      _.isError(o_O);
    }
  }

  isHabitInComplete(): boolean {
    let isInComplete = false;
    this.path.habits.forEach((element) => {
      if (element.startDay < this.scheduleDay && !element.isCompleted) {
        isInComplete = true;
      }
    });
    return isInComplete;
  }

  hideAndShowAllTaskCard(index: number) {
    if (this.isFullCardView[index]) {
      this.isFullCardView[index] = false;
    } else {
      this.isFullCardView[index] = true;
    }
  }

  checkTaskCompletedOrNot(index: number) {
    try {
      if (this.questionData[index].isCompleted) {
        return true;
      }
    } catch (error) {}

    try {
      if (this.documentData[index].isCompleted) {
        return true;
      }
    } catch (error) {}

    try {
      if (this.hadbitData[index].isCompleted) {
        return true;
      }
    } catch (error) {}

    try {
      if (this.pollData[index].isCompleted) {
        return true;
      }
    } catch (error) {}

    try {
      if (this.surveyData[index].isCompleted) {
        return true;
      }
    } catch (error) {}
  }

  // set shedule day and maxAllowed Schedule Day as per user unlocked
  // In constructor
  setCurrentUnlockedDay() {
    this.appState.set("unlocked_maxAllowedScheduleDay", 0);
    this.appState.set("unlocked_currentScheduleDay", 0);

    for (let i = 0; i <= this.maxData.length; i++) {
      try {
        if (this.checkTaskCompletedOrNot(i)) {
          this.appState.set("unlocked_maxAllowedScheduleDay", i + 1);
          this.appState.set("unlocked_currentScheduleDay", i + 1);
        }
      } catch (o_0) {
        console.log("Error maxAllowedScheduleDay", o_0);
      }
    }

    this.unlocked_maxAllowedScheduleDay = this.appState.get(
      "unlocked_maxAllowedScheduleDay"
    );
    this.unlocked_currentScheduleDay = this.appState.get(
      "unlocked_currentScheduleDay"
    );
    //this.maxAllowedScheduleDay = this.appState.get('daysOfContent') // LUMBA-1379
    this.scheduleDay = this.appState.get("currentScheduleDay"); // LUMBA-1379

    if (
      this.unlocked_maxAllowedScheduleDay > this.maxAllowedScheduleDay &&
      this.unlocked_currentScheduleDay > this.scheduleDay
    ) {
      this.appState.set("currentScheduleDay", this.unlocked_currentScheduleDay);
      this.appState.set(
        "maxAllowedScheduleDay",
        this.unlocked_maxAllowedScheduleDay
      );
      this.maxAllowedScheduleDay = this.appState.get("daysOfContent"); // LUMBA-1379
      this.scheduleDay = this.appState.get("currentScheduleDay"); // LUMBA-1379
    }

    this.init();
    this.switchScheduleDay(this.scheduleDay);
  }

  isHabitCompleted(index: number) {
    let isHabitComplete = false;
    try {
      for (let i = 0; i < this.hadbitData.length; i++) {
        if (this.hadbitData[i].startDay == index) {
          if (this.hadbitData[index].isCompleted) {
            isHabitComplete = true;
          }
        }
      }
    } catch (o_0) {}
    return isHabitComplete;
  }

  // check conditions for the daily tasks
  isPollPresent(index: number) {
    let isPoll = false;
    for (let i = 0; i < this.pollData.length; i++) {
      // tslint:disable-next-line: triple-equals
      if (this.pollData[i].startDay == index) {
        isPoll = true;
        return this.pollData[i];
      }
    }
    return;
  }

  isSurveyPresent(index: number) {
    let isSurvey = false;
    for (let i = 0; i < this.surveyData.length; i++) {
      if (this.surveyData[i].startDay == index) {
        isSurvey = true;
        return this.surveyData[i];
      }
    }
    return;
  }

  ishabitPresent(index: number) {
    let ishabit = false;
    for (let i = 0; i < this.hadbitData.length; i++) {
      if (this.hadbitData[i].startDay == index) {
        ishabit = true;
        return this.hadbitData[i];
      }
    }
    return;
  }

  isQuestionPresent(index: number) {
    let isQuestion = false;
    for (let i = 0; i < this.questionData.length; i++) {
      if (this.questionData[i].startDay == index) {
        isQuestion = true;
        return this.questionData[i];
      }
    }
    return;
  }

  isDocumentPresent(index: number) {
    let isDoc = false;
    for (let i = 0; i < this.documentData.length; i++) {
      if (this.documentData[i].startDay == index) {
        isDoc = true;
        return this.documentData[i];
      }
    }
    return;
  }

  sortArrayObject() {
    this.pollData.sort((a, b) =>
      a.startDay < b.startDay ? -1 : a.startDay > b.startDay ? 1 : 0
    );
    this.hadbitData.sort((a, b) =>
      a.startDay < b.startDay ? -1 : a.startDay > b.startDay ? 1 : 0
    );
    this.documentData.sort((a, b) =>
      a.startDay < b.startDay ? -1 : a.startDay > b.startDay ? 1 : 0
    );
    this.questionData.sort((a, b) =>
      a.startDay < b.startDay ? -1 : a.startDay > b.startDay ? 1 : 0
    );

    this.surveyData.sort((a, b) =>
      a.startDay > b.startDay ? -1 : a.startDay < b.startDay ? 1 : 0
    );
  }

  // find the length of all task object
  highestLengthAllTaskObject() {
    this.maxOneNumbers = Math.max(
      this.documentData.length,
      this.pollData.length
    );
    this.maxTwoNumbers = Math.max(
      this.hadbitData.length,
      this.questionData.length
    );

    const finalMaxvalue = Math.max(this.maxOneNumbers, this.maxTwoNumbers);

    if (finalMaxvalue > this.surveyData.length) {
      this.maxData = new Array(finalMaxvalue);
    } else {
      this.maxData = new Array(this.surveyData.length);
    }
    return this.maxData.length;
  }

  setAllTaskToMinimize(length: number) {
    var i = 0;
    while (i < length) {
      this.isFullCardView.push(false);
      i++;
    }
  }

  // progress bar subtitle
  formatSubtitle = (): string => {
    return "STEP " + this.scheduleDay;
  };

  todayTask() {
    this.isTodayTask = true;
    this.task = "today";
  }

  allTask() {
    this.isTodayTask = false;
    this.task = "allTask";
  }

  onSwipeLeft() {
    this.switchNextScheduleDay();
  }

  onSwipeRight() {
    this.switchPrevScheduleDay();
  }

  // lengthOf snooz will check is there snooz action is there or not
  switchScheduleDay(day_number: number) {
    if (
      this.dailyStepsComponent &&
      this.dailyStepsComponent.getSnoozedSteps()
    ) {
      this.lengthOfSnoozed = this.dailyStepsComponent.getSnoozedSteps().length;
    }
    this.dayNumber = day_number;
    // this.scheduleDay = day_number;
    // this.activeDay = day_number;
    this.isDayLocked(day_number);

    this.showCloseoutWelcomeScreen = this.isLastCurrentDayCompleted;

    if (day_number <= this.maxAllowedScheduleDay && day_number > 0) {
      this.appState.set("currentScheduleDay", day_number);

      this.dailyStepsComponent.init();
      this.scheduleDay = day_number;
      this.activeDay = day_number;
      this.currentDayStarted = !!localStorage.getItem(
        `Toggle:User:${this.currentUser.id}:DayProgress:${this.path.id}:${this.scheduleDay}`
      );
      this.dayHasContent = this.path.checkContentForDay(this.scheduleDay);
      this.dayHasIncompleteMandatory = this.path.checkMandatoryForDay(
        this.scheduleDay
      ); // LUMBA-1317

      // LUMBA-1301
      // Instead of maxAllowedScheduled we added scheduledStep cause maxAllowedScheduleDay is nothing but total day of content

      // Check whether localdata is present or not if not then check how many steps are completed
      if (!this.currentDayStarted || !this.currentDayCompleted) {
        let stepsArray = this.homev5Service.removeDuplicates(
          this.pathRegistration?.screensViewed,
          "step"
        );
        stepsArray.forEach((element) => {
          if (
            element.step == this.scheduleDay &&
            element.visitedScreens < element.totalScreens
          ) {
            this.currentDayStarted = true;
          } else if (
            element.step == this.scheduleDay &&
            element.visitedScreens == element.totalScreens
          ) {
            this.currentDayStarted = true;
            this.currentDayCompleted = true;
          }
        });
      }

      this.homev5Service.getDailySteps(this.scheduleDay, this.path);
      
      if (day_number === this.scheduledStep) {
        this.currentDayCompleted = !!localStorage.getItem(
          `Toggle:User:${this.currentUser.id}:DayCompleted:${this.path.id}:${this.scheduleDay}`
        );
        this.showCompletedMessage =
          !this.dayHasIncompleteMandatory && this.dayHasContent;
        this.showContinueButton = this.homev5Service.checkScreenCount(
          this.pathRegistration,
          this.scheduleDay
        )
        this.showReplayButton = this.homev5Service.checkReplayForNonMand(
          this.pathRegistration,
          this.scheduleDay
        )
        this.showBeginButton = this.homev5Service.checkPrevDayCompletedForNonMand(
          this.pathRegistration,
          this.scheduleDay,
          this.currentUser,
          this.path,
          this.currentDayStarted
        )
      } else {
        this.currentDayCompleted = false;
        this.showCompletedMessage = this.dayHasContent;
        this.showContinueButton = false;
        this.showReplayButton = this.dayHasContent;
        this.showBeginButton = false;
      }
      this.showCloseoutWelcomeScreen = this.isLastCurrentDayCompleted;
      
      this.currentDayTitle = this.path.getHomepageTitleForDay(this.scheduleDay);
      this.currentDaySubtext = this.path.getHomepageSubtextForDay(
        this.scheduleDay
      );
    } else if (
      day_number > this.maxAllowedScheduleDay &&
      this.showCloseoutWelcomeScreen &&
      day_number <= this.path.daysOfContent
    ) {
      // can see next day, since its now unlocked // LUMBA-1435
      this.activeDay = day_number;
      this.dayHasContent = this.path.checkContentForDay(this.activeDay);
      this.showCloseoutWelcomeScreen = this.isLastCurrentDayCompleted;
      this.closeOutOff();
      this.showCompletedMessage = false;
      this.showContinueButton = false;
      this.showReplayButton = false;
      this.showBeginButton = false;
    } else if (
      day_number === this.path.daysOfContent + 1 &&
      this.showCloseoutWelcomeScreen
    ) {
      // need to render closeout screen // LUMBA-1312
      this.activeDay = day_number;
      this.closeOutOn();
      this.showCompletedMessage = false;
      this.showContinueButton = false;
      this.showReplayButton = true;
      this.showBeginButton = false;
    }

    this.dailyStepsComponent.init();

    // console.log('======== HOME swipe =========== :', );
    // console.log('this.showBeginButton :', this.showBeginButton);
    // console.log('this.showReplayButton :', this.showReplayButton);
    // console.log('this.showContinueButton :', this.showContinueButton);
    // console.log('this.currentDayCompleted :', this.currentDayCompleted);
    // console.log('this.currentDayStarted :', this.currentDayStarted);
    // console.log('this.previousDayCompleted :', this.previousDayCompleted);
    // console.log('checkPrevDayCompleted',this.homev5Service.checkPrevDayCompleted(
    //   this.pathRegistration,
    //   this.scheduleDay,
    //   this.currentUser,
    //   this.path));
    
    // console.log('this.showUnlockButton :', this.showUnlockButton);
    // console.log('this.checkAllNonMandatory :', this.homev5Service.checkAllNonMandatory());
    // console.log('this.checkPrevDayCompletedForNonMand :', this.homev5Service.checkPrevDayCompletedForNonMand(this.pathRegistration,this.scheduleDay,this.currentUser,this.path,this.currentDayStarted));
    // console.log('this.checkReplayForNonMand :', this.homev5Service.checkReplayForNonMand(this.pathRegistration,this.scheduleDay));
    // console.log('this.checkScreenCount :', this.homev5Service.checkScreenCount(this.pathRegistration,this.scheduleDay));
    // console.log('============================== :', );
    
    this.appState.set(
      "isLastCurrentDayCompleted",
      this.isLastCurrentDayCompleted
    );

    const lastDayOfContent = this.path.lastDayOfContent();
    const lastDayCompleted = !!localStorage.getItem(
      `Toggle:User:${this.currentUser.id}:DayCompleted:${this.path.id}:${lastDayOfContent}`
    );
    if (lastDayCompleted) {
      localStorage.setItem(
        `Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${this.path.id}:${lastDayOfContent}`,
        "yes"
      );
    }
  }

  closeOutOff() {
    if (this.activeCloseoutWelcomeScreen) {
      this.activeCloseoutWelcomeScreen = false;
      this.appState.set("activeCloseoutWelcomeScreen", false);
    }
  }
  closeOutOn() {
    if (!this.activeCloseoutWelcomeScreen) {
      this.activeCloseoutWelcomeScreen = true;
      this.appState.set("activeCloseoutWelcomeScreen", true);
      this.appState.set("hideCloseout",true);
    }
  }
  setSnoozedHabits() {
    this.path.habits.sort((a, b) => a.endDay - b.endDay);
    this.dailyStepsService.setSnoozedSteps(
      this.path.habits
        .filter(
          (item) =>
            item.scheduledLater &&
            item.endDay < this.currentDay &&
            item.confirmation < 1
        ) // LUMBA-1360, // LUMBA-1445
        .map((habit) => {
          return {
            id: habit.id,
            dayNum: habit.startDay, // LUMBA-1307
            title: habit.getTitle(),
            text: habit.text,
            actionPoints: habit.actionPoints,
            screenId: "daily-content-screen-habit",
          };
        })
    );
  }

  getSnoozedSteps() {
    return this.dailyStepsService.getSnoozedSteps();
  }

  removeFromSnoozed(habit_id) {
    this.dailyStepsService.removeFromSnoozed(habit_id);
  }

  switchPrevScheduleDay() {
    this.isSlideLeft = "";
    setTimeout(() => {
      if (this.activeDay > this.scheduleDays.length) {
        this.activeDay = this.scheduleDays.length;
      }
      // this.switchScheduleDay(this.activeDay -1)
      //** SKIP THE STEP WHICH HAS NO CONTENT **//

      for (let i = this.activeDay - 1; i >= 0; i--) {
        this.dayHasContent = this.path.checkContentForDay(i);
        if (this.dayHasContent) {
          localStorage.setItem(
            `Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${this.path.id}:${i}`,
            "yes"
          );
          this.switchScheduleDay(i);
          break;
        }
      }

      this.leftSwipeAnimation();
      this.isSlideLeft = "animate__slideInLeft";
    }, 100);
    for (let i = this.activeDay - 1; i > 0; i--) {
      this.dayHasContent = this.path.checkContentForDay(i);
      if (this.dayHasContent) {
        // this.previousDayCompleted = !!localStorage.getItem(
        //   `Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${
        //     this.path.id
        //   }:${i - 1}`
        // );
        this.previousDayCompleted = this.homev5Service.checkPrevDayCompleted(
          this.pathRegistration,
          i,
          this.currentUser,
          this.path
        )
        break;
      }

    }

  }

  switchNextScheduleDay() {
    this.isSlideLeft = "";
    setTimeout(() => {
      if (this.activeDay > this.scheduleDays.length) {
        this.activeDay = this.scheduleDays.length;
      }
      let currentDay = this.activeDay;
      this.switchScheduleDay(this.activeDay + 1);
      if (!this.dayHasContent) {
        for (let i = this.activeDay + 1; i <= this.maxAllowedScheduleDay; i++) {
          this.dayHasContent = this.path.checkContentForDay(i);
          if (this.dayHasContent) {
            if (
              !!this.homev5Service.checkPrevDayCompleted(
                this.pathRegistration,
                currentDay,
                this.currentUser,
                this.path
              )
            ) {
              localStorage.setItem(
                `Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${
                  this.path.id
                }:${i - 1}`,
                "yes"
              );
            }
            this.switchScheduleDay(i);

            break;
          } else {
            localStorage.setItem(
              `Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${
                this.path.id
              }:${i - 1}`,
              "yes"
            );
            this.switchScheduleDay(i);
          }
        }
      }
      this.rightSwipeAnimation();
      this.isSlideLeft = "animate__slideInRight";
      for (let j = this.activeDay - 1; j > 0; j--) {
        this.dayHasContent = this.path.checkContentForDay(j);
        if (this.dayHasContent) {
          // this.previousDayCompleted = !!localStorage.getItem(
          //   `Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${this.path.id}:${j}`
          // );
          this.previousDayCompleted = this.homev5Service.checkPrevDayCompleted(
            this.pathRegistration,
            j,
            this.currentUser,
            this.path
          )
          break;
        }
      }

    }, 100);
    this.appState.set("swipeNext", undefined);
  }

  replay(i) {
    this.appState.set("isNewStepUnlocked", undefined);
    let screensViewed = this.appState.get('screensViewed')
    let stepsArray;
    if (this.pathRegistration.screensViewed) {
      stepsArray = this.homev5Service.removeDuplicates(this.pathRegistration.screensViewed,"step");
    }else{
      stepsArray = screensViewed
    }
    stepsArray.forEach((element) => {
      if (
        element.step == this.scheduleDay &&
        element.visitedScreens === element.totalScreens
      ) {
        this.appState.set('allowScreenCount', undefined)
      }
    });    

    localStorage.removeItem(
      `Toggle:User:${this.currentUser.id}:DayProgress:${this.path.id}:${
        !(i == undefined) ? i + 1 : this.scheduleDay
      }`
    );
    this.router.navigate([
      "/content",
      !(i == undefined) ? i + 1 : this.scheduleDay,
    ]);
    return false;
  }

  checkMandTaskCompletedOrNot(index: number) {
    try {
      if (
        this.isDocumentPresent(index).isMandatory &&
        this.isDocumentPresent(index).isCompleted
      ) {
        return true;
      } else if (!this.isDocumentPresent(index).isMandatory) {
        return true;
      }
    } catch (o_O) {
      if (this.isDocumentPresent(index).isCompleted) {
        return true;
      }
    }

    try {
      if (
        this.ishabitPresent(index).isMandatory &&
        this.ishabitPresent(index).isCompleted
      ) {
        return true;
      } else if (!this.ishabitPresent(index).isMandatory) {
        return true;
      }
    } catch (o_O) {
      if (this.ishabitPresent(index).isCompleted) {
        return true;
      }
    }

    try {
      if (
        this.isPollPresent(index).isMandatory &&
        this.isPollPresent(index).isCompleted
      ) {
        return true;
      } else if (!this.isPollPresent(index).isMandatory) {
        return true;
      }
    } catch (o_O) {
      if (this.isPollPresent(index).isCompleted) {
        return true;
      }
    }
  }

  unlockReplay(index) {
    var i = index - 1;
    if (i == 0) {
      return;
    }

    let updateScheduleDay = i + 1;
    // for (let s = index - 1; s >= 0; s--) {
    //   this.dayHasContent = this.path.checkContentForDay(s);
    //   if (this.dayHasContent) {
    //     this.previousDayCompleted = this.homev5Service.checkPrevDayCompleted(
    //       this.pathRegistration,
    //       s,
    //       this.currentUser,
    //       this.path
    //     )
    //     break;
    //   }
    // }
    // this.showPrev = this.previousDayCompleted;

    let data = {
      schedule_day: updateScheduleDay,
      user_id: this.currentUser.id,
    };

    // NOTE: The result can also be nothing if the user presses the `esc` key or clicks outside the dialog

    // if (this.previousDayCompleted) {
      this.homev5Service.updateScheduleDay(data).subscribe(
        (response) => {
          if (response.success == true) {
            localStorage.setItem('isBeginClicked','true')
            this.appState.set("allowScreenCount", true);
            this.appState.set("LevelNumber", 1);
            this.appState.set("maxAllowedScheduleDay", updateScheduleDay);
            this.appState.set("currentScheduleDay", updateScheduleDay);
            this.appState.set("scheduleDayForCurrentStep", updateScheduleDay);
            this.appState.set("indexOfLevel", 0);
            this.appState.set("levelUpdated", undefined);
            // this.maxAllowedScheduleDay = this.appState.get(
            //   'maxAllowedScheduleDay'
            // ) // LUMBA-1379
            this.scheduleDay = this.appState.get("currentScheduleDay"); // LUMBA-1379
            this.scheduledStep = this.appState.get("scheduleDayForCurrentStep");
            localStorage.removeItem(
              `Toggle:User:${this.currentUser.id}:DayProgress:${this.path.id}:${
                !(i == undefined) ? i + 1 : this.scheduledStep
              }`
            );
            this.router.navigate([
              "/content",
              !(i == undefined) ? i + 1 : this.scheduledStep,
            ]);
            return false;
          } else {
            this.toasterService.warning(this.i18n.t(
              "Server or connection issue. Please check your internet connection."
            ));
          }
        },
        (error) => {
          this.toasterService.warning(this.i18n.t(
            "Server or connection issue. Please check your internet connection."
          ));
          console.log("Response of update  error", error);
        }
      );
    // } else {
    //   this.toasterService.warning(this.i18n.t("Please complete previous step"));
    // }
  }

  submit() {
    this.changeSubPath();
  }

  selectButton(value: any, selectedPath: any) {
    this.selectedPath = selectedPath;
    this.isDoneButton = value;
  }

  private changeSubPath() {
    const request = {
      user_id: this.currentUser.id,
      active_path_id: this.selectedPath.path_id,
    };

    this.homev5Service.subpathchange(request).subscribe(
      (response) => {
        this.appState.set("isAllPaths", false);
        this.appState.set("screensViewed", undefined);
        this.appState.set("stepsArray", undefined);
        this.appState.set("stepObject", undefined);
        this.refresh();
        let data = response["data"];
        try {
          let isOnboardContentPresent = data.onboardingSurvey || data.isIntroVideo;
          if (data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted?.length > 0 && isOnboardContentPresent) {
            let value = data['isonboardingcompleted'][0];
            this.isonboardingcompleted = value["isonboardingcompleted"];
            if ((this.isonboardingcompleted === false || this.isonboardingcompleted === null) && isOnboardContentPresent) {
              this.router.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
            } else if (this.isonboardingcompleted === true) {
              this.router.navigate(["/main"]);
            }
          } else if(data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted.length===0 && isOnboardContentPresent){
              this.router.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
          } else {
            this.homeUIService.checkSubpathStatus();
            this.router.navigate(["/main"]);
          }
        } catch (error) {
          this.router.navigate(["/main"]);
        }
        this.refresh();
      },
      (error) => {
        console.log("Response of path changed ", error);
      }
    );
  }

  refresh() {
    return new Promise((resolve) => {
      // here to fetch the data and rerender the contents.
      this.appState.refreshMainComponent.emit();
      setTimeout(resolve, 0);
    });
  }

  toggleDropdown(event) {
    const item = $(event.currentTarget);
    if (!item.attr("aria-expanded")) {
      event.stopPropagation();
      // item.dropdown("toggle");
    }
  }

  get isLastDayCompleted(): boolean {
    const lastDayOfContent = this.path.lastDayOfContent();
    const lastDayCompleted = !!localStorage.getItem(
      `Toggle:User:${this.currentUser.id}:DayCompleted:${this.path.id}:${lastDayOfContent}`
    );
    return lastDayOfContent <= this.maxAllowedScheduleDay && lastDayCompleted;
  }

  get isLastCurrentDayCompleted(): boolean {
    const lastDayOfContent = this.path.lastDayOfContent();
    var value = !!this.homev5Service.checkPrevDayCompleted(
      this.pathRegistration,
      lastDayOfContent,
      this.currentUser,
      this.path
    );
    return value;
  }

  switchToPrev() {
    this.switchPrevScheduleDay();
  }

  switchToNext() {
    this.switchNextScheduleDay();
  }

  isDayLocked(day_number) {
    for (let i = day_number - 1; i > 0; i--) {
      this.dayHasContent = this.path.checkContentForDay(i);
      if (this.dayHasContent) {
        // let previousDayCompleted = !!localStorage.getItem(
        //   `Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${this.path.id}:${i}`
        // );
        let previousDayCompleted = this.homev5Service.checkPrevDayCompleted(
          this.pathRegistration,
          i,
          this.currentUser,
          this.path
        )
        this.previousDayCompleted = previousDayCompleted;
        this.showPrev = previousDayCompleted;
        break;
      }
    }

    if (
      day_number > this.scheduledStep &&
      this.scheduledStep != this.maxAllowedScheduleDay
    ) {
      this.showUnlockButton = true;
    } else {
      this.showUnlockButton = false;
    }
  }

  leftSwipeAnimation() {
    if (this.dayNumber >= 1) {
      this.rightState = false;
      setTimeout(() => {
        this.rightState = true;
      }, 100);
    }
  }

  rightSwipeAnimation() {
    if (this.dayNumber <= this.scheduleDays.length) {
      this.state = false;
      setTimeout(() => {
        this.state = true;
      }, 100);
    }
  }
  changeBullet(ind, inout) {
    if (ind <= this.activeDay - 1) {
      this.activeDay = inout.startDay + 1;
      this.switchPrevScheduleDay();
    } else {
      this.activeDay = ind;
      this.switchNextScheduleDay();
    }
  }

  showRoutines() {
    this.router.navigate(["end-routine"]);
  }

  optionsC = {
    backgroundColor: "#ffffff",
    backgroundOpacity: 1,
    backgroundStrokeWidth: 0,
    backgroundPadding: -13,
    radius: 45,
    space: -13,
    toFixed: 0,
    outerStrokeWidth: 17,
    outerStrokeColor: "rgba(243,243,243,0.42)",
    outerStrokeLinecap: "square",
    innerStrokeWidth: 11,
    titleFontSize: "20",
    subtitleFontSize: "15",
    subtitleFontWeight: "500",
    unitsFontSize: "20",
    unitsFontWeight: "700",
    titleFontWeight: "700",
    showInnerStroke: false,
    startFromZero: false,
  };

  optionsA = {
    backgroundColor: "#fdfdfd",
    backgroundOpacity: 1,
    backgroundStrokeWidth: 0,
    backgroundPadding: -13,
    radius: 45,
    space: -13,
    toFixed: 0,
    outerStrokeWidth: 17,
    outerStrokeColor: "#5290AC",
    outerStrokeLinecap: "square",
    innerStrokeWidth: 11,
    titleFontSize: "20",
    subtitleFontSize: "15",
    subtitleFontWeight: "500",
    unitsFontSize: "20",
    unitsFontWeight: "700",
    titleFontWeight: "700",
    showInnerStroke: false,
    startFromZero: false,
  };

  openNav() {
    document.getElementById("mySidenav").style.width = "300px";
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  switchTile(step) {
    this.switchScheduleDay(step.startDay);
  }

  switchToLeft() {
    this.closeOutOff();
    this.switchScheduleDay(this.activeDay - 1);
  }
  get isLeftArrowAvailable() {
    let isLeftArrow = false;
    if (this.activeDay !== 1) {
      for (let i = this.activeDay - 1; i > 0; i--) {
        if (this.path.checkContentForDay(i)) {
          isLeftArrow = true;
          break;
        } else {
          isLeftArrow = false;
        }
      }
    } else {
      isLeftArrow = false;
    }
    return isLeftArrow;
  }

  get isRightArrowAvailable() {
    let isRightArrow = false;
    if (this.activeDay === this.scheduleDays.length) {
      if (this.isLastCurrentDayCompleted) {
        isRightArrow = true;
      } else {
        isRightArrow = false;
      }
    } else {
      isRightArrow = true;
    }
    return isRightArrow;
  }

  actionPointAnimation() {
    const points = document.getElementById("actionPoints");
    var isCompletedAction = this.appState.get("isCompletedAction");
    var isHomeScreen = this.appState.get("isHomeScreen");
    var isCommentPosted = this.appState.get("isCommentPosted");
    const currentOldPoints = this.appState.get("oldActionPoints");
    if (isCompletedAction || isHomeScreen || isCommentPosted) {
      this.homev5Service.animateActionPoints(
        points,
        currentOldPoints,
        this.walletService.actionPoints.value,
        3000
      );
    }
  }

  changePath() {
    const dialogRef = this.dialog.open(ChangeSubpathAlertComponent);
    dialogRef.afterClosed().subscribe((result) => {});
  }

  goToQuestion() {
    var supportDialog = this.dialog.open(AlertSupportComponent);
    supportDialog.afterClosed().subscribe();
  }

  onTabChanged(event) {
    if (event.tab.textLabel == "ALL PATHS") {
      this.appState.set("isAllPaths", true);
    } else {
      this.appState.set("isAllPaths", false);
    }
  }

  allowScreenCount(status) {
    this.appState.set("screenStatus", status);
    if (status === 1) {
      this.appState.set("allowScreenCount", true);
      mixpanel.track("In Steps-Instep Begin", {"Step": (this.lastStep == 0 ? 1 : this.lastStep), 'step_number': this.currentDay})
      localStorage.setItem('isBeginClicked','true')      
    }
    if (status === 2) {
      this.appState.set("allowScreenCount", true);
      mixpanel.track("In Steps-Instep Resume", {"Step": this.lastStep == 0 ? 1 : this.lastStep, 'step_number': this.currentDay
    })
    }
  }
}

