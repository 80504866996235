import { Component, OnInit } from '@angular/core';
import { Constants } from '../constants/constants';

@Component({
  selector: 'app-alert-notify',
  templateUrl: './alert-notify.component.html',
  styleUrls: ['./alert-notify.component.scss']
})
export class AlertNotifyComponent implements OnInit {
  dialogContent
  constructor() { 
    this.dialogContent =`Be sure to confirm notifications are enabled for the ${Constants.appName} app in your iPhone Settings`
  }

  ngOnInit() {
  }

}
