import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";
import { AppState } from '../app.service';
import { CordovaService } from '../cordova.service';
import { Path } from '../path/path.model';
import { AnalyticsServiceService } from '../services/analytics-service.service';
import { ModalController } from '@ionic/angular';
import { Constants } from '../constants/constants';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {

  isMobile: boolean
  path: Path
  translatedFaqQuestion;
  translatedFaqAnswer;
  constructor(private appState: AppState, private modalController: ModalController,
    private analyticsService: AnalyticsServiceService, public cordovaService:CordovaService) {
    this.isMobile = this.appState.get('isMobile')
    this.path = this.appState.get('currentPath')   
    this.translatedFaqQuestion = `What is the purpose of the ${Constants.appName} app`
    this.translatedFaqAnswer = `The ${Constants.appName} app is designed to provide daily micro-learning through inspirational content, daily questions and related resources. Additionally, the app includes a social component allowing Leaders and Managers to share experiences and learn together, creating a global community.`;
   }

  ngOnInit() {

    
    this.visibleAndDisableData()
    this.expandAndUnexpand()
    this.analyticsService.setScreen(AnalyticsServiceService.FAQ)

  }

  
  // show and visible the user answers
  visibleAndDisableData(){
    $('.card-container').on('click', function(event) {
      var id = $(this).data('card-id')
      $('.card-body[data-card-id="' + id + '"]').toggle()
    })
  }

 expandAndUnexpand(){
  $(".question").on("click", function(){
    $(this).find(".answer").toggle(300);
    $(this).find($(".fa")).toggleClass('fa_plus fa_minus');
  });
 }

 goPrevScreen(){
  this.modalController.dismiss();
 // window.history.back()
}

  
}
