import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppState } from '../app.service';

@Component({
  selector: 'app-alert-notification-update',
  templateUrl: './alert-notification-update.component.html',
  styleUrls: ['./alert-notification-update.component.scss'],
})
export class AlertNotificationUpdateComponent implements OnInit {
  isMobile: boolean
  completedReps: any

  constructor(public dialogRef: MatDialogRef<AlertNotificationUpdateComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private appState: AppState) { 
      this.isMobile = this.appState.get('isMobile')
    }

  ngOnInit() {
    this.completedReps = this.data.dataKey.routine.reps_commit.filter(item => item.is_completed === true)
  }

}
