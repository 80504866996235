import { Component, DoCheck, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppState } from '../app.service';
import { CordovaService } from '../cordova.service';
import { RoutineInfo } from './routine-info.model';
import { RoutineLevel } from './routine-level.model';
import { HomeUiService } from '../../app/new-design/home-ui.service';
@Component({
  selector: 'first90-routine-sublevel',
  templateUrl: './routine-sublevel.component.html',
  styleUrls: ['./routine-sublevel.component.scss']
})
export class RoutineSubLevelComponent implements OnInit, DoCheck {

  @Input() routines: RoutineInfo
  @Input() levels: RoutineLevel
  @Input() inverted = false
  levelCount: number
  selectedLevels: any
  indexOfLevel: number
  totalLevels: number=0;

  constructor(private cordovaService: CordovaService,
    public homeUIService: HomeUiService,
    public appState: AppState) {
     }

  ngOnInit() {
  }

  ngDoCheck(){
    this.selectedLevels = this.appState.get('selectedLevelIds')
    this.indexOfLevel = this.appState.get('indexOfLevel')
    this.levelCount = this.selectedLevels?.findIndex(item => item.id === this.levels.id)
    // if (!this.appState.get('levelUpdated')) {
    //   this.levelCount = 1
    //   this.totalLevels = this.routines.levels.length
    // }else {
    if (!this.appState.get('levelUpdated') && this.levelCount != this.selectedLevels[this.levelCount]?.index) {
        this.levelCount = this.levelCount+1
    }
    else {
      this.levelCount = this.indexOfLevel+1
      this.totalLevels = this.routines.levels.length
    }
   // }
    if(this.levels && this.levels.enabled==false )
    {
      let flag =false
        this.routines.levels.forEach((level,index)=>{
          if(level.enabled && !flag && level.level == this.appState.get('LevelNumber')) {
            this.levels =level
            this.appState.set('indexOfLevel', index) 
            flag =true;
          }
        })
    }
  }

}
