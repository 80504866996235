import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { IonTextarea, ModalController, Platform } from '@ionic/angular';
import { AppState } from 'src/app/app.service';
import { HamburgerMenuComponent } from '../hamburger-menu/hamburger-menu.component';
import { FooziAiLibraryPopupComponent } from '../foozi-ai-library-popup/foozi-ai-library-popup.component';
import { AnimationsService } from 'src/app/shared/animations.service';
import { FooziAiConciergeService } from '../foozi-ai-concierge.service';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { FullScreenRoutineComponent } from 'src/app/full-screen-routine/full-screen-routine.component';
import { duration } from 'moment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { ToasterService } from 'src/app/toaster.service';
import mixpanel from 'mixpanel-browser';
import { FullScreenFeedComponent } from 'src/app/full-screen-feed/full-screen-feed.component';
import { MobileChatBoardComponent } from 'src/app/pages/mobile-chat-board/mobile-chat-board.component';
import { HomeUiService } from '../home-ui.service';

@Component({
  selector: "app-foozi-ai-concierge",
  templateUrl: "./foozi-ai-concierge.component.html",
  styleUrls: ["./foozi-ai-concierge.component.scss"],
})
export class FooziAiConciergeComponent implements OnInit, OnDestroy, AfterViewInit {
  isMobile;
  isModalOpen;
  isDisableLike = false;
  isCopyContent = false;
  isMsgSaved;
  isLikeSelected = false;
  postMsg: any = "";
  chat: any = [];
  suggestions = [];
  isLoader: boolean;
  dislikeFeedback: boolean = false;
  touchStartY;
  rowCount = 1;
  post_event_listeners = [];
  @ViewChild("hamModal") hamModal: HamburgerMenuComponent;
  @ViewChild('scrollBottom') private scrollBottom: ElementRef;
  @ViewChild('foozicardContainer') foozicardContainer: ElementRef;
  @ViewChild('textarea', { static: false }) textarea: IonTextarea;
  constructor(
    public platform: Platform,
    public appState: AppState,
    private modalController: ModalController,
    private animationService: AnimationsService,
    private keyboard: Keyboard,
    private renderer: Renderer2,
    private el: ElementRef,
    private sanitizer: DomSanitizer,
    private clipboard: Clipboard,
    private toaster: ToasterService,
    private fooziAiConciergeService: FooziAiConciergeService,
    private homeUiService: HomeUiService
  ) {
    this.isMobile = this.appState.get("isMobile");
    this.isModalOpen = this.appState.get("isHamburgerOpen");
  }

  addClickEventsToPosts() {
    // Find all elements with the class 'post-link'
    const postLinks = this.el?.nativeElement?.querySelectorAll('.post-link');
    
    // Remove all existing event listeners
    this.post_event_listeners.forEach((listener) => {
      listener();
    });

    postLinks?.forEach((link) => {
      //Removed text-decoration: underline; as it is looking odd 
      link.setAttribute("style", "color: #007bff;cursor: pointer;");
      // Get the post ID from the class name
      const postId = link.querySelector("span").className;

      // Add a click event listener to each link
      let listener = this.renderer.listen(link, "click", () => {
        this.openFullScreenRoutine(parseInt(postId, 10));
      });
      this.post_event_listeners.push(listener);
    });
  }
  
  ngOnInit() {
    let session = this.fooziAiConciergeService.createChatSession();
    // Check if the session_id exists in the local storage
     this.listenToKeyboardEvents();
     this.addInputEventListeners()     
    let session_id = sessionStorage.getItem('foozi_session');
    if (!session_id) {
      session.subscribe((res) => {
        sessionStorage.setItem('foozi_session', res.session_id);
        for(let suggestion of res.common_questions) {
          this.suggestions.push(suggestion);
        }
      });
    } else {
      // Check if the session_id is expired
      this.fooziAiConciergeService.checkSessionValidity(session_id).subscribe((res) => {
        if (!res) {
          // If session_id is expired, create a new session and save it in the local storage
          session.subscribe((res) => {
            sessionStorage.setItem('foozi_session', res.session_id);
          });
          this.isLoader = true;
          setTimeout(() => {
            const messages = document.querySelectorAll(".info-text");
            this.displayMessages(messages);
            this.isLoader = false;
          }, 1000);
        }
        if (res) {
          // res contains past_messages, so we can display them
          const messages = document.querySelectorAll(".info-text");
          this.displayMessagesImmediately(messages);
          for (let message of res.past_messages) {
            let role = message.role == 'assistant' ? 'foozi-response' : 'user-input';
            let classValue = message.role == 'assistant' ? 'foozi-responz-wrap' : 'justify-content-end user-input-wraper';
            let modifiedMessage = message.message.replace(
              /\[post_id-(\d+)-([\w\s.]+)\]/g,
              (match, postId, name) => `<span class="post-link"><span class=${postId}> (See ${name}'s post)</span></span>`
            )?.replace(/\s*\[path_id-\d+-step-\d+\]\s*/g, '');
           // modifiedMessage= this.removeBracketContentFromHtml(modifiedMessage)
            this.chat.push({
              role: role,
              message: modifiedMessage,
              class: classValue,
              noop: true
            });
            setTimeout(() => {
              this.addClickEventsToPosts();
            }, 0);
          }
          for (let suggestion of res.common_questions) {
            this.suggestions.push(suggestion);
          }
          setTimeout(() => {
            this.scrollToLastMessage();
          }, 800);    
          this.chat[this.chat.length - 1].noop = false;
          this.scrollBottom.nativeElement.scrollTop = this.scrollBottom.nativeElement.scrollHeight;
        }
      }
      );
    }
  
  }
    removeBracketContentFromHtml(content: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const pTags = doc.querySelectorAll('p');

    pTags.forEach((p) => {
      p.textContent = p.textContent?.replace(/\s*\[path_id-\d+-step-\d+\]\s*/g, '').trim();
    });

    return doc.body.innerHTML;
  }
   addInputEventListeners() {
    const inputs = document.querySelector("textarea");
    const element = document.querySelector(".mob") as HTMLElement;
    const element2 = document.querySelector(".mob-ai-continer-scroll-y") as HTMLElement
    if (inputs && this.platform.is("mobileweb") && element || element2) {
      this.renderer.listen(inputs, "focus", () => {
        element.style.bottom = "25px"; // Remove bottom spacing
        element2.style.height = "calc(100vh - 270px)";
      });

      this.renderer.listen(inputs, "blur", () => {
        element.style.bottom = "45px"; // Restore bottom spacing
        element2.style.height = "calc(100vh - 275px)";
      });
      this.renderer.listen(inputs, "focusout", () => {
        element.style.bottom = "45px"; // Restore bottom spacing
        element2.style.height = "calc(100vh - 275px)";
      });
    }

  }
   removeInputEventListeners() {
    const inputs = document.querySelector("textarea");
    if (inputs) {
       this.renderer.listen(inputs, "focus", null);
       this.renderer.listen(inputs, "blur", null);
       this.renderer.listen(inputs, "focusout", null);
    }
   
  }

  listenToKeyboardEvents() {
    this.keyboard.onKeyboardWillShow().subscribe(() => {
      this.adjustForKeyboard(true);
    });

    this.keyboard.onKeyboardWillHide().subscribe(() => {
      this.adjustForKeyboard(false);
    });
  }

  adjustForKeyboard(isKeyboardVisible: boolean) {
    const element = document.querySelector('.mob') as HTMLElement;
    const element2 = document.querySelector(".mob-ai-continer-scroll-y") as HTMLElement;
    if (element || element2) {
      if (isKeyboardVisible) {
        if (this.platform.is('ios')) {
            element.style.bottom = "18px"; // Remove bottom spacing
            element2.style.height = "calc(100vh - 190px)";
        }
        else if (this.platform.is('android')) {
           element.style.bottom = "45%"; // Remove bottom spacing
           element2.style.height = "calc(100vh - 450px)";
        }
      } else {
        element.style.bottom = '45px'; // Add bottom spacing or restore the original value
        element2.style.height ='calc(100vh - 195px)';
      }
    }
  }

  ngAfterViewInit(): void {
  }

  private capitalizeSentences(text: string): string {
    return text.replace(/(?:^|\.\s)([a-z])/g, (match) => match.toUpperCase());
  }
  adjustTextareaHeight(event): void {
    const textareaElement = this.textarea.getInputElement();
    let ele = document.getElementsByClassName("foozi-dynamic-textarea");
    const lineHeight = parseFloat(getComputedStyle(this.textarea['el']).lineHeight);
    if (event && event.target.value) {
      this.postMsg = this.capitalizeSentences(event.target.value);
    }
    textareaElement.then((textarea: HTMLTextAreaElement) => {
      let maxHeight;
      if (this.isMobile) {
        maxHeight = window.innerHeight * 0.4;
      }
      else {
        maxHeight = window.innerHeight * 0.55;
      }
      const currentScrollTop = textarea.scrollTop;
      textarea.style.height = "auto";
      textarea.style.overflow = "hidden";
      textarea.style.height = textarea.scrollHeight + "px";
      if (textarea.scrollHeight > maxHeight) {
        textarea.style.overflow = "auto";
        textarea.style.height = maxHeight + "px";
      }
      this.rowCount = Math.ceil(textarea.scrollHeight / lineHeight);
      if (this.rowCount > 1 && ele) {
        if (this.isMobile) {
          ele[0].setAttribute("style", "margin-bottom:8px !important;");
        }
        else {
         ele[0].setAttribute("style", "margin-bottom:12px !important;");
        }
      }
      else {
          ele[0].setAttribute("style", "margin-bottom:-20px !important;");
      }
      textarea.offsetHeight; // Trigger reflowg
      // Check if the user is at the bottom of the textarea
      const isAtBottom =
        textarea.scrollHeight - textarea.scrollTop <=
        textarea.clientHeight + 1;
      if (isAtBottom) {
        // Scroll to the bottom
        textarea.scrollTop = textarea.scrollHeight;
      } else {
        // Restore the previous scroll position if not near the bottom
        textarea.scrollTop = currentScrollTop;
      }
    });
  }

  // scrollToLastMessage() {
  //   this.foozicardContainer.nativeElement.scrollTop =
  //     this.foozicardContainer.nativeElement.scrollHeight;
    
  // }
  scrollToLastMessage() {
    if (this.foozicardContainer) {
      const container = this.foozicardContainer?.nativeElement;
      container.scrollTo({ top: container.scrollHeight, behavior: 'smooth', duration:100 });
    }
  }
  displayMessages(messages) {
    let delay = 0;
    messages.forEach((message, index) => {
      setTimeout(() => {
        message.style.display = "block";
      }, delay);
      delay += 1000; // Adjust delay between messages (in milliseconds)
    });
  }
  displayMessagesImmediately(messages) {
    messages.forEach((message, index) => {
      message.style.display = "block";
    });
  }

  //chat = [
  //   {
  //     role: 'user-input',
  //     message: 'I heard there was a recent discussion about motivating teams. Can you summarize the main points from that conversation?',
  //     class: 'justify-content-end user-input-wraper'
  //   },
  //   {
  //     role: 'foozi-response',
  //     message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,',
  //     class: 'foozi-responz-wrap'
  //   },
  //   // more conversations here
  // ];
  resetTextareaHeight(): void {
    const textareaElement = this.textarea.getInputElement();
    textareaElement.then((textarea: HTMLTextAreaElement) => {
      textarea.style.height = 'auto'; // Reset to the default size
      textarea.style.overflow = 'hidden'; // Hide the scrollbar initially
      // textarea.setAttribute("style", "margin-bottom:-20px");      
    });
  }
  onTextModification(ev) {
    console.log("called on change", ev.target.value);
    this.adjustTextareaHeight(ev)
  }
  sendMsg(event=null,suggestion = null) {
  // Set focus to the textarea to trigger the blur
  //  this.textarea.nativeElement.blur();
    // this.textarea.setBlur();
    // this.textarea.rows = 3;
    let user = this.appState.get("currentUser");
    mixpanel.track('Foozi message', {
      message: this.postMsg
    });
    this.resetTextareaHeight();
    setTimeout(() => {
      this.scrollToLastMessage();            
    },100)
    this.textarea.autofocus = false
    this.textarea['el'].blur();
    if (this.isMobile) {
      if (this.keyboard.isVisible) {
         this.keyboard.hide();
       } 
     } 
    if (this.dislikeFeedback && suggestion == null) {

      // This message is not meant to be sent to the backend
      this.chat.push({
        role: 'user-input',
        message: this.postMsg,
        class: 'justify-content-end user-input-wraper'
      });
      let response = this.chat[this.chat.length - 3].message;
      let prompt = this.chat[this.chat.length - 4].message;
      this.fooziAiConciergeService.sendLike(response, prompt, this.postMsg, 'false').subscribe((res) => {
        console.log(res);
       setTimeout(() => {
         this.scrollToLastMessage();
       }, 200);
      });
      this.dislikeFeedback = false;
      this.chat.push({
        role: 'foozi-response',
        message: "Thank you for your feedback. I will use it to improve my service.",
        class: 'foozi-responz-wrap',
        noop: true
      });
      this.postMsg = "";
      return;
    }
    if (suggestion) {
      this.postMsg = suggestion;
    }
    this.dislikeFeedback = false;
    console.log("sendMsg", this.postMsg);
    this.chat.push({
      role: 'user-input',
      message: this.postMsg,
      class: 'justify-content-end user-input-wraper'
    });
    this.isLoader = true;
    let postMsg = this.postMsg;
    this.postMsg = "";
    this.fooziAiConciergeService.sendMessage(sessionStorage.getItem('foozi_session'), postMsg).subscribe((res) => {
      console.log(res);
      setTimeout(() => {
        this.scrollToLastMessage();  
        this.isLikeSelected = false;
      },200)
      // turn on noop for last message
      if (this.chat.length > 2) {
        this.chat[this.chat.length - 2].noop = true;
      }
      let modifiedMessage = res.past_messages[res.past_messages.length - 1].message.replace(
        /\[post_id-(\d+)-([\w\s.]+)\]/g,
        (match, postId, name) => `<span class="post-link"><span class=${postId}> (See ${name}'s post)</span></span>`
      )?.replace(/\s*\[path_id-\d+-step-\d+\]\s*/g, '');
     // modifiedMessage = this.removeBracketContentFromHtml(modifiedMessage)
      this.chat.push({
        role: 'foozi-response',
        message: modifiedMessage,
        class: 'foozi-responz-wrap',
        noop: false
      });
      setTimeout(() => {
        this.addClickEventsToPosts();
      }, 0);
      this.isLoader = false;
    });
    this.scrollBottom.nativeElement.scrollTop = this.scrollBottom.nativeElement.scrollHeight;
  }
  closePage() {
    this.modalController.dismiss();
  }
  async click() {
    this.appState.set("isHamburgerOpen", true);
    this.isModalOpen = this.appState.get("isHamburgerOpen");
    console.log("called click event", this.isModalOpen);
  }
  closeHamModal() {
    this.appState.set("isHamburgerOpen", false);
    this.isModalOpen = this.appState.get("isHamburgerOpen");
    this.hamModal.closeHamModal();
  }
  async openFooziAiPopup() {
    const modal = await this.modalController.create({
      component: FooziAiLibraryPopupComponent,
      enterAnimation: this.animationService.slideInUpEnterAnimation,
      leaveAnimation: this.animationService.slideOutDownLeaveAnimation,
      cssClass: this.isMobile
        ? "m-foozi-Ai-library-popup"
        : "web-foozi-Ai-library-popup",
    });
    await modal.present();
    modal.onDidDismiss().then((res) => {
      if (res?.data?.isMsgSaved) {
        this.isMsgSaved = true;
      }
    });
  }
  // disableLike() {
  //   this.isDisableLike = true;
  //   this.chat.push({
  //     role: 'foozi-response',
  //     message: "<ion-text class=\"dislike-respond\"> I’m sorry you don’t like that response. Please provide feedback about your experience so that my service can be improved. </ion-text>",
  //     class: 'foozi-responz-wrap'
  //   });
  //   setTimeout(() => {
  //     this.isDisableLike = false;
  //   }, 3000);
  //   console.log("isDisableLike", this.isDisableLike);
  // }
  like() {
    this.isLikeSelected = !this.isLikeSelected;
    // last two messages are the user input and the response from foozi
    let response = this.chat[this.chat.length - 1].message;
    let prompt = this.chat[this.chat.length - 2].message;
    this.fooziAiConciergeService.sendLike(response, prompt).subscribe((res) => {
      console.log(res);
    });
    console.log("like", this.isLikeSelected);
  }
  //Open Full Screen Routine
  openFullScreenRoutine(postId: number) {
    this.fooziAiConciergeService.getPostID(postId).subscribe(async (res) => {
      this.fooziAiConciergeService.getPostReplies(res["data"]["root_id"], res["data"]["mode"], res["data"]["path_id"]).subscribe(async (res2) => {
        let singleTask = res2['data'];
        let mode = singleTask["type"];
        if (this.isMobile) {
         if (mode == "Routine") {
           this.openRoutineFeed(singleTask);
         } else if (mode == "Question") {
           this.openHabitQuestionFeed(singleTask, mode?.toLowerCase());
         } else if (mode == "Habit") {
           this.openHabitQuestionFeed(singleTask, mode?.toLowerCase());
         }
        }  
        else {
           const modal = await this.modalController.create({
             component: MobileChatBoardComponent,
             cssClass: "desktop-custom-modal",
             componentProps: { data: { isFocus: false, post: singleTask } },
             enterAnimation: this.animationService.slideInLeftEnterAnimation,
             leaveAnimation: this.animationService.SlideOutRightLeaveAnimation,
           });
           this.homeUiService.setModalStack(modal);
           await modal.present();
           modal.onDidDismiss().then(() => {
             this.isModalOpen = false;
           });
        }
       
      });
    });
  }
  async openHabitQuestionFeed(singleTask, mode) {
    let selectedOption = this.appState
      .get("currentPath")
      .update(mode + "s", singleTask.id, {
        votesCounts: singleTask.votescount,
      });

    if (selectedOption) {
      const data = {
        question: singleTask,
        modes: mode,
      };
      this.appState.set("singleFeed", data);
      console.log("singleFeed :", data);
      this.appState.set("singleHabit", singleTask);
    }

    const modal = await this.modalController.create({
      component: FullScreenFeedComponent,
      // animated: false,
      componentProps: { data: singleTask, focuseTextArea: false },
      cssClass: 'custom-modal',
      enterAnimation: this.animationService.slideInLeftEnterAnimation,
      leaveAnimation: this.animationService.SlideOutRightLeaveAnimation,

    })
    await modal.present()
  }
  async openRoutineFeed(singleTask) {
    let selectedOption = this.appState
      .get("currentPath")
      .update("routineInfo", singleTask['routine_info_id'], {
        votesCounts: singleTask['votescount'],
      });

    if (selectedOption) {
      const dataFeed = {
        routine: singleTask,
        mode: "routineInfo",
      };

      this.appState.set("singleFeed", dataFeed);
    }

    let data = {
      mode: 'routineInfo',
      singleRoutine: singleTask
    }
    this.appState.set("singleRoutineFeed", data);

    const modal = await this.modalController.create({
      component: FullScreenRoutineComponent,
      animated: false,
      componentProps: { data: singleTask, focuseTextArea: true },
      cssClass: 'custom-modal',
      enterAnimation: this.animationService.slideInLeftEnterAnimation,
      leaveAnimation: this.animationService.SlideOutRightLeaveAnimation,
    })
    await modal.present();
  }
  dislike() {
    this.dislikeFeedback = true;
    this.isLikeSelected = false;
     if (this.chat.length >= 1) {
       this.chat[this.chat.length - 1].noop = true;
     }    
    this.chat.push({
      role: 'foozi-response',
      message: "<ion-text class=\"dislike-respond\"> I’m sorry you don’t like that response. Please provide feedback about your experience so that my service can be improved. </ion-text>",
      class: 'foozi-responz-wrap',
      noop: true
    });
     setTimeout(() => {
       this.scrollToLastMessage();
     }, 200);
    console.log("dislike", this.dislikeFeedback);
  }
   extractPlainTextFromHtml(html: string): string {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  }
  async copyContent(text) {
    
   if (this.platform.is('cordova')) {
      try {
        await this.clipboard.copy(this.extractPlainTextFromHtml(text)).then(() => {
          this.isCopyContent = true;
        })
      } catch (error) {
        this.toaster.error('Error copying to clipboard with Cordova');
      }
    } else {
      try {
        await navigator.clipboard.writeText(this.extractPlainTextFromHtml(text)).then(() => {
          this.isCopyContent = true;
        })
      } catch (error) {
         this.toaster.error('Error copying to clipboard with web API');
      }
   }
     setTimeout(() => {
       this.isCopyContent = false;
     }, 700);
  }
   @HostListener('touchstart', ['$event'])
   onTouchStart(event: TouchEvent) {
    if (this.platform.is('ios')) {
      this.touchStartY = event.touches[0].clientY;
    }
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    let ele = document.getElementsByClassName("foozi-dynamic-textarea");
    if (this.rowCount > 1 && ele) {
      ele[0].setAttribute("style", "margin-bottom:5px !important;");
    } else {
      ele[0].setAttribute("style", "margin-bottom:-20px !important;");
    }
    if (this.platform.is('mobileweb')) {
      this.addInputEventListeners();    
    } 
    if (this.platform.is("ios") || this.platform.is("android")) {
      const touchEndY = event.changedTouches[0].clientY;
      console.log("this.touchStartY, touchEndY", this.touchStartY, touchEndY);
      // Check if the touch started and ended at the top of the screen
      if (this.touchStartY < 65 && touchEndY < 65) {
        this.scrollToTop();
      }
    }
  }
  @HostListener("click", ["$event"])
  onLinkClick(e) {
   if (this.platform.is("mobileweb")) {
      this.addInputEventListeners();
    }
  }

  scrollToTop() {
    if (this.foozicardContainer) {
      const container = this.foozicardContainer.nativeElement;
      if (container) {
        container.scrollTo({ top: 0, behavior: 'smooth', duration:200 });
      }
    }
  }
  ngOnDestroy(): void { 
    this.removeInputEventListeners()
  }
}
