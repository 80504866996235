import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppState } from '../app.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-reopt-habit-level-modal',
  templateUrl: './reopt-habit-level-modal.component.html',
  styleUrls: ['./reopt-habit-level-modal.component.scss'],
})
export class ReoptHabitLevelModalComponent implements OnInit {

 isMobile: boolean
  routineLevel: any
  @Input() data;
  constructor(private modal: ModalController,
    private appState: AppState) { 
      this.isMobile = this.appState.get('isMobile')
    }

  ngOnInit() {
    console.log('routine in alert', this.data);
  }
  confirmAction(status) {
   this.modal.dismiss({status})
  }

}
