import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgModel, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone'
import { AppState } from '../app.service';
import { Client } from '../client/client.model';
import { CordovaService } from '../cordova.service';
import { NotificationService } from '../notification/notification.service';
import { PathGroup } from '../path-group/path-group.model';
import { PathGroupService } from '../path-group/path-group.service';
import { SessionService } from '../session/session.service';
import { ThemeService } from '../theme/theme.service';
import { SignUpForm, User } from '../user/user.model';
import { UserService } from '../user/user.service';
import { environment } from '../../environments/environment'
import { SpinnerService } from '../spinner.service';
import { I18n } from '../i18n.service';
import { IonInput, ModalController, Platform ,PopoverController} from '@ionic/angular';
import { PopoverComponent } from '../shared/popover/popover.component';
import { SignupPopoverComponent } from '../signup-popover/signup-popover.component';
import { ToasterService } from '../toaster.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertWrongCodeComponent } from '../alert-wrong-code/alert-wrong-code.component';
import { CustomValidators } from '../components/edit-profile/custom-validators';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { AlertSelectPathComponent } from '../alert-select-path/alert-select-path.component';
import { AnimationsService } from '../shared/animations.service';
import { SignInService } from '../sign-in/sign-in.service';
import { Constants } from '../constants/constants';
export interface SignUpByForm{
  [signUpByForm: string]: any
}

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1, height: 50 })),
      transition(':enter', [
        style({ opacity: 0, height: 'auto' }),
        animate('0.5s ease-out')
      ]),
      transition(':leave', [
        animate('0.5s ease-in', style({ opacity: 0, height: 'auto' }))
      ])
    ]),
    trigger('fadeInOut2', [
      state('in', style({ opacity: 1, transform: 'scale(1)', maxHeight: '1000px' })),
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.95)', maxHeight: 0 }),
        animate('0.5s ease', style({ opacity: 1, transform: 'scale(1)', maxHeight: '1000px', height: '*' }))
      ]),
      transition(':leave', [
        animate('0.5s ease', style({ opacity: 0, transform: 'scale(0.95)', maxHeight: 0, height: 0 }))
      ])
    ]),
    trigger('fadeInOutPasswordValidator', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-10px)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        animate('500ms ease-in', style({ opacity: 0, transform: 'translateY(-10px)' }))
      ])
    ])
  ]
})
export class SignUpComponent implements OnInit {
  @ViewChild('firstName') firstName: NgModel
  @ViewChild('lastName') lastName: NgModel
  @ViewChild('email') email: NgModel
  @ViewChild('group') group: NgModel
  validate = ['firstName', 'lastName', 'email', 'group']
  valid: () => boolean
  onCordova: boolean = false // LUMBA-982
  fnameInputFocusTab = false
  lnameInputFocusTab = false
  pwdInputFocusTab = false
  passwordConfirmationTab = false
  signupCodeInputFocusTab = false
  environment: any
  pathGroupName: string
  queryParamSubDomain: string
  currentPathGroup: PathGroup
  client: Client
  signUpForm: SignUpForm = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    timezone: '',
    confirm: false,
    signupCode: '',
  }

  activePathId?: number

  expired: boolean
  notStarted: boolean

  clientBackgroundImageUrl: string
  colours: any
  nameSignUp: string
  loading = false
  imageStyle: object = { 'background-image': 'url(assets/images/default-logo.jpg)' }
  clientLogoImageUrlNew  = 'assets/images/default-logo.jpg'
  backgroundStyle: object = {'background-image': 'url(assets/images/default-background.jpg)'}

  clientLoginLogoImageUrl: string
  public signUpByForm: SignUpByForm
  isMobile: boolean
  clientId: any 
  termsURL: any
  passwordType: string = 'password';
  passwordIcon: string = 'eye-off';
  passwordType1: string = 'password';
  passwordIcon1: string = 'eye-off';
  clientImage: any
  isFname = true
  isLname = false
  isPwd = false
  
  isConfirmPwd = false
  isSUCode = false
  pathGroups: any
  @ViewChild('firstName', { static: false }) fName: IonInput;
  @ViewChild('signupCodeMobile', { static: false }) signupCodeMobile: IonInput;
  @ViewChild('signupCodeWeb', { static: false }) signupCodeWeb: IonInput;
  public frmSignup: UntypedFormGroup;
  passwordValidated = false
  filteredPathsGroups = []
  cohorts: any
  isCohort: any
  modalClosed = true;
  showVerification = false
  showSignUp = true
  verifyEmail: string
  isAndroidTablet: boolean;
  constants = Constants;
  translatedSignupAgreemetText;
  constructor(
    private appState: AppState,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private pathGroupService: PathGroupService,
    private sessionService: SessionService,
    private themeService: ThemeService,
    private notificationService: NotificationService,
    private i18n: I18n,
    public cordovaService: CordovaService,
    private spinnerService: SpinnerService,
    private popover:PopoverController,
    private toasterService: ToasterService,
    private matDialog: MatDialog,
    private fb: UntypedFormBuilder,
    private modalController: ModalController,
    private animationService: AnimationsService,
    private singinService: SignInService,
    private platform: Platform, 
  ) {
    this.environment = environment
    this.pathGroupName = this.route.snapshot.paramMap.get('pathGroupName')
    this.signUpForm.timezone = moment.tz.guess()
    this.translatedSignupAgreemetText = `By continuing, you agree to ${this.constants.appName}’s`;
    this.onCordova = this.cordovaService.onCordova
    this.isMobile = this.appState.get('isMobile')
    this.termsURL = 'https://1st90-api-images.s3.amazonaws.com/1st90/1st90_Terms+and+Conditions.pdf'
    this.clientImage = this.appState.get('clientImage')
    this.frmSignup = this.createSignupForm(); 
    this.isAndroidTablet = this.platform.is('mobileweb') && this.platform.is('tablet') && this.sessionService.isTabletDevice();
    this.appState.set('signupData',undefined)
    console.log('signUpForm in constructor:', this.appState.get('signUpForm'));
    if(appState.get('signUpForm')){
      this.signUpForm = this.appState.get('signUpForm')
      this.frmSignup.controls.password.setValue(this.signUpForm.password);
    }
    
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.fName.setFocus()      
    }, 10);
  }

  ngOnInit() {   
    let email = this.route.snapshot.queryParamMap.get(
      'newUserEmail'
    )
    if(email){
      this.signUpForm.email = email
    }
    this.checksignupcode(this.signUpForm.email,this.clientId,this.onCordova)
    if (localStorage.getItem('token')) {
      this.router.navigate(['/main'])
    }
    if (!this.pathGroupName) { // LUMBA-982
      this.clientBackgroundImageUrl = this.themeService.themeData.clientBackgroundImageUrl
      this.setImageStyles()  // LUMBA-1408

      this.spinnerService.off()
      return
    }
    this.pathGroupService.getPathGroupByName(this.pathGroupName).subscribe(pathGroup => {
        this.client = pathGroup.client
        this.setStyles()
        
        // LUMBA-982
        if (pathGroup.client.backgroundImageUrl) {
          localStorage.setItem('clientBackgroundImageUrl', pathGroup.client.backgroundImageUrl)
          this.clientBackgroundImageUrl = this.client.backgroundImageUrl
        } else {
          this.clientBackgroundImageUrl = this.themeService.themeData.clientBackgroundImageUrl
        }
        if (pathGroup.client.imageUrl) {
          localStorage.setItem('clientLogoImageUrl', pathGroup.client.imageUrl)
        }
        this.currentPathGroup = pathGroup
        //
        const now = new Date
        this.nameSignUp = this.currentPathGroup.getCustomText('st_su')
        this.colours = this.currentPathGroup.colours
        this.themeService.apply(this.currentPathGroup, this.client)  // LUMBA-1440
        if (now < new Date(this.currentPathGroup.starts)) {
          this.notStarted = true
        }
        if (now > new Date(this.currentPathGroup.ends)) {
          this.expired = true
        }
        this.spinnerService.off()
      },
      () => {
        this.spinnerService.off()
        this.router.navigate([`/not-found`])

      })     

      this.getclientIdURL()

      this.setClientBackground()    

  }

  isEngagementClient(): boolean {
    return this.client && this.client.name && this.client.name.toLowerCase() === 'engagement'
  }

  onTextareaFocus() {
    let id = document.getElementsByClassName("login-box");
    let id2  = document.getElementsByClassName("web-singup-overflow");
    if (id) {
      console.log("test bottomheight");
      for (let i = 0; i < id?.length; i++) {
         id[i]?.setAttribute(
           "style",
           "margin-top:2%; margin-bottom:55px; overflow-y:scroll;height:calc(100vh - 130px);"
         );
      }
      
    }
    if (id2) {
      for (let i = 0; i < id2?.length; i++) {
         id2[i]?.setAttribute(
           "style",
           "max-height:85vh;"
         );
      }
      
    }
  }

    onTextareaBlur() {
    let id = document.getElementsByClassName("login-box");
    let id2  = document.getElementsByClassName("web-singup-overflow");
    if (id) {
      for (let i = 0; i < id?.length; i++) {
         id[i]?.setAttribute(
           "style",
           "margin-top:21%; margin-bottom:5px; overflow-scroll:none; height:100%;"
         );
      }
      
    }
    if (id2) {
      for (let i = 0; i < id2?.length; i++) {
         id2[i]?.setAttribute(
           "style",
           "max-height:63vh; overflow-y:auto;"
         );
      }
      
    }
  }
  // LUMBA-982  // LUMBA-1410
  goToPrev() {
    if (this.pathGroupName) {
      this.appState.set('routedPath', this.pathGroupName)
      this.router.navigate([`login/${this.pathGroupName}`])
    } else {
      this.router.navigate([''])
    }
  }
  
  
  setImageStyles() {
    // LUMBA-1396
    const data = this.themeService.themeData
    if (data.clientBackgroundImageUrl) {
      this.backgroundStyle = {'background-image': `url(${data.clientBackgroundImageUrl})`}
    }
    if (data.clientLogoImageUrl) {
      this.imageStyle = {'background-image': `url(${data.clientLogoImageUrl})`}
      this.clientLogoImageUrlNew = data.clientLogoImageUrl
    }
  }
  setClientBackground() {
    const data = this.themeService.themeData
    if (data.clientBackgroundImageUrl) {
      this.clientBackgroundImageUrl = data.clientBackgroundImageUrl
    }
    if (data.clientLogoImageUrl) {
      this.clientLogoImageUrlNew = data.clientLogoImageUrl
    }
  }



   private setStyles() {
    if (this.client.backgroundImageUrl) {
      this.backgroundStyle = {'background-image': `url(${this.client.backgroundImageUrl})`}
      localStorage.setItem('clientBackgroundImageUrl', this.client.backgroundImageUrl)
    }
    if (this.client.imageUrl) {
      this.clientLogoImageUrlNew = this.client.imageUrl
      this.imageStyle = {'background-image': `url(${this.client.imageUrl})`}
      localStorage.setItem('clientLogoImageUrlNew', this.client.imageUrl)
    }
  }


  // LUMBA-982
  goToLogin() {
    this.appState.set('signUpForm', undefined)
    this.router.navigate(['login'])
  }
  getPathsFromCohortForWeb() {
    this.filteredPathsGroups = [];
        this.pathGroups.forEach(group => {
          this.client =group?.client;
            const matchingPaths = group.paths.map(path => {
                return path;
            });
            if (matchingPaths.length > 0) {
                this.filteredPathsGroups.push({
                    id: group?.id,
                    groupName: group?.name,
                    paths: matchingPaths
                });
            }
        });
    
    // console.log('filtered path group', this.filteredPathsGroups);
    return this.filteredPathsGroups;
  }
  async openModal(){
    this.modalClosed = false
    const modal = await this.modalController.create({
      component: AlertSelectPathComponent,
      cssClass: 'select-path-model-custom',
      showBackdrop:false,
      componentProps: {
        data: this.filteredPathsGroups,
      },
      enterAnimation: this.animationService.slideLeftTopEnterAnimation ,
    });
    
    modal.onDidDismiss().then((result) => {
      if (result.role === 'success') { 
        const selectedPath = result.data?.path; 
        this.activePathId = selectedPath?.id
        this.currentPathGroup = result.data?.pathGroup
        this.openShowVerification()
      } else {
        this.modalClosed = true
      }
    });
  await modal.present();
  }
  openShowVerification() {
    this.signUp();   
    this.showSignUp = false;
    this.showVerification = true
  }
  resendVerificationEmail() {
    // if (!this.verifyEmail) {
    //   console.log('inside ifff')
    //   return false;
    // }
  
    this.userService.resendVerificationEmail(this.verifyEmail).then(
      (response) => {
        if (response && response.success && response.message) {
          this.toasterService.success(this.i18n.t(
            "An email has been sent to this address."
          ));
        }
        return response.data;
      },
      (error) => {
        console.log("sendForgotPassword error", error);
        this.toasterService.error(this.i18n.t("Email not found"));
      }
    );
  
    return false;
  }
  selectPath() {
    if (this.loading) {
      return
    }
    if(this.pathGroups.length<1){
      const sf = this.signUpForm
      if (sf.passwordConfirmation === "" || sf.password === "" || sf.firstName === "" || sf.lastName === "" || sf.signupCode === "") {
        this.toasterService.warning(this.i18n.t('All fields are required'))
        return 
      }
    }
    if (this.fieldsRequired()) {      
      this.toasterService.warning(this.i18n.t('All fields are required'))
      return
    }
    if (!this.isPasswordsMatch()) { // LUMBA-1442
      this.toasterService.warning(this.i18n.t('Password confirmation doesn‘t match Password'))
      return
    }
    if(this.frmSignup.controls.password.status === "INVALID"){
      this.toasterService.warning(this.i18n.t('Please enter a valid password'))
      return
    }
    const clientSubDomain = this.themeService.getSubDomain() // LUMBA-1408
    // this.spinnerService.on()
    console.log('this.signUpForm.password :', this.signUpForm.password);
    this.signUpForm.password = this.singinService.removeWhiteSpaces(this.signUpForm.password)

    if (this.isSignupCode()) {
      this.userService.pathsBySignupCode(this.signUpForm.signupCode)
      .subscribe(result => {
        this.spinnerService.off()
        const paths = result.paths
        const errors = result.errors
        if(paths.length < 1 && errors.length > 0){
          this.showAlert()
        }
        this.loading = true
        this.appState.set('SignupCodePaths', paths)
        this.client = paths[0]?.pathGroup.client
        this.activePathId = paths[0]?.id
        this.appState.set('activePathId', this.activePathId)
        if (paths[0]) {
          this.signUp()
          return
        }else if (errors && errors.length > 0) {
          const messages = errors.map(e => (e))
          this.loading = false
          return
        }
      },
        (error) => {
        this.spinnerService.off()
      })
    } else {
      this.isCohort = this.pathGroups && this.pathGroups[0]?.client.isCohort
      if(!this.isCohort) {
        this.getPathsFromCohortForWeb()
        this.openModal()
      }else {
        this.userService.pathGroupsByClientEmail(this.signUpForm.email, this.clientId, clientSubDomain, this.onCordova)
        .subscribe(result => {
          this.spinnerService.off()
          const clientPathGroups = result.clientPathGroups
          const errors = result.errors
          this.loading = true
          this.appState.set('clientPathGroups', clientPathGroups)
          if (clientPathGroups.length < 2 && !errors) {
            this.signUp()
            return
          } else if (errors && errors.length > 0) {
            const messages = errors.map(e => (e))
            this.notificationService.alert(messages.join('<br>'), 'Oops!', {'enableHtml': true})
            this.loading = false
            return
          }
          this.appState.set('signupData', JSON.stringify(this.signUpForm))
          this.router.navigate(
            ['path-select'],
            {
              queryParams: {
                origin: 'signup',
              },
            })
        },
        error => {
          this.spinnerService.off()
          this.loading = false
          const messages = error.graphQLErrors.map(e => e.message)
          this.notificationService.alert(messages.join('<br>'), 'Oops!')
        })
      }

   }

  }

  signUp() {
    const params = {
      clientId: this.client.id,
      activePathId: this.activePathId,
      fromMobile: this.onCordova// LUMBA-982
    }
    console.log('------this.activePathId------',this.activePathId)
    this.userService.signUp(new User(Object.assign(params, this.signUpForm)))
      .subscribe(result => {
        this.spinnerService.off()
        this.signUpByForm = result.data
          const user = this.signUpByForm.signUpByForm.user
          const errors = this.signUpByForm.signUpByForm.errors
          this.loading = false
          if (user && !errors) {
            this.appState.set('verifyEmail', this.signUpForm.email)
            this.goToLogin()
          } else if (errors && errors.length > 0) {
            console.log(errors)
            const messages = errors.map(e => this.i18n.t(e))
            this.notificationService.alert(messages.join('<br>'), 'Oops!', {'enableHtml': true})
          }
        },
        error => {
          this.spinnerService.off()
          this.loading = false
          const messages = error.graphQLErrors.map(e => e.message)
          this.notificationService.alert(messages.join('<br>'), 'Oops!')
        })
  }

  fieldsRequired() {
    const sf = this.signUpForm
    if (sf.passwordConfirmation === "" || sf.password === "" || sf.firstName === "" || sf.lastName === "") {
      return true
    }
  }

  isPasswordsMatch() {
    return this.signUpForm.password === this.signUpForm.passwordConfirmation
  }

  isSignupCode(){
    console.log('----isSignupCode-----',this.signUpForm.signupCode)
    return this.signUpForm.signupCode != ''
  }

  selectActivePath(event) {
    console.log(event, this.signUpForm)
  }

  open(url: string) {
    this.cordovaService.openLinkInBrowser(
      'https://docs.google.com/viewer?url=http://www.africau.edu/images/default/sample.pdf&embedded=true'
    )
  }

  getclientIdURL(){
    this.clientId = this.route.snapshot.params.pathId
  }

  openLink(url: string) {
    if (this.cordovaService.onCordova) {
      this.cordovaService.openLinkInBrowser(url)
    }else{
      window.open(url)
    }
  }

  hideShowPassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }

  hideShowPassword1() {
    this.passwordType1 = this.passwordType1 === 'text' ? 'password' : 'text';
    this.passwordIcon1 = this.passwordIcon1 === 'eye-off' ? 'eye' : 'eye-off';
  }

  fnameInputFocus(){
    this.isFname = true
    this.isPwd  = false
    this.fnameInputFocusTab = true;
    if(this.isAndroidTablet){
      this.onTextareaFocus();
      }
  }

  fnameInputBlur(){
    if(this.signUpForm.firstName.length < 1){
      this.isFname  = false
    }
    this.fnameInputFocusTab = false
    if (this.isAndroidTablet && !this.fnameInputFocusTab && !this.lnameInputFocusTab && !this.pwdInputFocusTab && !this.passwordConfirmationTab && !this.signupCodeInputFocusTab ) {
      this.onTextareaBlur();
    }
  }

  lnameInputFocus(){
    this.isLname = true
    this.isPwd  = false
    if(this.signUpForm.firstName.length < 1){
      this.isFname  = false
    }
    this.lnameInputFocusTab = true;
    if(this.isAndroidTablet){
      this.onTextareaFocus();
      }
  }

  lnameInputBlur(){
    if(this.signUpForm.lastName.length < 1) this.isLname  = false
    this.lnameInputFocusTab = false
    if (this.isAndroidTablet && !this.fnameInputFocusTab && !this.lnameInputFocusTab && !this.pwdInputFocusTab && !this.passwordConfirmationTab && !this.signupCodeInputFocusTab ) {
      this.onTextareaBlur();
    }
  }

  pwdInputFocus(){
    this.isPwd = true
    if(this.signUpForm.firstName.length < 1){
      this.isFname  = false
    } 
    this.pwdInputFocusTab= true
    if(this.isAndroidTablet){
      this.onTextareaFocus();
      }   
      
  }

  pwdInputBlur(){
    if(this.signUpForm.password.length < 1) this.isPwd  = false
    this.pwdInputFocusTab= false
    if (this.isAndroidTablet && !this.fnameInputFocusTab && !this.lnameInputFocusTab && !this.pwdInputFocusTab && !this.passwordConfirmationTab && !this.signupCodeInputFocusTab ) {
      this.onTextareaBlur();
    }
  }

  confirmPwdInputFocus(){
    this.isConfirmPwd = true
    this.isPwd  = false
    if(this.signUpForm.firstName.length < 1){
      this.isFname  = false
    }
    this.passwordConfirmationTab = true;
    if(this.isAndroidTablet){
      this.onTextareaFocus();
      }  
  }

  confirmPwdInputBlur(){
    if(this.signUpForm.passwordConfirmation.length < 1) this.isConfirmPwd  = false
    this.passwordConfirmationTab= false
    if (this.isAndroidTablet && !this.fnameInputFocusTab && !this.lnameInputFocusTab && !this.pwdInputFocusTab && !this.passwordConfirmationTab && !this.signupCodeInputFocusTab ) {
      this.onTextareaBlur();
    }
  }

  signupCodeInputFocus(){
    this.isSUCode = true
    this.isPwd  = false
    if(this.signUpForm.signupCode.length < 1){
      this.isFname  = false
    }
    this.signupCodeInputFocusTab= true
    if(this.isAndroidTablet){
      this.onTextareaFocus();
      }  
  }

  signupCodeInputBlur(){
    if(this.signUpForm.signupCode.length < 1) this.isSUCode  = false
    this.signupCodeInputFocusTab= false
    if (this.isAndroidTablet && !this.fnameInputFocusTab && !this.lnameInputFocusTab && !this.pwdInputFocusTab && !this.passwordConfirmationTab && !this.signupCodeInputFocusTab ) {
      this.onTextareaBlur();
    }
  }

  checksignupcode(email, clientId, onCordova){
    const clientSubDomain = this.themeService.getSubDomain() 
    this.userService.pathGroupsByClientEmail(email, clientId, clientSubDomain, onCordova)
    .subscribe(result => {
      this.pathGroups = result.clientPathGroups
    },
    error => {
      const messages = error.graphQLErrors.map(e => e.message)
    })
  }

  async presentPopover(ev: Event) {
    const dialogRef = this.matDialog.open(SignupPopoverComponent)
    dialogRef.afterClosed().subscribe((result) => {
    })
  }

  showAlert(){
    const dialogRef = this.matDialog.open(AlertWrongCodeComponent)
    dialogRef.afterClosed().subscribe((result) => {
    })
  }

  gotoNextField(nextElement, field=null) {
    if(field === 'password' && this.frmSignup.controls.password.status === "INVALID"){
      console.log('password not validated');
      this.toasterService.warning(this.i18n.t('Please enter a valid password'))
    }
    nextElement.setFocus();
  }

  createSignupForm(): UntypedFormGroup {
    return this.fb.group(
      {
        email: [
          null,
          Validators.compose([Validators.email, Validators.required])
        ],
        password: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            Validators.minLength(7)
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      }
    );
  }

  onKey(event) {
    let password = event.target.value
    if(password){
      if(this.frmSignup.controls.password.status === "VALID"){
        this.passwordValidated = true
      }else if(this.frmSignup.controls.password.status === "INVALID"){
        this.passwordValidated = false
      }else{
        this.passwordValidated = false
      }
    }
  }
  
}
