import { Component, OnInit } from '@angular/core';
import { AppState } from '../app.service';
import { CordovaService } from '../cordova.service';
import { Path } from '../path/path.model';
import { ModalController } from '@ionic/angular';
import { Constants } from '../constants/constants';

@Component({
  selector: 'app-web-faq',
  templateUrl: './web-faq.component.html',
  styleUrls: ['./web-faq.component.scss'],
})
export class WebFaqComponent implements OnInit {

  path: Path
  isMobileWebIos = false;
  isMobileWeb=false;
  translatedWebFaqAnswer;
  translatedWebFaqQuestion
  constructor(private appState: AppState, 
    public cordovaService:CordovaService,
    public modalController: ModalController,) {
    this.path = this.appState.get('currentPath')  
    this.translatedWebFaqQuestion = `What is the purpose of the ${Constants.appName} app`
    this.translatedWebFaqAnswer = `The ${Constants.appName} app is designed to provide daily micro-learning through inspirational content, daily questions and related resources. Additionally, the app includes a social component allowing Leaders and Managers to share experiences and learn together, creating a global community.`;
   }

  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
    this.visibleAndDisableData()
    this.expandAndUnexpand()

  }

  
  // show and visible the user answers
  visibleAndDisableData(){
    $('.card-container').on('click', function(event) {
      var id = $(this).data('card-id')
      $('.card-body[data-card-id="' + id + '"]').toggle()
    })
  }

 expandAndUnexpand(){
  $(".question").on("click", function(){
    $(this).find(".answer").toggle(300);
    $(this).find($(".fa")).toggleClass('fa_plus fa_minus');
  });
 }

 close(){
  this.modalController.dismiss();
 }
  
}
