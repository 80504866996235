import { Component, OnInit } from '@angular/core';
import { AppState } from '../../app.service';
import { Constants } from 'src/app/constants/constants';
@Component({
  selector: 'first90-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currentYear: number
  isMobile: boolean;
  constants= Constants
  constructor(private appState: AppState) {
    this.currentYear = (new Date()).getFullYear()
    this.isMobile = this.appState.get('isMobile')
  }

  ngOnInit() {}

}
