import { Component, Inject, OnInit, Input, ChangeDetectorRef, } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fadeInLeftAnimation, fadeInRightAnimation, slideInDownAnimation, slideInUpAnimation } from 'angular-animations';
import { AppState } from '../app.service';
import { ModalController } from '@ionic/angular';
import { HomeUiService } from '../new-design/home-ui.service';

@Component({
  selector: 'app-alert-unlock-level',
  templateUrl: './alert-unlock-level.component.html',
  styleUrls: ['./alert-unlock-level.component.scss'],
  animations: [
    fadeInLeftAnimation({ duration: 300 }),
    fadeInRightAnimation({ duration: 300 }),
    slideInUpAnimation({ duration: 300 }),
    slideInDownAnimation({ duration: 300 })
  ],
})
export class AlertUnlockLevelComponent implements OnInit {
  levelUpdateData: any;
  @Input() nextLevelData;
  isMobile;
  levelTitle;
 
  // constructor(public dialogRef: MatDialogRef<AlertUnlockLevelComponent>,
  //   @Optional() @Inject(MAT_DIALOG_DATA) public data: any,) { }
  constructor(private router: Router,private cdRef: ChangeDetectorRef, private homeUIService: HomeUiService,
    private appState: AppState,private modalController: ModalController) {
    this.isMobile = this.appState.get('isMobile');
    if(this.isMobile) {
      this.levelUpdateData = this.router.getCurrentNavigation().extras.state.data;
    }
  }

  ngOnInit() { 
    this.levelTitle = this.appState.get('UnlockedLevelTitle');
    if (this.levelTitle) {
       this.appState.set('UnlockedLevelTitle',undefined)
    }
    setTimeout(()=>{
      if(!this.isMobile) {
        this.levelUpdateData =this.nextLevelData
      }
    },200)
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  nextScreen() {
    this.appState.set("allowScreenCount", undefined)
    this.appState.set('levelUpdated', true)
    if (this.isMobile) {
      this.router.navigate(['/content', this.levelUpdateData], { queryParams: { startFromScreen: 'daily-content-screen-routine-sub-level' } })
    }
    else {
      this.appState.set('isLevelClosedBeforeCommit', false)
      this.modalController.dismiss({isAlertUnlockClosed:true,currentDay:this.levelUpdateData});
    }
  }
  closeUnlockModal() {
    if (!this.isMobile) {
       this.modalController.dismiss();
    }
    else {
    if (this.appState.get('isLevelClosedBeforeCommit') == true) {
      this.homeUIService.closingModalOnClickingBackgroundForUnLockedCommit();
     }
      this.router.navigate(['/main']);
    }
   
  }

}
