import { AfterViewInit, Component, DoCheck, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { I18n } from "../i18n.service";
import { SessionService } from "../session/session.service";
import { AppState } from "../app.service";
import * as AddToCalendar from "../../../vendor/add-to-calendar-buttons/add-to-calendar.js";
import * as moment from "moment";
import { Path } from "../path/path.model";
import { CordovaService } from "../cordova.service";
import { AlertController, ModalController, PickerController, Platform } from "@ionic/angular";
import { HomeUiService } from "../new-design/home-ui.service";
import { AlertNotifyComponent } from "../alert-notify/alert-notify.component";
import { PathRegistration } from "../user/path-registration.model";
import { UserService } from "../user/user.service";
import { ToasterService } from "../toaster.service";
import { FirebaseX } from "@awesome-cordova-plugins/firebase-x/ngx";
import { AnalyticsServiceService } from "../services/analytics-service.service";
import { CalendarService } from "../calendar.service";
import { Client } from "../client/client.model";
import mixpanel from 'mixpanel-browser';
import { MatDialog } from "@angular/material/dialog";
import { NotificationConfirmationPopupComponent } from "../notification-confirmation-popup/notification-confirmation-popup.component";
import { HomeV5Service } from "../pages/home-v5/home-v5.service";
import { User } from "../user/user.model";
import { SpinnerService } from "../spinner.service";
import { MobileChatBoardComponent } from "../pages/mobile-chat-board/mobile-chat-board.component";
import { MyCommitmentsComponent } from "../my-commitments/my-commitments.component";
import { Router } from "@angular/router";
import { NativeService } from "../services/native.service";
import { Constants } from "../constants/constants";
import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions/ngx";

export interface CalendarParams {
  title: String;
  startDate: Date;
  endDate: Date;
  eventLocation: String;
  notes: String;
  endEventTime: any;
  frequency: string;
}
export interface UpdateUser {
  [updateUser: string]: any;
}
declare var $: any;
declare var FCMPlugin: any
const defaultColumnOptions = [
  [
    "00:00", "00:30", "01:00", "01:30",
    "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00",
    "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30",
    "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00",
    "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30",
    "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "24:00"
  ]
]
export interface UpdatePathRegistration {
  [updatePathRegistration: string]: any;
}

@Component({
  selector: "app-onboarding-learning-notification",
  templateUrl: "./onboarding-learning-notification.component.html",
  styleUrls: ["./onboarding-learning-notification.component.scss"],
})
export class OnboardingLearningNotificationComponent
  implements OnInit, AfterViewInit, OnDestroy, DoCheck
{
  @Input() learningScreenData;
  @Input() managerEmailScreenData;
  @Input() screenId;
  @Input() accountabilityEmailData;
  @Input() profileScreenData;
  @Input() currentScreen
  isMobile;
  confirmedTime;
  hasNativeCalendar: boolean; // LUMBA-1421
  cloudCalendarExpanded: boolean; // LUMBA-1421
  cloudCalendarSet: boolean; // LUMBA-1421
  morningTime: boolean;
  lunchTime: boolean;
  atWorkTime: boolean;
  homeTime: boolean;
  bedTime: boolean;
  isVideoViewed = false;
  isTimePickerCard: boolean;
  isTimeSelectCard: boolean;
  selectedTime: any;
  isMobileWebIos = false;
  isMobileWeb=false;
  itemPublish: number = 22;
  managerEmail: string = null;
  userUpdate: UpdateUser;
  confirmationCalendartime;
  @ViewChild("calendarButtonsContainer") calendarButtonsContainer;
  isShowed: boolean = false;
  dailyRoutine = [
    { title: this.i18n.t("After waking up"), time: null },
    { title: this.i18n.t("When starting work"), time: null },
    { title: this.i18n.t("Lunchtime"), time: null },
    { title: this.i18n.t("When finishing work"), time: null },
    { title: this.i18n.t("Before bed"), time: null },
  ];
  customPickerOptions: {
    buttons: { text: string; handler: (time: any) => void }[];
  };
  currIndex: any;
  defaultTime: string = "07:00";
  calendarParams: CalendarParams;
  currentPath: Path;
  learningTime;
  pathRegistration;
  start: any;
  end: any;
  endDate: any;
  selectedTimeOption = 0;
  @Input() isDaypartSelectionScreen: boolean;
  @Input() isDaypartConfirmationScreen: boolean;
  @Input() isNotificationSettingsCalenderScreen: boolean;
  @Input() currentScreenId;
  pushNotificationsEnabled: boolean;
  emailReminderEnabled: boolean = true;
  pushReminderEnabled: boolean;
  socialReminderEnabled: boolean;
  socialEmailReminder: boolean;
  loading: boolean = false;
  public updatePathRegistration: UpdatePathRegistration;
  currentUser;
  currentClient: Client;
  addedManagerEmail;
  accountabilityEmails;
  arrayOfAccountabilyEmails = [];
  accountablityEmail = "";
  selectedDayPartType;
  photoUrl: any;
  isPhotoUploaded: boolean;
  label: any;
  dataRefresher: any;
  isManagerEmailAdded;
  @ViewChild("fileInput") fileInput: ElementRef;
  resetCalenderData = false;
  constructor(
    private i18n: I18n,
    private appState: AppState,
    private cordovaService: CordovaService,
    private platform: Platform,
    private userService: UserService,
    private toastrService: ToasterService,
    private pickerController: PickerController,
    public homeUiService: HomeUiService,
    private modalCtrl: ModalController,
    private dialog: MatDialog,
    private analyticsService: AnalyticsServiceService,
    private calendarService: CalendarService,
    private firebaseX: FirebaseX,
    private sessionService: SessionService,
    private homeService: HomeV5Service,
    private spinnerService: SpinnerService,
    private alertController: AlertController,
    private router: Router,
    private permissions: AndroidPermissions,
    private native: NativeService
  ) {
    this.customPickerOptions = {
      buttons: [
        {
          text: this.i18n.t("Cancel"),
          handler: (time) => {
            return true;
          },
        },
        {
          text: this.i18n.t("Confirm"),
          handler: (time) => {
            this.afterWakingUp(time);
          },
        },
      ],
    };
    this.pathRegistration = this.appState.get("pathRegistration");
    this.currentPath = this.appState.get("currentPath");
    this.selectedDayPartType = this.pathRegistration?.selectedDayPart;
    this.currentUser = this.appState.get("currentUser");
    this.managerEmail = this.appState.get("managerEmail");
    this.currentClient = this.appState.get("currentClient");
    let learningTime = localStorage.getItem("learningTime");
    if (learningTime) {
      this.learningTime = learningTime;
    } else {
      if (this.pathRegistration && this.pathRegistration.pathData) {
        this.learningTime = this.pathRegistration.pathData.loginReminderTime;
      }
    }

    if (this.learningTime != null) {
      this.getConfirmedTimeInNormalMode(this.learningTime);
    }

    this.isMobile = appState.get("isMobile");
    this.addedManagerEmail = this.appState.get("managerEmail");
    this.accountabilityEmails = this.appState.get("accountabilityEmails");
    if (this.accountabilityEmails) {
      this.arrayOfAccountabilyEmails = this.accountabilityEmails;
    }
    const w: any = window;
    this.hasNativeCalendar =
      this.cordovaService.onCordova && w && w.plugins && w.plugins.calendar;
    // this.prepareCalendarParams();
    this.isTimePickerCard = false;
    this.isTimeSelectCard = true;
  }

  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
    this.atWorkTime = false;
    this.morningTime = false;
    this.lunchTime = false;
    this.homeTime = false;
    this.bedTime = false;
    this.pathRegistration = this.appState.get("pathRegistration");
    if (
      this.currentUser.imageUrl.endsWith("avatar.png") ||
      this.currentUser.imageUrl === "nil"
    ) {
      this.label = "Tap to Add Photo";
      this.isPhotoUploaded = false;
    } else {
      this.isPhotoUploaded = true;
    }
  }

  inputFocus() {
    this.isShowed = true;
    this.fileInput.nativeElement.click();
  }

  ngAfterViewInit() {
    if (
      this.isNotificationSettingsCalenderScreen ||
      this.isDaypartConfirmationScreen
    ) {
      this.initCalendarButton();
    }
    if (this.pathRegistration && this.pathRegistration.pathData) {
      if (
        !this.pathRegistration.pathData.emailReminderEnabled &&
        this.pathRegistration.pathData.emailReminderEnabled !== null
      ) {
        this.emailReminderEnabled =
          this.pathRegistration.pathData.emailReminderEnabled;
      }
      if (
        !this.pathRegistration.pathData.pushReminderEnabled &&
        this.pathRegistration.pathData.pushReminderEnabled !== null
      ) {
        this.pushReminderEnabled =
          this.pathRegistration.pathData.pushReminderEnabled;
      }
      if (
        this.pathRegistration.pathData.loginReminderEnabled &&
        this.pathRegistration.pathData.loginReminderEnabled !== null
      ) {
        this.pushNotificationsEnabled =
          this.pathRegistration.pathData.loginReminderEnabled;
      }
    }
  }

  selectTime(option) {
    if (this.confirmedTime) {
      return;
    }
    let time = "";
    switch (option) {
      case "AFTER_WAKING_UP":
        time = "07:00";
        this.morningTime = true;
        this.atWorkTime = false;
        this.lunchTime = false;
        this.homeTime = false;
        this.bedTime = false;
        this.itemPublish = 14;
        break;
      case "AFTER_ARRIVING_AT_WORK":
        time = "09:00";
        this.atWorkTime = true;
        this.morningTime = false;
        this.lunchTime = false;
        this.homeTime = false;
        this.bedTime = false;
        this.itemPublish = 18;
        break;
      case "AFTER_LUNCH":
        time = "12:00";
        this.lunchTime = true;
        this.atWorkTime = false;
        this.morningTime = false;
        this.homeTime = false;
        this.bedTime = false;
        this.itemPublish = 24;
        break;
      case "AFTER_ARRIVING_HOME":
        time = "17:00";
        this.atWorkTime = false;
        this.morningTime = false;
        this.lunchTime = false;
        this.homeTime = true;
        this.bedTime = false;
        this.itemPublish = 34;
        break;
      case "BEFORE_BED":
        time = "22:00";
        this.atWorkTime = false;
        this.morningTime = false;
        this.lunchTime = false;
        this.homeTime = false;
        this.bedTime = true;
        this.itemPublish = 44;
        break;
      default:
        this.itemPublish = 22;
    }
    this.openPicker();
    this.selectedTimeOption = this.currIndex;
  }

  confirmTime() {
    this.confirmedTime = this.selectedTime;
    this.appState.set("learningTime", this.confirmedTime);
    localStorage.setItem("learningTime", this.confirmedTime);

    localStorage.setItem("notificationsEnabled", "true");
    localStorage.setItem("emailReminderEnabled", "true");
    localStorage.setItem("socialReminderEnabled", "true");
    localStorage.setItem("socialEmailReminder", "true");
    this.resetCalenderData = false;
    this.initCalendarButton(); // LUMBA-1421
    this.NotificationConfirmationModal();
    this.checkScreenCompletion();
  }

  async NotificationConfirmationModal() {
    const modal = await this.modalCtrl.create({
      component: NotificationConfirmationPopupComponent,
      componentProps: { time: this.confirmedTime },
      cssClass: this.isMobile
        ? "notification-reminder-popup-mobile"
        : "notification-reminder-popup-web",
    });
    await modal.present();
    modal.onDidDismiss().then((result) => {
      if (result?.data?.enablePush) {
        this.getTokenAfterLogin();
        // if (this.cordovaService.isIOS() && typeof FCMPlugin !== "undefined") {
        //   FCMPlugin.requestPermissionOnIOS();
        //   this.permissionRequest();
        // // } else if (this.cordovaService.isIOS) {
        // //   this.notificationAlertBox();
        //  }
        this.requestNotificationPermission();
      } else {
        // this.pushNotificationsEnabled = false;
         let deviceId;
    if (this.isMobile && (localStorage.getItem('deviceToken') !== null || localStorage.getItem('deviceToken') != undefined)) {
      deviceId =localStorage.getItem('deviceToken')
    }
    else {
      deviceId = this.pathRegistration.pathData.deviceRegistrationId;
    }
        this.permissionRequest(deviceId);
      }
    });
  }
  async getTokenAfterLogin() {
    await this.platform
      .ready()
      .then(() => {
        this.firebaseX
          .getToken()
          .then((token) => {
            localStorage.setItem("deviceToken", token);
            console.log(`The token is ${token}`);
          })
          .catch((error) => console.error("Error getting token", error));

        this.firebaseX.onTokenRefresh().subscribe((token: string) => {
          localStorage.setItem("deviceToken", token);
          console.log("New device token : ", token);
        });
      })
      .catch((error) => {
        console.log("platform  error : ", error);
      });
  }

  unconfirmTime() {
    const previousTime = this.confirmedTime;
    this.confirmedTime = null;
  }
  checkScreenCompletion() {
    if (this.confirmedTime) {
      const time = moment(this.confirmedTime, "HH:mm");
      const timeString = time.format("HH:mm A");
      mixpanel.track("Onboarding-confirmTime", { confirmTime: timeString });
    }
  }
  initCalendarButton() {
    this.prepareCalendarParams();
    if (this.hasNativeCalendar) {
      return;
    }
    const calendarButtons = AddToCalendar.addToCalendar({
      options: {
        class: "mtr-btn",
        id: "add-calendar-buttons-id",
      },
      data: {
        title: this.calendarParams.title,
        start: this.calendarParams.startDate,
        // timezone: America/Los_Angeles,					// converts the time to the IANA timezone
        end: this.calendarParams.endDate,
        // duration: 120,                            // Event duration (IN MINUTES)
        //allday: true,													// Override end time, duration and timezone, triggers 'all day'
        address: this.calendarParams.eventLocation,
        description: this.calendarParams.notes,
        frequency: "DAILY",
        endDate: this.endDate,
      },
    });
    const parent = $(this.calendarButtonsContainer?.nativeElement);
    const w: any = window;
    w.closeCalenderOnMouseDown = this.cloudCalendarCollapser.bind(this);
    parent.empty();
    parent.append(calendarButtons);
    $("#checkbox-for-add-calendar-buttons-id").change(
      (event) => (this.cloudCalendarExpanded = event.target.checked)
    );
  }

  cloudCalendarCollapser() {
    const doCollapseCloudCalendar = () => {
      setTimeout(() => {
        $("#checkbox-for-add-calendar-buttons-id")[0].checked = false;
      }, 150);
      this.cloudCalendarExpanded = false;
      this.cloudCalendarSet = true;
      document.removeEventListener("mousedown", doCollapseCloudCalendar);
    };
    document.addEventListener("mousedown", doCollapseCloudCalendar);
  }
  getButtonIndex(index, type) {
    switch (index) {
      case 0:
        this.defaultTime = "07:00";
        break;
      case 1:
        this.defaultTime = "09:00";
        break;
      case 2:
        this.defaultTime = "12:00";
        break;
      case 3:
        this.defaultTime = "17:00";
        break;
      case 4:
        this.defaultTime = "22:00";
        break;
      default:
        this.defaultTime = "22:00";
    }
    this.currIndex = index;
    this.selectedDayPartType = type?.title;
  }
  prepareCalendarParams(routinelen: any = null) {
    const daysCount = this.currentPath.daysOfContent * 1.5; 
    if (!this.confirmedTime && this.confirmedTime == null) {
      this.start = moment(new Date(), "HH:mm");
      this.end = moment(new Date(), "HH:mm").add(10, "minute");
      this.endDate = moment(new Date(), "HH:mm").add(daysCount, "day").toDate();
    } else {
      this.start = moment(this.confirmedTime, "HH:mm");
      this.end = moment(this.confirmedTime, "HH:mm").add(10, "minute");
      this.endDate = moment(this.confirmedTime, "HH:mm")
        .add(daysCount, "day")
        .toDate();
    }

    var titleOfEvent = "Daily Learning Reminder";

    try {
      if (this.currentPath.names && this.currentPath.names.titleOfEvent) {
        titleOfEvent = this.currentPath.names.titleOfEvent;
      } else {
        titleOfEvent = "Daily Learning Reminder";
      }
    } catch (error) {}

    this.calendarParams = {
      startDate: this.start.toDate(),
      endDate: this.end.toDate(),
      title: titleOfEvent,
      eventLocation: "",
      notes: this.i18n.t(
        `Click here or open the ${Constants.appName} app to take your next step: ${Constants.deepLinkURL}`
      ),
      endEventTime: null,
      frequency: "DAILY",
    };
  }
  async openPicker(
    time?,
    numColumns = 1,
    numOptions = 49,
    columnOptions = defaultColumnOptions
  ) {
    let defaultHour = parseInt(this.defaultTime.split(":")[0]);
    if (time) {
      defaultHour = parseInt(time.split(":")[0]);
    }
    const defaultMinute = parseInt(
      this.defaultTime.split(":")[1].split(" ")[0]
    );
    let defaultAmPm = this.defaultTime.split(" ")[1];
    defaultAmPm = defaultHour < 12 ? "AM" : "PM";

    if (defaultHour === 0) {
      defaultHour = 12;
    } else if (defaultHour > 12) {
      defaultHour -= 12;
      defaultAmPm = "PM";
    }
    const picker = await this.pickerController.create({
      columns: [
        {
          name: "hour",
          options: this.generateHourOptions(), // For hours in 12-hour format
          selectedIndex: defaultHour - 1,
        },
        {
          name: "minute",
          options: this.generateColumnOptions(), // For minutes
          selectedIndex: defaultMinute === 0 ? 0 : 1,
        },
        {
          name: "ampm",
          options: [
            { text: "AM", value: "AM" },
            { text: "PM", value: "PM" },
          ],
          selectedIndex: defaultAmPm === "AM" ? 0 : 1,
        },
      ],
      buttons: [
        {
          text: this.i18n.t("Cancel"),
          role: "cancel",
        },
        {
          text: this.i18n.t("Confirm"),
          handler: (value) => {
            this.afterWakingUp(value);
          },
        },
      ],
      cssClass: `${this.homeUiService.isDark() ? "dark-theme" : "light-theme"}`,
    });

    await picker.present();
  }
  afterWakingUp(time) {
    let time12h =
      time.hour.text + ":" + time.minute.text + " " + time.ampm.text;
    const [tim, modifier] = time12h.split(" ");
    let [hours, minutes] = tim.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      let i = parseInt(hours, 10) + 12;
      hours = i.toString();
    }
    this.selectedTime = hours + ":" + minutes;
    if (this.selectedTime != null) {
      this.getConfirmedTimeInNormalMode(this.selectedTime);
    }

    for (let index = 0; index < this.dailyRoutine.length; index++) {
      if (index == this.currIndex) {
        this.dailyRoutine[this.currIndex].time = this.selectedTime;
        localStorage.setItem("currIndex", index + "");
      } else {
        this.dailyRoutine[index].time = null;
      }
    }
    this.confirmTime();
  }
  getConfirmedTimeInNormalMode(optedTime) {
    let time = optedTime?.split(":")[0];
    let time1 = optedTime?.split(":")[1];
    time = parseInt(time);
    time1 = parseInt(time1);
    if (time1 == 0) {
      time1 = "00";
    }
    if (time > 12) {
      this.confirmationCalendartime = time - 12 + ":" + time1 + " " + "PM";
    } else if (time == 12 && time1 == 0) {
      this.confirmationCalendartime = time + ":" + "00" + " " + "PM";
    } else if (time == 12 && time1 == 30) {
      this.confirmationCalendartime = time + ":" + time1 + " " + "PM";
    } else {
      this.confirmationCalendartime = time + ":" + time1 + " " + "AM";
    }
  }
  generateHourOptions() {
    const options = [];
    for (let i = 1; i <= 12; i++) {
      options.push({
        text: i.toString(),
        value: i.toString().padStart(2, "0"),
      });
    }
    return options;
  }
  generateColumnOptions() {
    const options = [
      { text: "00", value: "00" },
      { text: "30", value: "30" },
    ];
    return options;
  }
  toggle() {
    if (this.pushNotificationsEnabled && this.cordovaService.isIOS) {
      // this.notificationAlertBox();
      this.requestNotificationPermission();
    }
     let deviceId;
     if (
       this.isMobile &&
       (localStorage.getItem("deviceToken") != null ||
         localStorage.getItem("deviceToken") != undefined)
     ) {
       deviceId = localStorage.getItem("deviceToken");
     } else {
       deviceId = this.pathRegistration.pathData.deviceRegistrationId;
     }
    this.permissionRequest(deviceId);
  }
  notificationAlertBox() {
    let dialogRef = this.dialog.open(AlertNotifyComponent);
    dialogRef.afterClosed().subscribe((result) => {
      // if (result === "confirm" || result == undefined) {
      //   this.pushNotificationsEnabled = true;
      //   this.permissionRequest();
      // }
    });
  }

  async requestNotificationPermission() {
    const permissionStatus = await this.firebaseX.hasPermission();
    if (this.platform.is("ios") || this.platform.is("android")) {
      this.platform.ready().then(() => {
        if (permissionStatus) {
          console.log("Notification permission is already granted");
          // Further actions when permission is already granted
        } else if (permissionStatus == false) {
          
          //this.showPermissionPopup();
          this.getToken();
        }
      });
    }
  }

  permissionRequest(deviceToken: any = null) {
    let savedPathRegistration = this.appState.get("pathRegistration");
    let currentDay = this.appState.get("currentScheduleDay");
    const pathRegistration = new PathRegistration({
      userId: this.currentUser.id,
      pathId: this.currentPath.id,
      pathData: {
        loginReminderEnabled:
          this.pushNotificationsEnabled == false ? false : true,
        loginReminderTime: this.confirmedTime,
        deviceRegistrationId: deviceToken,
        emailReminderEnabled: this.emailReminderEnabled == false ? false : true,
        pushReminderEnabled: true,
        socialReminderEnabled:
          savedPathRegistration.pathData.socialReminderEnabled == false
            ? false
            : true,
        socialEmailEnabled:
          savedPathRegistration.pathData.socialEmailEnabled == false
            ? false
            : true,
        selectedLearningDay: currentDay,
      },
      scheduleDay: currentDay,
      selectedDayPart: this.selectedDayPartType,
    });

    this.userService.savePathRegistration(pathRegistration).subscribe(
      (response) => {
        this.updatePathRegistration = response.data;
        this.appState.set(
          "pathRegistration",
          this.updatePathRegistration.updatePathRegistration
        );
      },
      (error) => {
        console.log("Server push Notifi error", error);
        this.toastrService.error(
          this.i18n.t(
            "Server or connection issue. Please check your internet connection."
          )
        );
      },
      () => {}
    );

    this.analyticsService.setOnboardingEvent(
      "innstep-Onboarding",
      "Notification button enabled",
      this.currentScreenId,
      this.currentPath.id,
      this.currentClient.id
    );
  }

  async getToken() {
    let deviceToken;
    if (this.platform.is("ios") || this.platform.is("android")) {
      this.platform
        .ready()
        .then(async () => {
          await this.firebaseX.hasPermission().then(
            async (res) => {
              await this.firebaseX.grantPermission().then((permission) => {
                console.log("ios permission : ", permission);
                if (!permission && this.cordovaService.isIOS) {
                  this.notificationAlertBox();
                }
              });

              this.firebaseX
                .getToken()
                .then((token) => {
                  deviceToken = token;
                  localStorage.setItem("deviceToken", token);
                  this.permissionRequest(deviceToken);
                })
                .catch((error) => console.error("Error getting token", error));

              this.firebaseX.onMessageReceived().subscribe((data) => {
                let postData = JSON.parse(data?.keysandvalues)
                let notificationId = data['gcm.message_id']?parseInt(data['gcm.message_id']):parseInt(data['google.message_id']?.split(':')[1]?.split('%')[0])
                const requestData = {
                  mode: postData?.mode,
                  postId: postData?.post_id,
                  id: notificationId,
                  route: postData?.route,
                };
                this.appState.set("AfterAppInit", requestData);
                // "AfterAppInit" used for when app is not in BG

                // platform.resume() is used for when app is in BG
                this.platform.resume.subscribe(() => {
                  let communitySavedId = parseInt(
                    localStorage.getItem("CommunityNotificationId")
                  );
                  let commitmentsSavedId = parseInt(
                    localStorage.getItem("CommitmentsNotificationId")
                  );
                  if (
                    postData.route.match("/main/commitments") &&
                    notificationId &&
                    (notificationId > commitmentsSavedId || !commitmentsSavedId)
                  ) {
                    if (
                      data["notification_foreground"] === "true" &&
                      !data["tap"]
                    ) {
                      localStorage.setItem(
                        "CommitmentsNotificationId",
                        notificationId.toString()
                      );
                    } else {
                      localStorage.setItem(
                        "CommitmentsNotificationId",
                        notificationId.toString()
                      );
                      this.homeUiService.openNextComponent(
                        MyCommitmentsComponent
                      );
                      //this.router.navigate(['/main/commitments']);
                    }
                  }

                  if (
                    postData.route.match("/main/questions") &&
                    notificationId &&
                    (notificationId > communitySavedId || !communitySavedId)
                  ) {
                    if (
                      data["notification_foreground"] === "true" &&
                      !data["tap"]
                    ) {
                      localStorage.setItem(
                        "CommunityNotificationId",
                        notificationId.toString()
                      );
                    } else {
                      this.appState.set("communityNotification", true);
                      this.appState.set("notificationData", requestData);
                      if (this.router.url === "/main/questions") {
                        this.appState.set("isBackground", true);
                      } else {
                        // this.router.navigate(['/main/questions']);
                        this.homeUiService.openNextComponent(
                          MobileChatBoardComponent
                        );
                      }
                    }
                  }
                });

                this.sessionService.removeNotificationBadge();
              });
            },
            (error) => {
              console.log("permission error :", error);
            }
          );

          this.firebaseX.onTokenRefresh().subscribe((token: string) => {
            deviceToken = token;
            this.permissionRequest(deviceToken);
            localStorage.setItem("deviceToken", token);
          });
        })
        .catch((error) => {
          console.log("platform  error : ", error);
        });
    } else {
       let deviceId;
    if (this.isMobile && (localStorage.getItem('deviceToken') !== null || localStorage.getItem('deviceToken') != undefined)) {
      deviceId =localStorage.getItem('deviceToken')
    }
    else {
      deviceId = this.pathRegistration.pathData.deviceRegistrationId;
    }
      this.permissionRequest(deviceId);
    }
  }

  addCalendarEvent() {
    try {
      this.createCalendarEvent();
    } catch (error) {
      this.toastrService.error(this.i18n.t("calendar: Something went wrong."));
    }
    this.analyticsService.setOnboardingEvent(
      "Onboarding",
      "create calendarEvent clicked",
      this.currentScreenId,
      this.currentPath.id,
      this.currentClient.id
    );
  }

  createCalendarEvent() {
    const success = function (message) {
      console.log("Success: " + JSON.stringify(message));
    };
    const error = function (message) {
      console.error("Error: " + message);
    };

    try {
      if (!this.confirmedTime) {
        this.toastrService.warning(
          this.i18n.t("Please select the time on previous screen!")
        );
        return;
      } else {
        // create an event interactively
        const w: any = window;
        const calOptions = w.plugins.calendar.getCalendarOptions();
        const showAlertPrompt = true;
        const daysCount = this.currentPath.daysOfContent * 1.5; // LUMBA-1399
        calOptions.recurrence = "DAILY";
        calOptions.recurrenceInterval = 1;
        calOptions.firstReminderMinutes = 0;
        calOptions.recurrenceEndDate = moment(this.confirmedTime, "HH:mm")
          .add(daysCount, "day")
          .toDate();
        this.calendarService.scheduleEvents(
          this.calendarParams.title,
          this.calendarParams.eventLocation,
          this.calendarParams.notes,
          this.calendarParams.startDate,
          this.calendarParams.endDate,
          calOptions,
          success,
          error,
          showAlertPrompt
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  addManagerEmail(ev) {
    if (ev?.target?.value?.includes("@")) {
      this.appState.set("managerEmail", ev?.target?.value);
      this.isManagerEmailAdded = ev?.target?.value;
    }
  }

  addAccountablityEmail(email: any) {
    if (this.sessionService.validateEmail(email)) {
      this.arrayOfAccountabilyEmails.push(email);
      this.accountablityEmail = "";
      this.appState.set("accountabilityEmails", this.arrayOfAccountabilyEmails);
    } else {
      this.toastrService.warning(this.i18n.t("Please enter valid email!"));
    }
  }

  removeEmail(email: any) {
    const index = this.arrayOfAccountabilyEmails?.indexOf(email);
    if (index > -1) {
      this.arrayOfAccountabilyEmails?.splice(index, 1);
      this.appState.set("accountabilityEmails", this.arrayOfAccountabilyEmails);
    }
  }

  pushAccountablityEmails(currentDay?) {
    this.currentUser = this.appState.get("currentUser");
    let isAccountailityChanges = false;
    if (this.currentUser?.accountabilityEmails?.length != this.arrayOfAccountabilyEmails?.length) {
      isAccountailityChanges = true;
    }
    else {
        for (let i = 0; i < this.arrayOfAccountabilyEmails.length; i++) {
          if (this.arrayOfAccountabilyEmails[i] !== this.currentUser?.accountabilityEmails[i]) {
            isAccountailityChanges = true;
          }
        }
    }
    
    if (
      this.arrayOfAccountabilyEmails &&
      this.arrayOfAccountabilyEmails?.length > 0 && isAccountailityChanges )
     {
      var data = {
        id: this.currentUser.id,
        accountability_email: this.arrayOfAccountabilyEmails,
        current_day: currentDay,
      };

      this.homeService.accountablityEmailAPI(data).subscribe(
        (response) => {
          this.appState.set("accountabilityEmails", response?.data);
          this.currentUser.accountabilityEmails =response?.data
          console.log(response, this.currentUser);
        },
        (error) => {
          console.log(error);
        }
      );
      this.analyticsService.setOnboardingEvent(
        "Onboarding",
        "Accountablity emails added",
        this.currentScreenId,
        this.currentPath.id,
        this.currentClient.id
      );
    }
  }

  fileChangeEvent(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    this.spinnerService.on();
    this.uploadPhoto(files[0]);
  }

  uploadPhoto(file: any) {
    let currentDay = this.appState.get("currentScheduleDay");
    console.log("file uplo")
    const myFormData = new FormData();
    if (this.isMobile) {
      myFormData.append("file", file.blob, file.blob.name);
      mixpanel.track("Onboarding-profile image uploaded", {
        profileImage: myFormData,
      });
    } else {
      myFormData.append("file", file, file.name);
      mixpanel.track("Onboarding-profile image uploaded", {
        profileImage: myFormData,
      });
    }
    this.spinnerService.on();
    this.userService.postProfilePic(myFormData).subscribe(
      (result) => {
        this.spinnerService.off();
        this.sessionService.url = result["data"]["url"];
        this.currentUser.imageUrl = this.sessionService.url;
        this.currentUser.profileUploadedDay = currentDay;
        this.updateProfile();
      },
      (error) => {
        this.spinnerService.off();
        this.toastrService.success(this.i18n.t(error));
      }
    );
  }

  requestPermissions() {
    this.permissions.checkPermission(this.permissions.PERMISSION.CAMERA)
      .then(result => {
        if (!result.hasPermission) {
          this.permissions.requestPermissions([
            this.permissions.PERMISSION.READ_MEDIA_IMAGES,
            this.permissions.PERMISSION.CAMERA]).then(async (res) => {
              if (res) {
                const fileResult = await this.native.showActionSheetForFiles();
                // this.spinnerService.on();
                if (fileResult) {
                  mixpanel.track("instep-Onboarding-profile image uploaded", {
                    profileImage: fileResult,
                  });
                  this.uploadPhoto(fileResult);
                  this.analyticsService.setOnboardingEvent(
                    "Onboarding",
                    `Profile image uploaded`,
                    this.currentScreenId,
                    this.currentPath.id,
                    this.currentClient.id
                  );
                } else {
                  this.spinnerService.off();
                }
              }
            })

        }
        else {

        }
      })
      .catch(err => console.error('Error checking permission:', err));
  }

  async chooseUploadOption() {
    this.stopInterval();
    if (this.platform.is('android')) {
      this.requestPermissions();
    }
    else {
      const fileResult = await this.native.showActionSheetForFiles();
      // this.spinnerService.on();
      if (fileResult) {
        mixpanel.track("instep-Onboarding-profile image uploaded", {
          profileImage: fileResult,
        });
        this.uploadPhoto(fileResult);
        this.analyticsService.setOnboardingEvent(
          "Onboarding",
          `Profile image uploaded`,
          this.currentScreenId,
          this.currentPath.id,
          this.currentClient.id
        );
      } else {
        this.spinnerService.off();
      }
    } 
  }
  stopInterval() {
    if (this.dataRefresher) {
      clearInterval(this.dataRefresher);
    }
  }
  updateProfile() {
    this.spinnerService.on();
     delete this.currentUser.userBgColor;
    this.userService.save(this.currentUser).subscribe((response) => {
      this.userUpdate = response.data;
      const updatedUser = new User(this.userUpdate.updateUser);
      this.currentUser = updatedUser;
      this.label = "";
      this.appState.set("currentUser", updatedUser);
      localStorage.setItem("userid", updatedUser.id.toString());
      this.spinnerService.off();
      this.isPhotoUploaded = true;

      this.toastrService.success(this.i18n.t("Profile Picture Added!"));
    });
  }

  ngDoCheck(): void {
    if (localStorage.getItem("learningTime") != "null") {
      this.confirmedTime = localStorage.getItem("learningTime");
    }
    if (this.currentScreen == "daily-content-screen-learning-settings") {
      this.resetCalenderData = false;
    }
    if (this.isNotificationSettingsCalenderScreen==true && this.currentScreen == 'daily-content-screen-learning-settings-confirmation-calender' && !this.resetCalenderData) {
      this.initCalendarButton();
      this.resetCalenderData = true;
      }
  }

  ngOnDestroy() {
    if (this.managerEmail?.includes("@")) {
      this.appState.set("managerEmail", this.managerEmail);
      this.addedManagerEmail = this.managerEmail;
    }
  }
}
