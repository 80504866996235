import { ChangeDetectorRef,Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { I18n } from 'src/app/i18n.service';
import { RoutineInfo } from '../routine-info.model';
import { RoutineLevel } from '../routine-level.model';
import { HomeUiService } from '../../new-design/home-ui.service';

@Component({
  selector: 'location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {

  @Input() routine: RoutineInfo
  @Input() level: RoutineLevel
  @Input() inverted = false

  @Output() locationData = new EventEmitter<any>();
  location: string
  locationLabel: string

  constructor(private i18n: I18n,
    public homeUIService: HomeUiService,
    private cdRef: ChangeDetectorRef,) {
    this.locationLabel = this.i18n.t('at/in location')
   }

  ngOnInit() {
    
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  getValue(location){
    this.locationData.emit(location)
  }

}
